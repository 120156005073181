import { defineSlotRecipe } from '@chakra-ui/react';

export const drawerRecipe = defineSlotRecipe({
  slots: ['body', 'content'],
  base: {
    content: {
      bg: 'background',
      color: 'textSecondary',
    },
    body: {
      px: 3,
    },
  },
});
