import { Box, Center, Container, Flex, Heading, Text } from '@chakra-ui/react';
import { Image } from '@components/AssetWrapper';
import { PROJECTS_BY_ID } from '@content/partners';
import { fullSizeParentStyles, LANDING_ROUTES, pxToRem, TRANSITIONS } from '@cyberkongz/ui';
import { keyframes } from '@emotion/react';
import { AnimatePresence, motion, MotionStyle, Variants } from 'framer-motion';
import { useEffect, useState } from 'react';

import {
  FocusRectangles,
  ShowcasePartnerLogoStart,
  ShowcasePartnersWaiting4Interaction,
  ShowcaseThumbnail,
  START_TRANISTION_PARTNER,
  useBackgroundScale,
} from './Shared';

// const ChakraBox = chakra(motion.div, {
//   /**
//    * Allow motion props and non-Chakra props to be forwarded.
//    */
//   shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
// });

const BASE_ASSETS_URL = '/static/home/showcases/about_you';

type ShowcaseStage = 'start' | 'waiting4Type' | 'typeCompleted';

const IMG_WIDTH = 1440;
const IMG_HEIGHT = 790;

const letterPulse = keyframes`
  0% {
    opacity: 1;
  }
  45% {
    opacity: 0;
  }
  55% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

function Letter({
  letter,
  state,
  onClick,
}: {
  letter: string;
  state: 'typed' | 'waiting' | 'stale';
  onClick: () => void;
}) {
  return (
    <Center
      w={10}
      h={10}
      fontSize="2xl"
      border="mediumWhite"
      borderRadius="sm"
      transition={TRANSITIONS.standard}
      color={state === 'typed' ? 'textAlt' : 'text'}
      cursor={state === 'waiting' ? 'pointer' : 'default'}
      bg={state === 'typed' ? 'text' : 'transparent'}
      onClick={state === 'waiting' ? onClick : undefined}
    >
      <Text
        opacity={state === 'stale' ? 0.5 : 1}
        animation={state === 'waiting' ? `${letterPulse} 0.8s linear infinite` : 'none'}
      >
        {letter}
      </Text>
    </Center>
  );
}

function TypeLetters({ onComplete }: { onComplete: () => void }) {
  const [typed, setTyped] = useState('');

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      const isO = e.key.toLowerCase() === 'o';
      const isH = e.key.toLowerCase() === 'h';

      if (isO) {
        if (typed === '') {
          setTyped('o');
        }
        if (typed === 'o') {
          setTyped('oo');
        }
      }

      if (isH && typed === 'oo') {
        setTyped('ooh');

        setTimeout(onComplete, 500);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [typed]);

  return (
    <Flex justifyContent="center" gap={3}>
      <Letter
        letter="O"
        state={typed.charAt(0) === 'o' ? 'typed' : 'waiting'}
        onClick={() => setTyped('o')}
      />
      <Letter
        letter="O"
        state={typed.charAt(1) === 'o' ? 'typed' : typed.charAt(0) === 'o' ? 'waiting' : 'stale'}
        onClick={() => setTyped('oo')}
      />
      <Letter
        letter="H"
        state={typed.charAt(2) === 'h' ? 'typed' : typed.charAt(1) === 'o' ? 'waiting' : 'stale'}
        onClick={() => {
          setTyped('ooh');

          setTimeout(onComplete, 500);
        }}
      />
    </Flex>
  );
}

const showcaseBgStyles: MotionStyle = {
  ...(fullSizeParentStyles as MotionStyle),
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  zIndex: -1,
};

const bgImageVariants: Variants = {
  start: {
    opacity: 1,
    filter: ['blur(0px)', 'blur(10px)', 'blur(10px)', 'blur(0px)'],
    scale: [1, 1, 1, 1.1],
    transition: START_TRANISTION_PARTNER,
  },
  typeCompleted: {
    opacity: 1,
    transition: { ease: [0, 0.42, 0.58, 1] },
  },
};

export function ShowcaseAboutYou() {
  const [stage, setStage] = useState<ShowcaseStage>('start');
  const { scale } = useBackgroundScale({ imageWidth: IMG_WIDTH, imageHeight: IMG_HEIGHT });
  const isTypeCompleted = stage === 'typeCompleted';

  return (
    <>
      <AnimatePresence mode="wait">
        <motion.div
          key={isTypeCompleted ? 'color' : 'normal'}
          initial={{ opacity: isTypeCompleted ? 0 : 1 }}
          variants={bgImageVariants}
          animate={isTypeCompleted ? 'typeCompleted' : 'start'}
          exit={{ opacity: 0 }}
          style={{
            backgroundImage: `url(${BASE_ASSETS_URL}/${
              isTypeCompleted ? 'bg_color.jpg' : 'bg.png'
            })`,
            ...showcaseBgStyles,
          }}
        >
          <Box
            css={{
              ...fullSizeParentStyles,
              bg: 'linear-gradient(180deg, rgba(0, 0, 0, 0.67) -13.08%, rgba(0, 0, 0, 0.13) 36.92%, rgba(0, 0, 0, 0.82) 86.92%)',
            }}
          />
        </motion.div>
      </AnimatePresence>
      <Container>
        <AnimatePresence mode="wait">
          {stage === 'start' || stage === 'waiting4Type'}
          {stage === 'start' ? (
            <ShowcasePartnerLogoStart
              onAnimationComplete={() => setStage('waiting4Type')}
              partnerImage={<Image src={PROJECTS_BY_ID.about_you.image} alt="About You logo" />}
            />
          ) : null}
          {stage === 'waiting4Type' ? (
            <ShowcasePartnersWaiting4Interaction
              description={
                <>
                  <Heading size="xs" textShadow="0px 0px 50px #FFFFFF" mb={4}>
                    Type/Click OOH to see the merch in colours
                  </Heading>
                  <TypeLetters onComplete={() => setStage('typeCompleted')} />
                </>
              }
              rectangles={
                <FocusRectangles
                  width={pxToRem(scale.width * 325)}
                  height={pxToRem(scale.height * 450)}
                  stylesContainer={{
                    transform: 'translate(-50%, -55%)',
                    top: '50%',
                  }}
                />
              }
            />
          ) : null}
          {stage === 'typeCompleted' ? (
            <ShowcaseThumbnail
              src={`${BASE_ASSETS_URL}/thumbnail.png`}
              alt="Cap"
              label="ABOUT YOU x CyberKongz"
              href={LANDING_ROUTES.MERCH_UNDER_ARMOUR}
            />
          ) : null}
        </AnimatePresence>
      </Container>
    </>
  );
}
