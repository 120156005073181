import { defineSlotRecipe } from '@chakra-ui/react';

export const sliderRecipe = defineSlotRecipe({
  slots: ['root', 'track', 'thumb', 'marker'],
  base: {
    marker: {
      textStyle: 'none',
      fontSize: 'sm',
      fontWeight: 'medium',
    },
    thumb: {
      cursor: 'pointer',
    },
  },
  variants: {
    variant: {
      outline: {
        track: {
          background: 'text',
        },
        range: {
          bg: 'green.500',
        },
        thumb: {
          borderWidth: 'none',
          bg: 'text',
        },
      },
    },
  },
});
