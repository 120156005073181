import { Flex } from '@chakra-ui/react';
import { Footer, LANDING_ROUTES } from '@cyberkongz/ui';
import { PropsWithChildren } from 'react';

import { Menu } from './Menu';

function BaseLayout({ children }: PropsWithChildren) {
  return (
    <>
      <Menu />
      <Flex as="main" css={{ flex: 1 }}>
        {children}
      </Flex>
      <Footer landingRoutes={LANDING_ROUTES} />
    </>
  );
}

export function BasicLayout({ children }: PropsWithChildren) {
  return <BaseLayout>{children}</BaseLayout>;
}
