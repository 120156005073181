import { defineRecipe } from '@chakra-ui/react';

import { pxToRem } from '../utils';

export const containerRecipe = defineRecipe({
  base: {
    px: 4,
    maxWidth: { base: pxToRem(480), sm: pxToRem(1240) },
  },
  variants: {
    variant: {
      md: {
        maxWidth: { base: pxToRem(480), sm: pxToRem(1360) },
      },
      lg: {
        maxWidth: { base: pxToRem(480), sm: pxToRem(1440) },
      },
      full: {
        maxWidth: { base: pxToRem(480), sm: '100%' },
      },
    },
  },
});
