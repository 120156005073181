import { Icon, IconProps } from '@chakra-ui/react';

export function ShopIcon(props: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <svg>
        <g clipPath="url(#clip0_616_4140)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 10.7256V12L21 12V10.7256L19.8921 7.125L4.10786 7.125L3 10.7256ZM3 5.625L1.5 10.5L1.5 13.5L22.5 13.5V10.5L21 5.625L3 5.625Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.5 19.5L4.5 13.5H3V21H15L15 13.5H13.5L13.5 19.5H4.5Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 13.5V21H19.5V13.5H21Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 4.5L3 4.5V3L21 3V4.5Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_616_4140">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
}
