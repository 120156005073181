import keyBy from 'lodash/keyBy';

export type TokenType = 'ERC20' | 'ERC721' | 'ERC1155';

export type ERC20Token = 'banana' | 'ethereum';
export type ERC721Token = 'kong_vx' | 'kong_genkai';
export type CharmzToken = 'cyber_fragment' | 'rainbow_crystal' | 'promethean_relic';
export type ERC1155Token = 'shardz' | 'golden_ticket' | CharmzToken;

type TokensType = ERC20Token | ERC721Token | ERC1155Token;

type ERC20TokenConfig = {
  key: ERC20Token;
  tokenType: Extract<TokenType, 'ERC20'>;
  decimals: number;
  name: string;
};

type ERC721TokenConfig = {
  key: ERC721Token;
  tokenType: Extract<TokenType, 'ERC721'>;
  name: string;
};
export type ERC1155TokenConfig = {
  key: ERC1155Token;
  tokenType: Extract<TokenType, 'ERC1155'>;
  decimals: number;
  name: string;
  tokenId: number;
};

const ERC_20_TOKENS: ERC20TokenConfig[] = [
  {
    key: 'banana',
    name: '$BANANA',
    tokenType: 'ERC20',
    decimals: 18,
  },
  { key: 'ethereum', name: 'Ethereum', tokenType: 'ERC20', decimals: 18 },
];

export const ERC_20_BY_KEY = keyBy(ERC_20_TOKENS, 'key') as Record<ERC20Token, ERC20TokenConfig>;

const ERC_721_TOKENS: ERC721TokenConfig[] = [
  {
    key: 'kong_vx',
    name: 'CyberKongz VX',
    tokenType: 'ERC721',
  },
  {
    key: 'kong_genkai',
    name: 'CyberKongz Genkai',
    tokenType: 'ERC721',
  },
];

export const ERC_721_BY_KEY = keyBy(ERC_721_TOKENS, 'key') as Record<
  ERC721Token,
  ERC721TokenConfig
>;

const ERC_1155_TOKENS: ERC1155TokenConfig[] = [
  {
    key: 'shardz',
    name: 'Shardz',
    tokenType: 'ERC1155',
    decimals: 18,
    tokenId: 0,
  },
  {
    key: 'cyber_fragment',
    name: 'Cyber Fragment',
    tokenType: 'ERC1155',
    decimals: 0,
    tokenId: 1,
  },
  {
    key: 'rainbow_crystal',
    name: 'Rainbow Crystal',
    tokenType: 'ERC1155',
    decimals: 0,
    tokenId: 2,
  },
  {
    key: 'promethean_relic',
    name: 'Promethean Relic',
    tokenType: 'ERC1155',
    decimals: 0,
    tokenId: 3,
  },
  {
    key: 'golden_ticket',
    name: 'Golden Ticket',
    tokenType: 'ERC1155',
    decimals: 0,
    tokenId: 1,
  },
];

export const ERC_1155_BY_KEY = keyBy(ERC_1155_TOKENS, 'key') as Record<
  ERC1155Token,
  ERC1155TokenConfig
>;

const TOKENS = [...ERC_20_TOKENS, ...ERC_721_TOKENS, ...ERC_1155_TOKENS];

export const TOKENS_BY_KEY = keyBy(TOKENS, 'key') as unknown as Record<
  TokensType,
  { name: string; decimals: number }
>;
