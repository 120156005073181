import { defineRecipe, SystemStyleObject } from '@chakra-ui/react';

import { pxToRem } from '../utils';

const inputFieldStyles: SystemStyleObject = {
  border: 'thin',
  bg: 'backgroundSecondary',
  '--focus-color': 'colors.colorPalette.focusRing',
  '--error-color': 'colors.error',
};

export const inputRecipe = defineRecipe({
  variants: {
    variant: {
      primary: inputFieldStyles,
    },
    size: {
      sm: {
        py: 1,
        h: 10,
        fontSize: 'sm',
        borderRadius: 'sm',
      },
      md: {
        py: 2,
        px: 4,
        h: pxToRem(52),
        fontSize: 'md',
        borderRadius: 'md',
      },
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'md',
  },
});

export const textareaRecipe = defineRecipe({
  base: {
    resize: 'none',
    minHeight: 20,
    py: 3,
  },
  variants: {
    variant: {
      primary: inputFieldStyles,
    },
    size: {
      sm: {
        borderRadius: 'sm',
      },
      md: {
        borderRadius: 'md',
      },
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'md',
  },
});
