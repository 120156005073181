import { CDN_ROUTE } from '../consts';

export function parseEmakiConsumableName(name: string) {
  return name
    .replaceAll(`'`, '')
    .replaceAll(' - ', '_')
    .replaceAll(':', '')
    .replaceAll(' ', '_')
    .toLowerCase();
}

export function getEmakiConsumableImages(
  name: string,
  { isTest, isExpired }: { isTest?: boolean; isExpired?: boolean } = {}
) {
  let parsedItemName = parseEmakiConsumableName(name);

  if (isExpired) {
    parsedItemName = `${parsedItemName}_expired`;
  }

  const baseUrl = `${CDN_ROUTE}/static/emaki/consumables`;

  if (isTest) {
    return {
      imageSmall: `/static/emaki/test/${parsedItemName}.jpg`,
      imageMedium: `/static/emaki/test/${parsedItemName}.jpg`,
      imageBig: `/static/emaki/test/${parsedItemName}.jpg`,
    };
  }

  return {
    imageSmall: `${baseUrl}/40x40/${parsedItemName}.jpg`,
    imageMedium: `${baseUrl}/175x175/${parsedItemName}.jpg`,
    imageBig: `${baseUrl}/400x400/${parsedItemName}.jpg`,
  };
}

export function getShopifyHandle(name: string) {
  return name.replaceAll(' - ', '-').replaceAll(' ', '-').toLowerCase();
}
