import { defineSlotRecipe, SystemStyleObject } from '@chakra-ui/react';

import { getClipPathBackground, pxToRem } from '../utils';

const landingCardStyles = {
  bg: 'backgroundLandingCards',
  p: { base: 4, sm: 8 },
  clipPath: getClipPathBackground(pxToRem(12)),
};

export const transparentCardStyles = {
  borderRadius: 'lg',
  bg: 'backgroundCardsTransparent',
  p: { base: 4, sm: 6 },
};

export const cardOutlineStyles = {
  border: 'thin',
  borderColor: 'neutral.700',
  '&:after, &:before': {
    position: 'absolute',
    display: 'block',
    content: '""',
    w: pxToRem(31),
    borderTop: 'inherit',
  },
  '&:after': {
    top: 0,
    left: 0,
    transform: `translateX(${pxToRem(-5)}) rotateZ(135deg)`,
  },
  '&:before': {
    bottom: 0,
    right: 0,
    transform: `translateX(${pxToRem(5)}) rotateZ(135deg)`,
  },
};

export function getExpandedCardOutlineStyles(
  isExpanded: boolean,
  borderColor?: string
): SystemStyleObject {
  return {
    position: 'relative',
    border: '1px solid transparent',
    ...(isExpanded && cardOutlineStyles),
    borderColor: borderColor || 'none',
  };
}

export const cardRecipe = defineSlotRecipe({
  slots: ['root'],
  base: {
    root: {
      color: 'textSecondary',
      position: 'relative',
      bg: 'red',
      width: '100%',
      // by default it's flex + column
      display: 'block',
      flexDirection: 'row',
    },
  },
  variants: {
    variant: {
      primary: {
        root: {
          bg: 'backgroundCards',
          borderRadius: 'lg',
        },
      },
      transparent: { root: transparentCardStyles },
      transparentSecondary: {
        root: {
          ...transparentCardStyles,
          bg: 'backgroundCardsTransparentSecondary',
        },
      },
      transparentDark: {
        root: {
          ...transparentCardStyles,
          bg: 'backgroundCardsTransparentDark',
        },
      },
      landing: { root: landingCardStyles },
      landingTransparent: {
        root: {
          ...landingCardStyles,
          bg: 'backgroundLandingCardsTransparent',
          backdropFilter: 'blur(16px)',
        },
      },
      'genkai.transparent': {
        root: {
          ...transparentCardStyles,
          bg: 'genkai.backgroundCardsTransparent',
          color: 'genkai.text',
        },
      },
    },
    size: {
      md: {
        root: {
          p: 4,
        },
      },
      lg: {
        root: {
          p: { base: 4, sm: 6 },
        },
      },
      xl: {
        root: {
          p: { base: 4, sm: 6, md: 8 },
        },
      },
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'md',
  },
});
