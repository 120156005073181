import { defineSlotRecipe } from '@chakra-ui/react';

export const checkboxRecipe = defineSlotRecipe({
  slots: ['root', 'control', 'label'],
  base: {
    root: {
      gap: 2,
      cursor: 'pointer',
    },
    label: {
      fontWeight: 'normal',
      width: '100%',
    },
  },
  variants: {
    variant: {
      primary: {
        control: {
          borderColor: 'textSecondary',

          _checked: {
            color: 'background',
            bg: 'primary',
            borderColor: 'primary',
            _hover: {
              bg: 'primary',
              borderColor: 'primary',
            },
          },
          _indeterminate: {
            color: 'background',
            bg: 'primary',
            borderColor: 'primary',
            _hover: {
              bg: 'primary',
              borderColor: 'primary',
            },
          },
          _disabled: {
            borderColor: 'textTertiary',
            bg: 'transparent',
          },
        },
      },
    },
    size: {
      md: {
        control: {
          boxSize: 4,
          p: 0,
        },
        label: {
          textStyle: 'none',
          fontSize: 'md',
        },
      },
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});
