import { Accordion, HStack } from '@chakra-ui/react';
import * as React from 'react';

import { ChevronDownIcon } from '../icons';

interface AccordionItemTriggerProps extends Accordion.ItemTriggerProps {
  indicatorPlacement?: 'start' | 'end';
}

// import { PropsWithChildren } from 'react';

// import { TRANSITIONS } from '../theme';
// import { ChevronDownIcon } from './icons';

// export function AccordionButtonWrapper({
//   css,
//   _expanded,
//   _hover,
//   children,
//   cssArrow,
//   ...rest
// }: PropsWithChildren & AccordionButtonProps & { cssArrow?: SystemStyleObject }) {
//   return (
//     <AccordionButton
//       css={{
//         alignItems: 'center',
//         p: 0,
//         justifyContent: 'space-between',
//         textAlign: 'left',
//         ...css,
//       }}
//       _expanded={{
//         // TO DO BETTER WAY INSTEAD OF CLASSNAMES?
//         '.accordion-icon': {
//           transform: 'rotateZ(180deg)',
//         },
//         ..._expanded,
//       }}
//       _hover={{ bg: 'none', ..._hover }}
//       {...rest}
//     >
//       {children}
//       <ChevronDownIcon
//         className="accordion-icon"
//         ml={3}
//         transition={TRANSITIONS.standard}
//         css={cssArrow}
//       />
//     </AccordionButton>
//   );
// }

export const AccordionItemTrigger = React.forwardRef<HTMLButtonElement, AccordionItemTriggerProps>(
  function AccordionItemTrigger(props, ref) {
    const { children, indicatorPlacement = 'end', ...rest } = props;
    return (
      <Accordion.ItemTrigger {...rest} ref={ref}>
        {indicatorPlacement === 'start' && (
          <Accordion.ItemIndicator rotate={{ base: '-90deg', _open: '0deg' }}>
            <ChevronDownIcon />
          </Accordion.ItemIndicator>
        )}
        <HStack gap="4" flex="1" textAlign="start" width="full">
          {children}
        </HStack>
        {indicatorPlacement === 'end' && (
          <Accordion.ItemIndicator>
            <ChevronDownIcon />
          </Accordion.ItemIndicator>
        )}
      </Accordion.ItemTrigger>
    );
  }
);

interface AccordionItemContentProps extends Accordion.ItemContentProps {}

export const AccordionItemContent = React.forwardRef<HTMLDivElement, AccordionItemContentProps>(
  function AccordionItemContent(props, ref) {
    return (
      <Accordion.ItemContent>
        <Accordion.ItemBody {...props} ref={ref} />
      </Accordion.ItemContent>
    );
  }
);

export const AccordionRoot = Accordion.Root;
export const AccordionItem = Accordion.Item;
