import { defineSlotRecipe } from '@chakra-ui/react';

export const fieldRecipe = defineSlotRecipe({
  slots: ['root', 'label', 'errorText', 'helperText'],
  base: {
    root: {
      gap: 2,
    },
    label: {
      width: '100%',
    },
    errorText: {
      color: 'error',
      fontSize: 'sm',
      fontWeight: 'normal',
    },
    helperText: {
      fontSize: 'sm',
      color: 'textTertiary',
      lineHeight: '18px',
    },
  },
});
