import keyBy from 'lodash/keyBy';

import { CDN_ROUTE, KLAW_ROUTE, KONGZ_MART_ROUTE } from './consts';
import { RarityType } from './types';

export type ConsumableType =
  | 'Capsule'
  | 'Voucher'
  | 'Redeemable'
  | 'MysteryBox'
  | 'NFTBox'
  | 'Medipack';

type CapsuleNameId =
  | 'genesis_weapons_capsule'
  | 'genesis_shields_capsule'
  | 'embattle_common_capsule'
  | 'embattle_rare_capsule'
  | 'embattle_epic_capsule'
  | 'embattle_legendary_capsule'
  | 'ascension_common_capsule'
  | 'ascension_rare_capsule'
  | 'ascension_epic_capsule'
  | 'ascension_legendary_capsule';

type VoucherNameId = 'merch_voucher' | 'whitelist_voucher' | 'golden_ticket';

type MysteryBoxNameId =
  | 'ascension_common_mystery_box'
  | 'ascension_rare_mystery_box'
  | 'ascension_epic_mystery_box'
  | 'ascension_legendary_mystery_box';

type NFTMysteryBoxNameId =
  | 'moku_mystery_box'
  | 'runiverse_mystery_box'
  | 'fight_league_fighters_mystery_box'
  | 'kaidro_pilots_mystery_box'
  | 'kanstar_original_mystery_box';

type RedeemableNameId = 'banana_bunch' | 'shredz' | MysteryBoxNameId | NFTMysteryBoxNameId;

type MedipackNameId = 'basic_medipack' | 'elite_medipack';

export type ConsumableNameId = CapsuleNameId | VoucherNameId | RedeemableNameId | MedipackNameId;

type ConsumableBaseType = {
  id: number;
  nameId: ConsumableNameId;
  name: string;
  description: string;
  imageSmall: string;
  imageMedium: string;
  imageBig: string;
  disabled?: boolean;
  tokenType: 'ERC1155';
};

export type CapsuleType = ConsumableBaseType & {
  type: 'Capsule';
  series: 'Weapons' | 'Shields' | 'Embattle' | 'Ascension';
  rarity: RarityType | 'Genesis';
};

export type MysteryBoxType = ConsumableBaseType & {
  type: 'MysteryBox';
  series: 'Ascension';
  rarity: RarityType;
};

export type NFTMysteryBoxType = Omit<MysteryBoxType, 'rarity' | 'type' | 'series'> & {
  series: 'Moku' | 'Runiverse' | 'Fight League Fighters' | 'Kaidro Pilots' | 'Kanstar Original';
  type: 'NFTBox';
  mainnetContractAddress: `0x${string}`;
};

type VoucherType = ConsumableBaseType & {
  type: 'Voucher';
  series: '#0001' | 'Whitelist' | 'Golden Ticket';
};

type RedeemableType = ConsumableBaseType & {
  type: 'Redeemable';
};

export type MedipackType = ConsumableBaseType & {
  type: 'Medipack';
  healHP: number;
  fullHeal: boolean;
};

export type ConsumableItemType =
  | CapsuleType
  | VoucherType
  | RedeemableType
  | MysteryBoxType
  | NFTMysteryBoxType
  | MedipackType;

function getConsumableImages(parsedName: string) {
  const baseUrl = `${CDN_ROUTE}/static/icons/consumables`;

  return {
    imageSmall: `${baseUrl}/40x40/${parsedName}.png`,
    imageMedium: `${baseUrl}/175x175/${parsedName}.png`,
    imageBig: `${baseUrl}/400x400/${parsedName}.png`,
  };
}

function getCapsuleImages(capsuleName: string) {
  return getConsumableImages(`${capsuleName}_capsule`);
}

function getVoucherImages(voucherSeries: string) {
  return getConsumableImages(`${voucherSeries}_voucher`);
}

function getMysteryBoxesImages(mysteryBoxName: string) {
  return getConsumableImages(`${mysteryBoxName}_mystery_box`);
}

const EMBATTLE_CAPSULES: CapsuleType[] = (
  [
    {
      id: 6,
      nameId: 'embattle_common_capsule',
      name: 'Common Loot Capsule (Embattle)',
      rarity: 'Common',
      description: 'This capsule contains equipment of common type.',
      ...getCapsuleImages('embattle_common'),
    },
    {
      id: 7,
      nameId: 'embattle_rare_capsule',
      name: 'Rare Loot Capsule (Embattle)',
      rarity: 'Rare',
      description: 'This capsule contains equipment of rare type.',
      ...getCapsuleImages('embattle_rare'),
    },
    {
      id: 8,
      nameId: 'embattle_epic_capsule',
      name: 'Epic Loot Capsule (Embattle)',
      rarity: 'Epic',
      description: 'This capsule contains equipment of epic type.',
      ...getCapsuleImages('embattle_epic'),
    },
    {
      id: 9,
      nameId: 'embattle_legendary_capsule',
      name: 'Legendary Loot Capsule (Embattle)',
      rarity: 'Legendary',
      description: 'This capsule contains equipment of legendary type.',
      ...getCapsuleImages('embattle_legendary'),
    },
  ] as Omit<CapsuleType, 'tokenType' | 'series' | 'type'>[]
).map((item) => ({ ...item, tokenType: 'ERC1155', type: 'Capsule', series: 'Embattle' }));

const ASCENSION_CAPSULES: CapsuleType[] = (
  [
    {
      id: 13,
      nameId: 'ascension_common_capsule',
      name: 'Common Loot Capsule (Ascension)',
      rarity: 'Common',
      description: 'This capsule contains equipment of common type.',
      ...getCapsuleImages('ascension_common'),
    },
    {
      id: 14,
      nameId: 'ascension_rare_capsule',
      name: 'Rare Loot Capsule (Ascension)',
      rarity: 'Rare',
      description: 'This capsule contains equipment of rare type.',
      ...getCapsuleImages('ascension_rare'),
    },
    {
      id: 15,
      nameId: 'ascension_epic_capsule',
      name: 'Epic Loot Capsule (Ascension)',
      rarity: 'Epic',
      description: 'This capsule contains equipment of epic type.',
      ...getCapsuleImages('ascension_epic'),
    },
    {
      id: 16,
      nameId: 'ascension_legendary_capsule',
      name: 'Legendary Loot Capsule (Ascension)',
      rarity: 'Legendary',
      description: 'This capsule contains equipment of legendary type.',
      ...getCapsuleImages('ascension_legendary'),
    },
  ] as Omit<CapsuleType, 'tokenType' | 'series' | 'type'>[]
).map((item) => ({ ...item, tokenType: 'ERC1155', type: 'Capsule', series: 'Ascension' }));

export const MEDIPACKS: MedipackType[] = (
  [
    {
      id: 11,
      nameId: 'basic_medipack',
      name: 'Basic Medipack',
      description: 'Partial heal.',
      healHP: 5,
      fullHeal: false,
      ...getConsumableImages('basic_medipack'),
    },
    {
      id: 12,
      nameId: 'elite_medipack',
      name: 'Elite Medipack',
      description: 'Full heal and revive.',
      healHP: 0,
      fullHeal: true,
      ...getConsumableImages('elite_medipack'),
    },
  ] as Omit<MedipackType, 'tokenType' | 'type'>[]
).map((item) => ({ ...item, tokenType: 'ERC1155', type: 'Medipack' }));

const ASCENSION_MYSTERY_BOXES: MysteryBoxType[] = (
  [
    {
      id: 17,
      nameId: 'ascension_common_mystery_box',
      name: 'Common Mystery Box (Ascension)',
      description: 'This common mystery box contains a unique prize',
      rarity: 'Common',
      ...getMysteryBoxesImages('ascension_common'),
    },
    {
      id: 18,
      nameId: 'ascension_rare_mystery_box',
      name: 'Rare Mystery Box (Ascension)',
      description: 'This rare mystery box contains a unique prize',
      rarity: 'Rare',
      ...getMysteryBoxesImages('ascension_rare'),
    },
    {
      id: 19,
      nameId: 'ascension_epic_mystery_box',
      name: 'Epic Mystery Box (Ascension)',
      description: 'This epic mystery box contains a unique prize',
      rarity: 'Epic',
      ...getMysteryBoxesImages('ascension_epic'),
    },
    {
      id: 20,
      nameId: 'ascension_legendary_mystery_box',
      name: 'Legendary Mystery Box (Ascension)',
      description: 'This legendary mystery box contains a unique prize',
      rarity: 'Legendary',
      ...getMysteryBoxesImages('ascension_legendary'),
    },
  ] as Omit<MysteryBoxType, 'tokenType' | 'series' | 'type'>[]
).map((item) => ({ ...item, tokenType: 'ERC1155', type: 'MysteryBox', series: 'Ascension' }));

const NFT_MYSTERY_BOXES: NFTMysteryBoxType[] = (
  [
    {
      id: 100,
      nameId: 'moku_mystery_box',
      name: 'Moku NFT Box',
      description: 'This Box contains Moki Eggs of different rarities',
      series: 'Moku',
      mainnetContractAddress: '0x47b5a7c2e4f07772696bbf8c8c32fe2b9eabd550',
    },
    {
      id: 101,
      nameId: 'runiverse_mystery_box',
      name: 'Runiverse NFT Box',
      description: 'This Box contains Runiverse Estates of different regions',
      series: 'Runiverse',
      mainnetContractAddress: '0x775f0a0bb8258501d0862df38a7f7ad8f8f7423d',
    },
    {
      id: 102,
      nameId: 'fight_league_fighters_mystery_box',
      name: 'Fight League: FIGHTERS NFT Box',
      description: 'This Box contains a Survivor Fighter capsule',
      series: 'Fight League Fighters',
      mainnetContractAddress: '0x2a6120978d3f868299aa020303b93b9a90f257cf',
    },
    {
      id: 103,
      nameId: 'kaidro_pilots_mystery_box',
      name: 'Kaidro Pilots Edition 01 NFT Box',
      description: 'This Box contains an unrevealed Kaidro Pilots Edition 01',
      series: 'Kaidro Pilots',
      mainnetContractAddress: '0xc676f28365c8bfb73b1158b19a98c7fb342184ea',
    },
    {
      id: 104,
      nameId: 'kanstar_original_mystery_box',
      name: 'Original Kanstar NFT Box',
      description: 'This Box contains an unrevealed Original Kanstar',
      series: 'Kanstar Original',
      mainnetContractAddress: '0x6f6f478176a7a58a5209e451438270b7617f3f27',
    },
  ] as Omit<NFTMysteryBoxType, 'tokenType' | 'type' | 'imageSmall' | 'imageMedium' | 'imageBig'>[]
).map((item) => ({
  ...item,
  tokenType: 'ERC1155',
  type: 'NFTBox',
  ...getMysteryBoxesImages(item.series.toLowerCase().replaceAll(' ', '_')),
}));

const MYSTERY_BOXES = [...ASCENSION_MYSTERY_BOXES, ...NFT_MYSTERY_BOXES];

export const CONSUMABLES_ITEMS: ConsumableItemType[] = [
  {
    id: 1,
    nameId: 'genesis_weapons_capsule',
    name: 'Genesis Loot Capsule (Weapons)',
    type: 'Capsule',
    rarity: 'Genesis',
    series: 'Weapons',
    description: 'This capsule contains Genesis Weapons of any rarity',
    tokenType: 'ERC1155',
    ...getCapsuleImages('genesis'),
  },
  {
    id: 2,
    nameId: 'merch_voucher',
    name: 'Merch Voucher',
    type: 'Voucher',
    series: '#0001',
    description: 'This voucher can be redeemed for a random Merch.',
    tokenType: 'ERC1155',
    ...getVoucherImages('0001'),
  },
  {
    id: 3,
    nameId: 'banana_bunch',
    name: 'Banana Bunch',
    type: 'Redeemable',
    description: 'This Banana Bunch can be redeemed for Banana token.',
    tokenType: 'ERC1155',
    ...getConsumableImages('banana_bunch'),
  },
  {
    id: 4,
    nameId: 'genesis_shields_capsule',
    name: 'Genesis Loot Capsule (Shields)',
    type: 'Capsule',
    rarity: 'Genesis',
    series: 'Shields',
    description: 'This capsule contains Genesis Shields of any rarity',
    tokenType: 'ERC1155',
    ...getCapsuleImages('genesis'),
  },
  {
    id: 5,
    nameId: 'whitelist_voucher',
    name: 'Whitelist Voucher',
    type: 'Voucher',
    series: 'Whitelist',
    description: `This voucher can be redeemed for a whitelist spot. Check current whitelists at [KongzMart](${KONGZ_MART_ROUTE})`,
    tokenType: 'ERC1155',
    ...getVoucherImages('wl'),
  },
  ...EMBATTLE_CAPSULES,
  {
    id: 10,
    nameId: 'shredz',
    name: 'Shredz',
    type: 'Redeemable',
    description: 'This Shredz can be redeemed for Golden Ticket.',
    tokenType: 'ERC1155',
    ...getConsumableImages('shredz'),
  },
  ...MEDIPACKS,
  ...ASCENSION_CAPSULES,
  ...MYSTERY_BOXES,
];

export const CONSUMABLES_ITEMS_IDS = CONSUMABLES_ITEMS.map(({ id }) => id);

const CONSUMABLES_GOLDEN_TICKET: VoucherType[] = [
  {
    id: 1,
    nameId: 'golden_ticket',
    name: 'Golden Ticket',
    type: 'Voucher',
    series: 'Golden Ticket',
    description: `This ticket can be redeemed in [The Klaw](${KLAW_ROUTE})`,
    tokenType: 'ERC1155',
    ...getConsumableImages('golden_ticket'),
  },
];

export const CONSUMABLES_BY_ID = keyBy(CONSUMABLES_ITEMS, 'id') as Record<
  number,
  ConsumableItemType
>;
export const CONSUMABLES_BY_NAME_ID = keyBy(CONSUMABLES_ITEMS, 'nameId') as Record<
  ConsumableNameId,
  ConsumableItemType
>;

export const CONSUMABLES_GOLDEN_TICKET_BY_ID = keyBy(CONSUMABLES_GOLDEN_TICKET, 'id');
