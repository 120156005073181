import keyBy from 'lodash/keyBy';

import { CDN_ROUTE } from '../consts';
import { RarityType } from '../types';

export type SeriesType = 'Genesis' | 'Embattle' | 'Ascension';

export type SlotType = 'Main-Hand' | 'Off-Hand' | 'Arms' | 'Torso' | 'Legs';

type ArmoryItemBase = {
  id: string;
  name: string;
  rarity: RarityType;
  description: string;
  gearScore: number;
  slot: SlotType;
};

export type ArmoryItemType = ArmoryItemBase & {
  series: SeriesType;
  imageSmall: string;
  imageMedium: string;
  imageMediumTransparent: string;
  imageBig: string;
  imageBigTransparent: string;
  setId?: number;
};

const ARMORY_WEAPONS_BASE: ArmoryItemBase[] = [
  {
    name: 'Pitchfork',
    rarity: 'Common',
    description:
      'An old rusty Pitchfork used by yield farmers. This can barely considered a weapon.',
    id: '18447869977911427073',
    gearScore: 48,
    slot: 'Main-Hand',
  },
  {
    name: 'Old Sword',
    rarity: 'Common',
    description: `A regular old sword, not properly cared for. Don't expect to win a serious duel with this one.`,
    id: '36894614051620978689',
    gearScore: 49,
    slot: 'Main-Hand',
  },
  {
    name: 'Wooden Mallet',
    rarity: 'Common',
    description:
      'An old Wooden Mallet used for carpentry. Some say they can “tap out” anything wished for.',
    id: '55341358125330530305',
    gearScore: 50,
    slot: 'Main-Hand',
  },

  {
    name: `Daisy`,
    rarity: 'Common',
    description: `It's a flower. What do you expect?`,
    id: '73788102199040081921',
    gearScore: 51,
    slot: 'Main-Hand',
  },
  {
    name: `Cactus On A Stick`,
    rarity: 'Common',
    description: `Desperate times call for desperate measures. A cactus on a stick will get the job done.`,
    id: '92234846272749633537',
    gearScore: 52,
    slot: 'Main-Hand',
  },
  {
    name: `Kris`,
    rarity: 'Rare',
    description: `A well forged dagger with a wavy blade. Great in close combat and as a backup.`,
    id: '110681590350754217985',
    gearScore: 107,
    slot: 'Main-Hand',
  },
  {
    name: `Katana`,
    rarity: 'Rare',
    description: `An elegantly forged Katana with a single blade. A nimble weapon that is very effective in the right hands.`,
    id: '129128334424463769601',
    gearScore: 109,
    slot: 'Main-Hand',
  },
  {
    name: `Battle Axe`,
    rarity: 'Rare',
    description: `A big and heavy, double-bladed battle axe. The craftsmanship of this weapon seeks its equal.`,
    id: '147575078498173321217',
    gearScore: 111,
    slot: 'Main-Hand',
  },
  {
    name: `Kongbone`,
    rarity: 'Rare',
    description: `A Kongz arm bone turned into a lethal weapon. The bones of CyberKongz are strengthened by naturally occurring carbon fiber.`,
    id: '166021822571882872833',
    gearScore: 113,
    slot: 'Main-Hand',
  },
  {
    name: `Royal Guard's Hammer`,
    rarity: 'Epic',
    description:
      'A beautifully crafted battle hammer only swung be the most skilled warriors. Elegant and hard to come by.',
    id: '184468566649887457281',
    gearScore: 185,
    slot: 'Main-Hand',
  },
  {
    name: `Gut Ripper`,
    rarity: 'Epic',
    description: `A brutal close combat weapon. The name Gut Ripper speaks for itself.`,
    id: '202915310723597008897',
    gearScore: 190,
    slot: 'Main-Hand',
  },
  {
    name: `Horus Wand`,
    rarity: 'Epic',
    description: `An ancient wand used by spiritual wanderers in search of the Shrine of Kongz.  Its crystal seem to possess some hidden powers.`,
    id: '221362054797306560513',
    gearScore: 195,
    slot: 'Main-Hand',
  },
  {
    name: `Noctua`,
    rarity: 'Legendary',
    description: `The legendary sword Noctua. Its blade glows a fierce red, which only grows stronger by every enemy it strikes down. Noctua was only wielded by the Knights of the Owl.`,
    id: '239808798875311144961',
    gearScore: 320,
    slot: 'Main-Hand',
  },
  {
    name: `Hellfire`,
    rarity: 'Legendary',
    description: `The legendary sword Hellfire. Forged in the depths of hell, with its blade burning as hot as the sun. Wounds caused by this weapon never fully heal and leave their bearer in permanent agony.`,
    id: '258255542949020696577',
    gearScore: 320,
    slot: 'Main-Hand',
  },
];

const ARMORY_SHIELDS_BASE: ArmoryItemBase[] = [
  {
    name: 'Barn Door',
    rarity: 'Common',
    description: `An old wooden door ripped out of a humble yield farmer's barn. This will barely lasts a fight.`,
    id: '276702568484821860353',
    gearScore: 48,
    slot: 'Off-Hand',
  },
  {
    name: 'Banana Leaf',
    rarity: 'Common',
    description:
      'A big reinforced leaf from the legendary Kongz Island banana plant. Better than nothing.',
    id: '295149312558531411969',
    gearScore: 49,
    slot: 'Off-Hand',
  },
  {
    name: 'Wooden Shield',
    rarity: 'Common',
    description: 'An old wooden shield. Already quite beat up, but it will get the job done.',
    id: '313596056632240963585',
    gearScore: 50,
    slot: 'Off-Hand',
  },
  {
    name: 'Oakenshield',
    rarity: 'Common',
    description:
      'A tough and exceptionally sturdy stump of an old oak tree. This will hold off some big strikes.',
    id: '332042800705950515201',
    gearScore: 51,
    slot: 'Off-Hand',
  },
  {
    name: 'Bib',
    rarity: 'Common',
    description: 'Get bibbed.',
    id: '350489544779660066817',
    gearScore: 52,
    slot: 'Off-Hand',
  },
  {
    name: `Knight's Shield`,
    rarity: 'Rare',
    description:
      'A well forged shield commonly used by regular Knights. Thick and heavy steel make this shield quite useful.',
    id: '368936288857664651265',
    gearScore: 107,
    slot: 'Off-Hand',
  },
  {
    name: `Gladiator's Shield`,
    rarity: 'Rare',
    description:
      'A colourful shield used by gladiators in the old times. Made from hardened wood and reinforced with steel.',
    id: '387383032931374202881',
    gearScore: 109,
    slot: 'Off-Hand',
  },
  {
    name: `Steelforged Shield`,
    rarity: 'Rare',
    description:
      'A durable shield forged from rare steel and crafted with great care. Definitely a great choice.',
    id: '405829777005083754497',
    gearScore: 111,
    slot: 'Off-Hand',
  },
  {
    name: `Spiked Steelguard`,
    rarity: 'Rare',
    description:
      'A mixture of shield and weapon. The Spiked Steelguard will hold off heavy attacks and it’s spikes can do some serious damage.',
    id: '424276521078793306113',
    gearScore: 113,
    slot: 'Off-Hand',
  },
  {
    name: `Royal Guard's Shield`,
    rarity: 'Epic',
    description:
      'A beautifully crafted shield only carried by the most skilled warriors. Elegant and hard to come by.',
    id: '442723265156797890561',
    gearScore: 185,
    slot: 'Off-Hand',
  },
  {
    name: `Plutonium Scientist's Protector`,
    rarity: 'Epic',
    description:
      'This shield is commonly used by scientists working at Plutonium Industries for the creation of fuel rods. This protector can shield the scientists from harmful radiation.',
    id: '461170009230507442177',
    gearScore: 190,
    slot: 'Off-Hand',
  },
  {
    name: `Ancient Promethean Aegis`,
    rarity: 'Epic',
    description: `A shield crafted by the Prometheans. It's incredibly old but still incredibly tough. Using it in a proper manner is no easy task.`,
    id: '479616753304216993793',
    gearScore: 195,
    slot: 'Off-Hand',
  },
  {
    name: `Noctua's Shield`,
    rarity: 'Legendary',
    description: `The legendary Noctua's Shield. The red glowing eyes strike fear into every enemies heart and can fend off the toughest of blows. Noctua's Shield was only carried by the Knights of the Owl.`,
    id: '498063497382221578241',
    gearScore: 320,
    slot: 'Off-Hand',
  },
  {
    name: `Hellbreaker`,
    rarity: 'Legendary',
    description:
      'The legendary shield Hellbreaker. Forged in the depths of hell and infused with dark magic. Strikes blocked with this shield leave their attackers stunned and blinded by the flash of light and screeching sound. ',
    id: '516510241455931129857',
    gearScore: 320,
    slot: 'Off-Hand',
  },
];

const ARMORY_EMBATTLE_BASE: ArmoryItemBase[] = [
  {
    name: 'Street Sign Chest Guard',
    rarity: 'Common',
    description: '',
    id: '534955859612553773056',
    slot: 'Torso',
    gearScore: 26,
  },
  {
    name: 'Street Sign Arm Guards',
    rarity: 'Common',
    description: '',
    id: '553402885161240035328',
    slot: 'Arms',
    gearScore: 24,
  },
  {
    name: 'Street Sign Leg Guards',
    rarity: 'Common',
    description: '',
    id: '571849910709926297600',
    slot: 'Legs',
    gearScore: 24,
  },
  {
    name: 'Street Sign',
    rarity: 'Common',
    description: '',
    id: '590296936258612559872',
    slot: 'Main-Hand',
    gearScore: 28,
  },
  {
    name: 'Stop Sign',
    rarity: 'Common',
    description: '',
    id: '608743961807298822144',
    slot: 'Off-Hand',
    gearScore: 28,
  },
  {
    name: 'Scrap Metal Chest Protector',
    rarity: 'Common',
    description: '',
    id: '627189579981101531136',
    slot: 'Torso',
    gearScore: 28,
  },
  {
    name: 'Scrap Metal Arm Protectors',
    rarity: 'Common',
    description: '',
    id: '645636605529787793408',
    slot: 'Arms',
    gearScore: 26,
  },
  {
    name: 'Scrap Metal Leg Protectors',
    rarity: 'Common',
    description: '',
    id: '664083631078474055680',
    slot: 'Legs',
    gearScore: 26,
  },
  {
    name: 'Crowbar',
    rarity: 'Common',
    description: '',
    id: '682530656627160317952',
    slot: 'Main-Hand',
    gearScore: 30,
  },
  {
    name: 'Trash Can Lid',
    rarity: 'Common',
    description: '',
    id: '700977682175846580224',
    slot: 'Off-Hand',
    gearScore: 30,
  },
  {
    name: 'Rusted Steel Plated Chest',
    rarity: 'Common',
    description: '',
    id: '719423300349649289216',
    slot: 'Torso',
    gearScore: 30,
  },
  {
    name: 'Rusted Steel Plated Arms',
    rarity: 'Common',
    description: '',
    id: '737870325898335551488',
    slot: 'Arms',
    gearScore: 28,
  },
  {
    name: 'Rusted Steel Plated Legs',
    rarity: 'Common',
    description: '',
    id: '756317351447021813760',
    slot: 'Legs',
    gearScore: 28,
  },
  {
    name: 'Pipe',
    rarity: 'Common',
    description: '',
    id: '774764376995708076032',
    slot: 'Main-Hand',
    gearScore: 32,
  },
  {
    name: 'Barrier Plate',
    rarity: 'Common',
    description: '',
    id: '793211402544394338304',
    slot: 'Off-Hand',
    gearScore: 32,
  },
  {
    name: 'Rebel Chest Protector',
    rarity: 'Common',
    description: '',
    id: '811657020718197047296',
    slot: 'Torso',
    gearScore: 32,
  },
  {
    name: 'Rebel Arm Protectors',
    rarity: 'Common',
    description: '',
    id: '830104046266883309568',
    slot: 'Arms',
    gearScore: 30,
  },
  {
    name: 'Rebel Leg Protectors',
    rarity: 'Common',
    description: '',
    id: '848551071815569571840',
    slot: 'Legs',
    gearScore: 30,
  },
  {
    name: 'Nail Bat',
    rarity: 'Common',
    description: '',
    id: '866998097364255834112',
    slot: 'Main-Hand',
    gearScore: 34,
  },
  {
    name: 'Rebel Shield',
    rarity: 'Common',
    description: '',
    id: '885445122912942096384',
    slot: 'Off-Hand',
    gearScore: 34,
  },
  {
    name: 'Banana Plated Chest',
    rarity: 'Common',
    description: '',
    id: '903890741086744805376',
    slot: 'Torso',
    gearScore: 34,
  },
  {
    name: 'Banana Plated Arms',
    rarity: 'Common',
    description: '',
    id: '922337766635431067648',
    slot: 'Arms',
    gearScore: 32,
  },
  {
    name: 'Banana Plated Legs',
    rarity: 'Common',
    description: '',
    id: '940784792184117329920',
    slot: 'Legs',
    gearScore: 32,
  },
  {
    name: 'Small Axe',
    rarity: 'Common',
    description: '',
    id: '959231817732803592192',
    slot: 'Main-Hand',
    gearScore: 36,
  },
  {
    name: 'Banana Shield',
    rarity: 'Common',
    description: '',
    id: '977678843281489854464',
    slot: 'Off-Hand',
    gearScore: 36,
  },
  {
    name: "Adventurer's Splint Chest Guard",
    rarity: 'Rare',
    description: '',
    id: '996124461455292628992',
    slot: 'Torso',
    gearScore: 63,
  },
  {
    name: "Adventurer's Splint Arms",
    rarity: 'Rare',
    description: '',
    id: '1014571487003978891264',
    slot: 'Arms',
    gearScore: 58,
  },
  {
    name: "Adventurer's Splint Legs",
    rarity: 'Rare',
    description: '',
    id: '1033018512552665153536',
    slot: 'Legs',
    gearScore: 58,
  },
  {
    name: 'Saber',
    rarity: 'Rare',
    description: '',
    id: '1051465538101351415808',
    slot: 'Main-Hand',
    gearScore: 68,
  },
  {
    name: "Wooden Adventurer's Shield",
    rarity: 'Rare',
    description: '',
    id: '1069912563650037678080',
    slot: 'Off-Hand',
    gearScore: 68,
  },
  {
    name: 'KIPD Riot Chest Gear',
    rarity: 'Rare',
    description: '',
    id: '1088358181823840387072',
    slot: 'Torso',
    gearScore: 68,
  },
  {
    name: 'KIPD Riot Arm Gear',
    rarity: 'Rare',
    description: '',
    id: '1106805207372526649344',
    slot: 'Arms',
    gearScore: 63,
  },
  {
    name: 'KIPD Riot Leg Gear',
    rarity: 'Rare',
    description: '',
    id: '1125252232921212911616',
    slot: 'Legs',
    gearScore: 63,
  },
  {
    name: 'Tactical Axe',
    rarity: 'Rare',
    description: '',
    id: '1143699258469899173888',
    slot: 'Main-Hand',
    gearScore: 73,
  },
  {
    name: 'Tactical Shield',
    rarity: 'Rare',
    description: '',
    id: '1162146284018585436160',
    slot: 'Off-Hand',
    gearScore: 73,
  },
  {
    name: 'Steel Forged Chestplate',
    rarity: 'Rare',
    description: '',
    id: '1180591902192388145152',
    slot: 'Torso',
    gearScore: 73,
  },
  {
    name: 'Steel Forged Arms',
    rarity: 'Rare',
    description: '',
    id: '1199038927741074407424',
    slot: 'Arms',
    gearScore: 68,
  },
  {
    name: 'Steel Forged Legs',
    rarity: 'Rare',
    description: '',
    id: '1217485953289760669696',
    slot: 'Legs',
    gearScore: 68,
  },
  {
    name: 'Steel Mace',
    rarity: 'Rare',
    description: '',
    id: '1235932978838446931968',
    slot: 'Main-Hand',
    gearScore: 78,
  },
  {
    name: 'Steel Shield',
    rarity: 'Rare',
    description: '',
    id: '1254380004387133194240',
    slot: 'Off-Hand',
    gearScore: 78,
  },
  {
    name: "Legion's Battlegear Chest",
    rarity: 'Rare',
    description: '',
    id: '1272825622560935903232',
    slot: 'Torso',
    gearScore: 78,
  },
  {
    name: "Legion's Battlegear Arms",
    rarity: 'Rare',
    description: '',
    id: '1291272648109622165504',
    slot: 'Arms',
    gearScore: 73,
  },
  {
    name: "Legion's Battlegear Legs",
    rarity: 'Rare',
    description: '',
    id: '1309719673658308427776',
    slot: 'Legs',
    gearScore: 73,
  },
  {
    name: 'Scimitar',
    rarity: 'Rare',
    description: '',
    id: '1328166699206994690048',
    slot: 'Main-Hand',
    gearScore: 83,
  },
  {
    name: "Legion's Crest",
    rarity: 'Rare',
    description: '',
    id: '1346613724755680952320',
    slot: 'Off-Hand',
    gearScore: 83,
  },
  {
    name: 'Enriched Darksteel Breastplate',
    rarity: 'Epic',
    description: '',
    id: '1365059342929483726848',
    slot: 'Torso',
    gearScore: 115,
  },
  {
    name: 'Enriched Darksteel Arms',
    rarity: 'Epic',
    description: '',
    id: '1383506368478169989120',
    slot: 'Arms',
    gearScore: 110,
  },
  {
    name: 'Enriched Darksteel Legs',
    rarity: 'Epic',
    description: '',
    id: '1401953394026856251392',
    slot: 'Legs',
    gearScore: 110,
  },
  {
    name: 'Darksteel Saber',
    rarity: 'Epic',
    description: '',
    id: '1420400419575542513664',
    slot: 'Main-Hand',
    gearScore: 120,
  },
  {
    name: 'Darksteel Guardian',
    rarity: 'Epic',
    description: '',
    id: '1438847445124228775936',
    slot: 'Off-Hand',
    gearScore: 120,
  },
  {
    name: 'Reactor Chest Battleplate',
    rarity: 'Epic',
    description: '',
    id: '1457293063298031484928',
    slot: 'Torso',
    gearScore: 120,
  },
  {
    name: 'Reactor Arm Battleplate',
    rarity: 'Epic',
    description: '',
    id: '1475740088846717747200',
    slot: 'Arms',
    gearScore: 115,
  },
  {
    name: 'Reactor Leg Battleplate',
    rarity: 'Epic',
    description: '',
    id: '1494187114395404009472',
    slot: 'Legs',
    gearScore: 115,
  },
  {
    name: 'Reactor Powered Battlehammer',
    rarity: 'Epic',
    description: '',
    id: '1512634139944090271744',
    slot: 'Main-Hand',
    gearScore: 125,
  },
  {
    name: 'Reactor Powered Bulwark',
    rarity: 'Epic',
    description: '',
    id: '1531081165492776534016',
    slot: 'Off-Hand',
    gearScore: 125,
  },
  {
    name: 'Azure Elemental Chest Guard',
    rarity: 'Epic',
    description: '',
    id: '1549526783666579243008',
    slot: 'Torso',
    gearScore: 125,
  },
  {
    name: 'Azure Elemental Arm Guards',
    rarity: 'Epic',
    description: '',
    id: '1567973809215265505280',
    slot: 'Arms',
    gearScore: 120,
  },
  {
    name: 'Azure Elemental Leg Guards',
    rarity: 'Epic',
    description: '',
    id: '1586420834763951767552',
    slot: 'Legs',
    gearScore: 120,
  },
  {
    name: 'Azure Broadsword',
    rarity: 'Epic',
    description: '',
    id: '1604867860312638029824',
    slot: 'Main-Hand',
    gearScore: 130,
  },
  {
    name: 'Azure Aegis',
    rarity: 'Epic',
    description: '',
    id: '1623314885861324292096',
    slot: 'Off-Hand',
    gearScore: 130,
  },
  {
    name: 'Kongium Heritage Breastplate',
    rarity: 'Legendary',
    description: '',
    id: '1641760504035127066624',
    slot: 'Torso',
    gearScore: 200,
  },
  {
    name: 'Kongium Heritage Arms',
    rarity: 'Legendary',
    description: '',
    id: '1660207529583813328896',
    slot: 'Arms',
    gearScore: 190,
  },
  {
    name: 'Kongium Heritage Legs',
    rarity: 'Legendary',
    description: '',
    id: '1678654555132499591168',
    slot: 'Legs',
    gearScore: 190,
  },
  {
    name: 'Kongium Decapitator',
    rarity: 'Legendary',
    description: '',
    id: '1697101580681185853440',
    slot: 'Main-Hand',
    gearScore: 210,
  },
  {
    name: 'Immovable One',
    rarity: 'Legendary',
    description: '',
    id: '1715548606229872115712',
    slot: 'Off-Hand',
    gearScore: 210,
  },
  {
    name: 'Promethean Buster Chestplate',
    rarity: 'Legendary',
    description: '',
    id: '1733994224403674824704',
    slot: 'Torso',
    gearScore: 200,
  },
  {
    name: 'Promethean Buster Arms',
    rarity: 'Legendary',
    description: '',
    id: '1752441249952361086976',
    slot: 'Arms',
    gearScore: 190,
  },
  {
    name: 'Promethean Buster Legs',
    rarity: 'Legendary',
    description: '',
    id: '1770888275501047349248',
    slot: 'Legs',
    gearScore: 190,
  },
  {
    name: 'Illuminator',
    rarity: 'Legendary',
    description: '',
    id: '1789335301049733611520',
    slot: 'Main-Hand',
    gearScore: 210,
  },
  {
    name: 'Promethean Deflector',
    rarity: 'Legendary',
    description: '',
    id: '1807782326598419873792',
    slot: 'Off-Hand',
    gearScore: 210,
  },
];

const ARMORY_ASCENSION_BASE: ArmoryItemBase[] = [
  {
    name: 'Blasted Furnance Barricade Harness',
    rarity: 'Common',
    description: '',
    id: '1826227944772222386176',
    slot: 'Torso',
    gearScore: 22,
  },
  {
    name: 'Blasted Furnance Arm Guards',
    rarity: 'Common',
    description: '',
    id: '1844674970320908648448',
    slot: 'Arms',
    gearScore: 20,
  },
  {
    name: 'Blasted Furnance Leg Guards',
    rarity: 'Common',
    description: '',
    id: '1863121995869594910720',
    slot: 'Legs',
    gearScore: 20,
  },
  {
    name: 'Blasted Furnance Blaster',
    rarity: 'Common',
    description: '',
    id: '1881569021418281172992',
    slot: 'Main-Hand',
    gearScore: 24,
  },
  {
    name: 'Blasted Furnance Rampart',
    rarity: 'Common',
    description: '',
    id: '1900016046966967435264',
    slot: 'Off-Hand',
    gearScore: 24,
  },
  {
    name: 'Cryo Commander Chest Shell',
    rarity: 'Common',
    description: '',
    id: '1918461665140770144256',
    slot: 'Torso',
    gearScore: 24,
  },
  {
    name: 'Cryo Commander Arm Guards',
    rarity: 'Common',
    description: '',
    id: '1936908690689456406528',
    slot: 'Arms',
    gearScore: 22,
  },
  {
    name: 'Cryo Commander Leg Guards',
    rarity: 'Common',
    description: '',
    id: '1955355716238142668800',
    slot: 'Legs',
    gearScore: 22,
  },
  {
    name: 'Cryo Commander Blastech Pistol',
    rarity: 'Common',
    description: '',
    id: '1973802741786828931072',
    slot: 'Main-Hand',
    gearScore: 26,
  },
  {
    name: 'Cryo Commander Core Shield',
    rarity: 'Common',
    description: '',
    id: '1992249767335515193344',
    slot: 'Off-Hand',
    gearScore: 26,
  },
  {
    name: 'Midnight Trooper Coreplate',
    rarity: 'Common',
    description: '',
    id: '2010695385509317902336',
    slot: 'Torso',
    gearScore: 26,
  },
  {
    name: 'Midnight Trooper Stealth Bracers',
    rarity: 'Common',
    description: '',
    id: '2029142411058004164608',
    slot: 'Arms',
    gearScore: 24,
  },
  {
    name: 'Midnight Trooper Stealth Shoes',
    rarity: 'Common',
    description: '',
    id: '2047589436606690426880',
    slot: 'Legs',
    gearScore: 24,
  },
  {
    name: 'Midnight Trooper Suppressed Blaster',
    rarity: 'Common',
    description: '',
    id: '2066036462155376689152',
    slot: 'Main-Hand',
    gearScore: 28,
  },
  {
    name: 'Midnight Trooper Deflective Plate',
    rarity: 'Common',
    description: '',
    id: '2084483487704062951424',
    slot: 'Off-Hand',
    gearScore: 28,
  },
  {
    name: 'Runic Raider Chestplate',
    rarity: 'Common',
    description: '',
    id: '2102929105877865660416',
    slot: 'Torso',
    gearScore: 28,
  },
  {
    name: 'Runic Raider Arm Guard',
    rarity: 'Common',
    description: '',
    id: '2121376131426551922688',
    slot: 'Arms',
    gearScore: 26,
  },
  {
    name: 'Runic Raider Steel Treads',
    rarity: 'Common',
    description: '',
    id: '2139823156975238184960',
    slot: 'Legs',
    gearScore: 26,
  },
  {
    name: 'Runic Raider Staxe',
    rarity: 'Common',
    description: '',
    id: '2158270182523924447232',
    slot: 'Main-Hand',
    gearScore: 30,
  },
  {
    name: 'Runic Raider Light Shield',
    rarity: 'Common',
    description: '',
    id: '2176717208072610709504',
    slot: 'Off-Hand',
    gearScore: 30,
  },
  {
    name: 'Vortex Pulse Chestplate',
    rarity: 'Common',
    description: '',
    id: '2195162826246413418496',
    slot: 'Torso',
    gearScore: 30,
  },
  {
    name: 'Vortex Pulse Arm Sleeves',
    rarity: 'Common',
    description: '',
    id: '2213609851795099680768',
    slot: 'Arms',
    gearScore: 28,
  },
  {
    name: 'Vortex Pulse Techboots',
    rarity: 'Common',
    description: '',
    id: '2232056877343785943040',
    slot: 'Legs',
    gearScore: 28,
  },
  {
    name: 'Vortex Pulse Laser Blaster',
    rarity: 'Common',
    description: '',
    id: '2250503902892472205312',
    slot: 'Main-Hand',
    gearScore: 32,
  },
  {
    name: 'Vortex Pulse Flux Shield',
    rarity: 'Common',
    description: '',
    id: '2268950928441158467584',
    slot: 'Off-Hand',
    gearScore: 32,
  },
  {
    name: 'Kaboom Blastplate',
    rarity: 'Common',
    description: '',
    id: '2287396546614961176576',
    slot: 'Torso',
    gearScore: 32,
  },
  {
    name: 'Kaboom Bracers',
    rarity: 'Common',
    description: '',
    id: '2305843572163647438848',
    slot: 'Arms',
    gearScore: 30,
  },
  {
    name: 'Kaboom Techboots',
    rarity: 'Common',
    description: '',
    id: '2324290597712333701120',
    slot: 'Legs',
    gearScore: 30,
  },
  {
    name: 'Kaboom Disruptor',
    rarity: 'Common',
    description: '',
    id: '2342737623261019963392',
    slot: 'Main-Hand',
    gearScore: 34,
  },
  {
    name: 'Kaboom Liberty Shield',
    rarity: 'Common',
    description: '',
    id: '2361184648809706225664',
    slot: 'Off-Hand',
    gearScore: 34,
  },
  {
    name: 'Arcane Operator Chestpiece',
    rarity: 'Common',
    description: '',
    id: '2379630266983508934656',
    slot: 'Torso',
    gearScore: 34,
  },
  {
    name: 'Arcane Operator Bracers',
    rarity: 'Common',
    description: '',
    id: '2398077292532195196928',
    slot: 'Arms',
    gearScore: 32,
  },
  {
    name: 'Arcane Operator Cuisses',
    rarity: 'Common',
    description: '',
    id: '2416524318080881459200',
    slot: 'Legs',
    gearScore: 32,
  },
  {
    name: 'Arcane Operator Blade',
    rarity: 'Common',
    description: '',
    id: '2434971343629567721472',
    slot: 'Main-Hand',
    gearScore: 36,
  },
  {
    name: 'Arcane Operator Holo Shield',
    rarity: 'Common',
    description: '',
    id: '2453418369178253983744',
    slot: 'Off-Hand',
    gearScore: 36,
  },
  {
    name: 'KEC Recon Coreplate',
    rarity: 'Common',
    description: '',
    id: '2471863987352056692736',
    slot: 'Torso',
    gearScore: 36,
  },
  {
    name: 'KEC Recon Articulators',
    rarity: 'Common',
    description: '',
    id: '2490311012900742955008',
    slot: 'Arms',
    gearScore: 34,
  },
  {
    name: 'KEC Recon Terrain Treads',
    rarity: 'Common',
    description: '',
    id: '2508758038449429217280',
    slot: 'Legs',
    gearScore: 34,
  },
  {
    name: 'KEC Recon Pulse Pistol',
    rarity: 'Common',
    description: '',
    id: '2527205063998115479552',
    slot: 'Main-Hand',
    gearScore: 38,
  },
  {
    name: 'KEC Recon GeoGuard',
    rarity: 'Common',
    description: '',
    id: '2545652089546801741824',
    slot: 'Off-Hand',
    gearScore: 38,
  },
  {
    name: 'Titanite Centaurian Chest Guard',
    rarity: 'Rare',
    description: '',
    id: '2564097707720604516352',
    slot: 'Torso',
    gearScore: 58,
  },
  {
    name: 'Titanite Centaurian Exo-Arms',
    rarity: 'Rare',
    description: '',
    id: '2582544733269290778624',
    slot: 'Arms',
    gearScore: 53,
  },
  {
    name: 'Titanite Centaurian Leg Guard',
    rarity: 'Rare',
    description: '',
    id: '2600991758817977040896',
    slot: 'Legs',
    gearScore: 53,
  },
  {
    name: 'Titanite Centaurian Railgun',
    rarity: 'Rare',
    description: '',
    id: '2619438784366663303168',
    slot: 'Main-Hand',
    gearScore: 63,
  },
  {
    name: 'Titanite Centaurian Ice Shield',
    rarity: 'Rare',
    description: '',
    id: '2637885809915349565440',
    slot: 'Off-Hand',
    gearScore: 63,
  },
  {
    name: 'Torus Chestplate',
    rarity: 'Rare',
    description: '',
    id: '2656331428089152274432',
    slot: 'Torso',
    gearScore: 63,
  },
  {
    name: 'Torus Armored Sleeves',
    rarity: 'Rare',
    description: '',
    id: '2674778453637838536704',
    slot: 'Arms',
    gearScore: 58,
  },
  {
    name: 'Torus Leg Guards',
    rarity: 'Rare',
    description: '',
    id: '2693225479186524798976',
    slot: 'Legs',
    gearScore: 58,
  },
  {
    name: 'Torus Devastator',
    rarity: 'Rare',
    description: '',
    id: '2711672504735211061248',
    slot: 'Main-Hand',
    gearScore: 68,
  },
  {
    name: 'Torus Wand',
    rarity: 'Rare',
    description: '',
    id: '2730119530283897323520',
    slot: 'Off-Hand',
    gearScore: 68,
  },
  {
    name: 'Warrior of Iyakoy Ancient Yoroi',
    rarity: 'Rare',
    description: '',
    id: '2748565148457700032512',
    slot: 'Torso',
    gearScore: 68,
  },
  {
    name: 'Warrior of Iyakoy Aituth Sleeves',
    rarity: 'Rare',
    description: '',
    id: '2767012174006386294784',
    slot: 'Arms',
    gearScore: 63,
  },
  {
    name: 'Warrior of Iyakoy Leg Guard',
    rarity: 'Rare',
    description: '',
    id: '2785459199555072557056',
    slot: 'Legs',
    gearScore: 63,
  },
  {
    name: 'Warrior of Iyakoy Aituth Fang',
    rarity: 'Rare',
    description: '',
    id: '2803906225103758819328',
    slot: 'Main-Hand',
    gearScore: 73,
  },
  {
    name: 'Warrior of Iyakoy Wand',
    rarity: 'Rare',
    description: '',
    id: '2822353250652445081600',
    slot: 'Off-Hand',
    gearScore: 73,
  },
  {
    name: 'Ajisai Enforcer Carbon Vest',
    rarity: 'Rare',
    description: '',
    id: '2840798868826247790592',
    slot: 'Torso',
    gearScore: 73,
  },
  {
    name: 'Ajisai Enforcer Carbon Sleeves',
    rarity: 'Rare',
    description: '',
    id: '2859245894374934052864',
    slot: 'Arms',
    gearScore: 68,
  },
  {
    name: 'Ajisai Enforcer Z-Boots',
    rarity: 'Rare',
    description: '',
    id: '2877692919923620315136',
    slot: 'Legs',
    gearScore: 68,
  },
  {
    name: 'Ajisai Enforcer Neutron Rifle',
    rarity: 'Rare',
    description: '',
    id: '2896139945472306577408',
    slot: 'Main-Hand',
    gearScore: 78,
  },
  {
    name: 'Ajisai Enforcer Particle Scrambler',
    rarity: 'Rare',
    description: '',
    id: '2914586971020992839680',
    slot: 'Off-Hand',
    gearScore: 78,
  },
  {
    name: 'Rugendo Elite Frost Cuirass',
    rarity: 'Rare',
    description: '',
    id: '2933032589194795548672',
    slot: 'Torso',
    gearScore: 78,
  },
  {
    name: 'Rugendo Elite Crystal Arm Guards',
    rarity: 'Rare',
    description: '',
    id: '2951479614743481810944',
    slot: 'Arms',
    gearScore: 73,
  },
  {
    name: 'Rugendo Elite Shinplates',
    rarity: 'Rare',
    description: '',
    id: '2969926640292168073216',
    slot: 'Legs',
    gearScore: 73,
  },
  {
    name: 'Rugendo Elite Scout Cross-Blaster',
    rarity: 'Rare',
    description: '',
    id: '2988373665840854335488',
    slot: 'Main-Hand',
    gearScore: 83,
  },
  {
    name: 'Rugendo Elite Scout Aegis',
    rarity: 'Rare',
    description: '',
    id: '3006820691389540597760',
    slot: 'Off-Hand',
    gearScore: 83,
  },
  {
    name: 'Nightforce Phantom Ghost Vest',
    rarity: 'Rare',
    description: '',
    id: '3025266309563343306752',
    slot: 'Torso',
    gearScore: 83,
  },
  {
    name: 'Nightforce Phantom Stealth Bracers',
    rarity: 'Rare',
    description: '',
    id: '3043713335112029569024',
    slot: 'Arms',
    gearScore: 78,
  },
  {
    name: 'Nightforce Phantom Ghostboots',
    rarity: 'Rare',
    description: '',
    id: '3062160360660715831296',
    slot: 'Legs',
    gearScore: 78,
  },
  {
    name: 'Nightforce Phantom Piercer',
    rarity: 'Rare',
    description: '',
    id: '3080607386209402093568',
    slot: 'Main-Hand',
    gearScore: 88,
  },
  {
    name: 'Nightforce Phantom Nova Shield',
    rarity: 'Rare',
    description: '',
    id: '3099054411758088355840',
    slot: 'Off-Hand',
    gearScore: 88,
  },
  {
    name: 'KIPD Trauma Unit Protector',
    rarity: 'Epic',
    description: '',
    id: '3117500029931891130368',
    slot: 'Torso',
    gearScore: 115,
  },
  {
    name: 'KIPD Trauma Unit Arm Guards',
    rarity: 'Epic',
    description: '',
    id: '3135947055480577392640',
    slot: 'Arms',
    gearScore: 110,
  },
  {
    name: 'KIPD Trauma Unit Jumpers',
    rarity: 'Epic',
    description: '',
    id: '3154394081029263654912',
    slot: 'Legs',
    gearScore: 110,
  },
  {
    name: 'KIPD Trauma Tempest Blade',
    rarity: 'Epic',
    description: '',
    id: '3172841106577949917184',
    slot: 'Main-Hand',
    gearScore: 120,
  },
  {
    name: 'KIPD Trauma Protectorate',
    rarity: 'Epic',
    description: '',
    id: '3191288132126636179456',
    slot: 'Off-Hand',
    gearScore: 120,
  },
  {
    name: 'Fallen Plutonium Chest Reactor',
    rarity: 'Epic',
    description: '',
    id: '3209733750300438888448',
    slot: 'Torso',
    gearScore: 120,
  },
  {
    name: 'Fallen Plutonium Arm Guards',
    rarity: 'Epic',
    description: '',
    id: '3228180775849125150720',
    slot: 'Arms',
    gearScore: 115,
  },
  {
    name: 'Fallen Plutonium ExoLegs',
    rarity: 'Epic',
    description: '',
    id: '3246627801397811412992',
    slot: 'Legs',
    gearScore: 115,
  },
  {
    name: 'Fallen Plutonium Nuclear Obliterator',
    rarity: 'Epic',
    description: '',
    id: '3265074826946497675264',
    slot: 'Main-Hand',
    gearScore: 125,
  },
  {
    name: 'Fallen Plutonium Fusion Shield',
    rarity: 'Epic',
    description: '',
    id: '3283521852495183937536',
    slot: 'Off-Hand',
    gearScore: 125,
  },
  {
    name: 'Ryu Cyclone Chest Rig',
    rarity: 'Epic',
    description: '',
    id: '3301967470668986646528',
    slot: 'Torso',
    gearScore: 125,
  },
  {
    name: 'Ryu Cyclone Arm Rig',
    rarity: 'Epic',
    description: '',
    id: '3320414496217672908800',
    slot: 'Arms',
    gearScore: 120,
  },
  {
    name: 'Ryu Cyclone Combat Boots',
    rarity: 'Epic',
    description: '',
    id: '3338861521766359171072',
    slot: 'Legs',
    gearScore: 120,
  },
  {
    name: 'Ryu Cyclone Annihalator',
    rarity: 'Epic',
    description: '',
    id: '3357308547315045433344',
    slot: 'Main-Hand',
    gearScore: 130,
  },
  {
    name: 'Ryu Cyclone Blast Shield',
    rarity: 'Epic',
    description: '',
    id: '3375755572863731695616',
    slot: 'Off-Hand',
    gearScore: 130,
  },
  {
    name: 'Crimson Inferno ExoWings',
    rarity: 'Epic',
    description: '',
    id: '3394201191037534404608',
    slot: 'Torso',
    gearScore: 130,
  },
  {
    name: 'Crimson Inferno ExoGauntlets',
    rarity: 'Epic',
    description: '',
    id: '3412648216586220666880',
    slot: 'Arms',
    gearScore: 125,
  },
  {
    name: 'Crimson Inferno ExoBoots',
    rarity: 'Epic',
    description: '',
    id: '3431095242134906929152',
    slot: 'Legs',
    gearScore: 125,
  },
  {
    name: 'Crimson Inferno Prometheans Wrath',
    rarity: 'Epic',
    description: '',
    id: '3449542267683593191424',
    slot: 'Main-Hand',
    gearScore: 135,
  },
  {
    name: 'Crimson Inferno Guardian Plate',
    rarity: 'Epic',
    description: '',
    id: '3467989293232279453696',
    slot: 'Off-Hand',
    gearScore: 135,
  },
  {
    name: 'Jolgue Guardian Armor',
    rarity: 'Legendary',
    description: '',
    id: '3486434911406082228224',
    slot: 'Torso',
    gearScore: 200,
  },
  {
    name: 'Jolgue Guardian Thruster Sleeves',
    rarity: 'Legendary',
    description: '',
    id: '3504881936954768490496',
    slot: 'Arms',
    gearScore: 190,
  },
  {
    name: 'Jolgue Guardian Thruster Boots',
    rarity: 'Legendary',
    description: '',
    id: '3523328962503454752768',
    slot: 'Legs',
    gearScore: 190,
  },
  {
    name: 'Jolgue Guardian Weeping Killer',
    rarity: 'Legendary',
    description: '',
    id: '3541775988052141015040',
    slot: 'Main-Hand',
    gearScore: 210,
  },
  {
    name: 'Jolgue Guardian Sacred Shield',
    rarity: 'Legendary',
    description: '',
    id: '3560223013600827277312',
    slot: 'Off-Hand',
    gearScore: 210,
  },
  {
    name: 'Sealkeeper of Ixon Armor',
    rarity: 'Legendary',
    description: '',
    id: '3578668631774629986304',
    slot: 'Torso',
    gearScore: 200,
  },
  {
    name: 'Sealkeeper of Ixon Gauntlets',
    rarity: 'Legendary',
    description: '',
    id: '3597115657323316248576',
    slot: 'Arms',
    gearScore: 190,
  },
  {
    name: 'Sealkeeper of Ixon Surge Boots',
    rarity: 'Legendary',
    description: '',
    id: '3615562682872002510848',
    slot: 'Legs',
    gearScore: 190,
  },
  {
    name: 'Sealkeeper of Ixon Harbinger',
    rarity: 'Legendary',
    description: '',
    id: '3634009708420688773120',
    slot: 'Main-Hand',
    gearScore: 210,
  },
  {
    name: 'Sealkeeper of Ixon Aether',
    rarity: 'Legendary',
    description: '',
    id: '3652456733969375035392',
    slot: 'Off-Hand',
    gearScore: 210,
  },
];

function getArmoryItemImages(itemName: string, folder: SeriesType) {
  const parsedItemName = itemName
    .replaceAll(`'`, '')
    .replaceAll('-', '_')
    .split(' ')
    .join('_')
    .toLowerCase();

  const baseUrl = `${CDN_ROUTE}/static/icons/armory/items/${folder.toLowerCase()}`;

  return {
    imageSmall: `${baseUrl}/40x40/${parsedItemName}.png`,
    imageMedium: `${baseUrl}/175x175/${parsedItemName}.png`,
    imageMediumTransparent: `${baseUrl}/175x175_transparent/${parsedItemName}.png`,
    imageBig: `${baseUrl}/400x400/${parsedItemName}.png`,
    imageBigTransparent: `${baseUrl}/400x400_transparent/${parsedItemName}.png`,
  };
}

const ARMORY_WEAPONS: ArmoryItemType[] = ARMORY_WEAPONS_BASE.map((item) => ({
  ...item,
  series: 'Genesis',
  ...getArmoryItemImages(item.name, 'Genesis'),
}));

const ARMORY_SHIELDS: ArmoryItemType[] = ARMORY_SHIELDS_BASE.map((item) => ({
  ...item,
  series: 'Genesis',
  ...getArmoryItemImages(item.name, 'Genesis'),
}));

const ARMORY_EMBATTLE: ArmoryItemType[] = ARMORY_EMBATTLE_BASE.map((item, i) => ({
  ...item,
  series: 'Embattle',
  ...getArmoryItemImages(item.name, 'Embattle'),
  // every set has 5 items
  setId: Math.floor(i / 5) + 1,
}));

const ARMORY_ASCENSION: ArmoryItemType[] = ARMORY_ASCENSION_BASE.map((item, i) => ({
  ...item,
  series: 'Ascension',
  ...getArmoryItemImages(item.name, 'Ascension'),
  // every set has 5 items, offset 14 - total embattle sets
  setId: 14 + Math.floor(i / 5) + 1,
}));

export const ARMORY_ITEMS: ArmoryItemType[] = [
  ...ARMORY_WEAPONS,
  ...ARMORY_SHIELDS,
  ...ARMORY_EMBATTLE,
  ...ARMORY_ASCENSION,
];

export const ARMORY_ITEMS_IDS = ARMORY_ITEMS.map(({ id }) => id);

export const ARMORY_ITEMS_BY_ID = keyBy(ARMORY_ITEMS, 'id');

// SETS
export const ARMORY_ITEMS_BY_SET_ID = ARMORY_ITEMS.reduce(
  (mapping, item) => {
    const { setId } = item;

    if (setId) {
      if (mapping[setId]) {
        mapping[setId].push(item);
      } else {
        mapping[setId] = [item];
      }
    }

    return mapping;
  },
  { Embattle: {} } as {
    [key: number]: ArmoryItemType[];
  }
);
