import { defineSlotRecipe } from '@chakra-ui/react';

export const radioGroupRecipe = defineSlotRecipe({
  slots: ['item', 'itemControl'],
  base: {
    item: {
      '--chakra-cursor-radio': 'pointer',
      cursor: 'pointer',
    },
    itemControl: {
      '& .dot': {
        scale: '0.5',
      },
    },
  },
  variants: {
    variant: {
      solid: {
        itemControl: { borderWidth: '2px' },
      },
    },
  },
  defaultVariants: {
    // @ts-ignore
    size: 'sm',
  },
});
