import {
  EmakiConsumableBaseDataType,
  EmakiConsumableType,
  EmakiLinkableBackgroundType,
  EmakiLinkableItemType,
  EmakiPhysicalVoucherType,
} from './types';
import { getEmakiConsumableImages, getShopifyHandle } from './utils';

const EMAKI_TOKEN: EmakiConsumableType = {
  id: '1',
  name: 'Emaki Season 1',
  type: 'emakiToken',
  ...getEmakiConsumableImages('Emaki Season 1'),
};

const SPRAY_CAN: EmakiConsumableType = {
  id: '2',
  name: 'Background Modifier',
  type: 'sprayCan',
  ...getEmakiConsumableImages('Background Modifier'),
};

const EMAKI_LINKABLE_ITEMS: EmakiLinkableItemType[] = (
  [
    { id: '3', name: 'Humba Jungle', slot: 'Background' },
    { id: '4', name: 'Focus Lines', slot: 'Background' },
    { id: '5', name: 'Yaya Canyon', slot: 'Background' },
    { id: '6', name: 'Mapuwa Grasslands', slot: 'Background' },
    { id: '7', name: 'Sakura', slot: 'Special Effect' },
    { id: '8', name: 'Clouds', slot: 'Special Effect' },
    { id: '9', name: 'Sparks', slot: 'Special Effect' },
    { id: '10', name: 'adidas Beanie', slot: 'Hat' },
    { id: '11', name: 'adidas Kimono', slot: 'Clothing' },
    { id: '12', name: 'adidas Utility Vest', slot: 'Clothing' },
    { id: '13', name: 'adidas Wing Unit', slot: 'Accessory' },
  ] as (EmakiConsumableBaseDataType & Pick<EmakiLinkableItemType, 'slot'>)[]
).map((item) => ({
  ...item,
  type: 'linkableItem',
  ...getEmakiConsumableImages(item.name),
  shopifyHandle: getShopifyHandle(item.name),
}));

const EMAKI_LINKABLE_BACKGROUNDS: EmakiLinkableBackgroundType[] = (
  [
    {
      id: '724623887078278986451430262700651068631159129611047298752605',
      name: 'Clay',
      rgba: 'rgba(177, 96, 93)',
    },
    {
      id: '724623887078278986451430262700651068631159129611047298889054',
      name: 'Burnt Orange',
      rgba: 'rgba(179, 117, 94)',
    },
    {
      id: '724623887078278986451430262700651068631159129611047299822173',
      name: 'Mustard',
      rgba: 'rgba(193, 178, 93)',
    },
    {
      id: '724623887078278986451430262700651068631159129611047294317405',
      name: 'Field Green',
      rgba: 'rgba(109, 179, 93)',
    },
    {
      id: '724623887078278986451430262700651068631159129611047293334134',
      name: 'Forest Green',
      rgba: 'rgba(94, 178, 118)',
    },
    {
      id: '724623887078278986451430262700651068631159129611047293337779',
      name: 'Sea Blue',
      rgba: 'rgba(94, 192, 179)',
    },
    {
      id: '724623887078278986451430262700651068631159129611047293260978',
      name: 'Sky Blue',
      rgba: 'rgba(93, 148, 178)',
    },
    {
      id: '724623887078278986451430262700651068631159129611047293509042',
      name: 'Indigo',
      rgba: 'rgba(97, 93, 178)',
    },
    {
      id: '724623887078278986451430262700651068631159129611047294491825',
      name: 'Grape',
      rgba: 'rgba(112, 92, 177)',
    },
    {
      id: '724623887078278986451430262700651068631159129611047296851377',
      name: 'Lavender',
      rgba: 'rgba(148, 93, 177)',
    },
    {
      id: '724623887078278986451430262700651068631159129611047298817428',
      name: 'Pink',
      rgba: 'rgba(178, 93, 148)',
    },
    {
      id: '724623887078278986451430262700651068631159129611047299730768',
      name: 'Vermillion',
      rgba: 'rgba(192, 77, 80)',
    },
    {
      id: '724623887078278986451430262700651068631159129611047292315735',
      name: 'Deep Purple',
      rgba: 'rgba(79, 40, 87)',
    },
    {
      id: '724623887078278986451430262700651068631159129611047288915778',
      name: 'Deep Green',
      rgba: 'rgba(27, 71, 66)',
    },
    {
      id: '724623887078278986451430262700651068631159129611047289501272',
      name: 'Deep Blue',
      rgba: 'rgba(36, 54, 88)',
    },
  ] as (EmakiConsumableBaseDataType & Pick<EmakiLinkableBackgroundType, 'rgba'>)[]
).map((item) => ({
  ...item,
  type: 'linkableBackground',
  slot: 'Background',
  ...getEmakiConsumableImages(item.name),
}));

const EMAKI_COLLECTIBLE_ITEMS: EmakiConsumableType[] = (
  [
    {
      id: '14',
      name: 'Chapter 1: Dawn of Akenzi',
      nameShort: 'Chapter 1 Cover',
    },
    {
      id: '15',
      name: 'Chapter 2: The Yaya Canyons',
      nameShort: 'Chapter 2 Cover',
    },
    {
      id: '16',
      name: 'Chapter 3: Powerful Oza',
      nameShort: 'Chapter 3 Cover',
    },
  ] as EmakiConsumableBaseDataType[]
).map((item) => ({
  ...item,
  type: 'collectible',
  ...getEmakiConsumableImages(item.name),
}));

const EMAKI_PHYSICAL_VOUCHER_ITEMS: EmakiPhysicalVoucherType[] = (
  [
    {
      id: '17',
      name: 'Genkai Warrior Hoodie - Powered by 9dcc',
      nameShort: 'Genkai Warrior Hoodie',
    },
    {
      id: '18',
      name: 'CyberKongz Black Vest',
    },
  ] as EmakiConsumableBaseDataType[]
).map((item) => ({
  ...item,
  type: 'physicalVoucher',
  ...getEmakiConsumableImages(item.name, { isExpired: true }),
  shopifyHandle: getShopifyHandle(item.name),
}));

export const EMAKI_CONSUMABLES_SEASON_1: EmakiConsumableType[] = [
  EMAKI_TOKEN,
  SPRAY_CAN,
  ...EMAKI_LINKABLE_ITEMS,
  ...EMAKI_LINKABLE_BACKGROUNDS,
  ...EMAKI_COLLECTIBLE_ITEMS,
  ...EMAKI_PHYSICAL_VOUCHER_ITEMS,
];
