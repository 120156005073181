export const VRFCoordinatorRoninABI = [
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "bytes32",
        "name": "requestHash",
        "type": "bytes32"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "randomSeed",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "requestValue",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "refundAmount",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "paymentAmount",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "constantFee",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "bool",
        "name": "callbackResult",
        "type": "bool"
      }
    ],
    "name": "RandomSeedFulfilled",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "bytes32",
        "name": "reqHash",
        "type": "bytes32"
      },
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "blockNumber",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "callbackGasLimit",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "gasPrice",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "gasFee",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "requester",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "consumer",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "refundAddr",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "nonce",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "usdConstantFee",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "ronConstantFee",
            "type": "uint256"
          },
          {
            "internalType": "bytes32[]",
            "name": "orderedKeyHashes",
            "type": "bytes32[]"
          }
        ],
        "indexed": false,
        "internalType": "struct LibSLA.RandomRequest",
        "name": "request",
        "type": "tuple"
      }
    ],
    "name": "RandomSeedRequested",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "by",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "bytes32",
        "name": "keyHash",
        "type": "bytes32"
      },
      {
        "indexed": true,
        "internalType": "enum Action",
        "name": "action",
        "type": "uint8"
      },
      {
        "indexed": false,
        "internalType": "uint64",
        "name": "score",
        "type": "uint64"
      }
    ],
    "name": "ScoreUpdated",
    "type": "event"
  }
] as const;