import keyBy from 'lodash/keyBy';

import { EMAKI_CONSUMABLES_SEASON_1 } from './emakiSeason1';
import { EMAKI_CONSUMABLES_SEASON_2 } from './emakiSeason2';
import { EmakiConsumableType } from './types';

export const EMAKI_CONSUMABLES: EmakiConsumableType[] = [
  ...EMAKI_CONSUMABLES_SEASON_1,
  ...EMAKI_CONSUMABLES_SEASON_2,
];

export const EMAKI_CONSUMABLES_IDS = EMAKI_CONSUMABLES.map(({ id }) => id);

export const EMAKI_CONSUMABLES_BY_ID = keyBy(EMAKI_CONSUMABLES, 'id');
