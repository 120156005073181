import { defineSlotRecipe } from '@chakra-ui/react';

export const switchRecipe = defineSlotRecipe({
  slots: ['root', 'control', 'thumb'],
  variants: {
    variant: {
      solid: {
        control: {
          background: 'neutral.600',
          _checked: {
            background: 'green.500',
          },
        },
        thumb: {
          background: 'text',
          _checked: {
            background: 'text',
          },
        },
      },
    },
  },
});
