import { Box, SystemStyleObject } from '@chakra-ui/react';
import { pxToRem } from '@cyberkongz/ui';

export function RectangleDecoration({
  stylesWrapper,
  sxRectangle,
}: {
  stylesWrapper?: SystemStyleObject;
  sxRectangle?: SystemStyleObject;
}) {
  return (
    <Box
      css={{
        position: 'relative',
        borderTop: 'thinWhite',
        borderTopColor: 'currentColor',
        ...stylesWrapper,
        '&:before': {
          display: 'block',
          content: '""',
          position: 'absolute',
          left: 0,
          bottom: 0,
          w: pxToRem(85),
          h: pxToRem(4),
          bg: 'currentColor',
          boxShadow: '0px 0px 10px currentColor',
          ...sxRectangle,
        },
      }}
    />
  );
}

export function RectangleDecorationWithRotatedBorder({
  stylesWrapper,
  sxRectangle,
  sxRotatedBorder,
}: {
  stylesWrapper?: SystemStyleObject;
  sxRectangle?: SystemStyleObject;
  sxRotatedBorder?: SystemStyleObject;
}) {
  return (
    <Box
      css={{
        w: ['calc(100% - 3)', pxToRem(625)],
        ...stylesWrapper,
      }}
    >
      <Box
        css={{
          w: pxToRem(301),
          h: pxToRem(6),
          bg: 'white',
          boxShadow: '0px 0px 10px #FFFFFF',
          ...sxRectangle,
        }}
      />
      <Box
        css={{
          borderTop: 'thinWhite',
          w: '100%',
          position: 'relative',
          '&:before': {
            position: 'absolute',
            display: 'block',
            content: '""',
            w: pxToRem(50),
            h: pxToRem(50),
            borderTop: 'inherit',
            borderBottom: 'inherit',
            transform: `rotateZ(-135deg)`,
            transformOrigin: 'top left',
            ...sxRotatedBorder,
          },
        }}
      />
    </Box>
  );
}

const squareDotsStyles: SystemStyleObject = {
  display: 'block',
  position: 'absolute',
  zIndex: 1,
  content: '""',
  bg: 'neutral.700',
  w: 1,
  h: 1,
  top: '50%',
  transform: 'translate(-50%, -50%)',
};

export function BorderWithSquareDots() {
  return (
    <Box
      css={{
        position: 'relative',
        border: 'thin',
        '&:after': {
          ...squareDotsStyles,
          right: 0,
          transform: 'translate(50%, -50%)',
        },
        '&:before': {
          ...squareDotsStyles,
          left: 0,
        },
      }}
    />
  );
}
