import {
  ChainType,
  RONIN_GATEWAY_V2_ADDRESS,
  RONIN_GATEWAY_V2_ADDRESS_TESTNET,
  ZERO_ADDRESS,
} from '@cyberkongz/config-blockchain';
import { KongGenkai, KongVX } from '@cyberkongz/database';

import { APIKongGenkai, APIKongVX, KollectionType } from './api-interfaces';

const RONIN_OWNER_ADDRESS_CHECK = [
  null,
  ZERO_ADDRESS,
  RONIN_GATEWAY_V2_ADDRESS,
  RONIN_GATEWAY_V2_ADDRESS_TESTNET,
];

const POLYGON_OWNER_ADDRESS_CHECK = [null, ZERO_ADDRESS];

export function getKongVXOwnerData({
  owner_address,
  owner_address_polygon,
  owner_address_ronin,
}: APIKongVX | KongVX): { chain: ChainType; owner: string } {
  if (RONIN_OWNER_ADDRESS_CHECK.every((address) => address !== owner_address_ronin)) {
    return { chain: 'ronin', owner: owner_address_ronin as string };
  }

  if (POLYGON_OWNER_ADDRESS_CHECK.every((address) => address !== owner_address_polygon)) {
    return { chain: 'polygon', owner: owner_address_polygon as string };
  }

  return {
    chain: 'mainnet',
    owner: owner_address,
  };
}

export function getKongGenkaiOwnerData({
  owner_address,
  owner_address_ronin,
}: APIKongGenkai | KongGenkai) {
  const onL1 = RONIN_OWNER_ADDRESS_CHECK.every((address) => address !== owner_address_ronin)
    ? false
    : true;
  const owner = onL1 ? owner_address : owner_address_ronin;

  return { onL1, owner: owner || '' };
}

// TO DO remove and actually check in params pipes on API side
export function isValidKongId(type: KollectionType, id: number) {
  switch (type) {
    case 'genesis':
      return id >= 1 && id <= 1000;
    case 'baby':
      return id >= 1001 && id <= 5000;
    case 'vx':
      return id >= 1 && id <= 15000;
    case 'genkai':
      return id >= 1 && id <= 20000;
    default:
      return false;
  }
}
