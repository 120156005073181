import { ThemingConfig } from '@chakra-ui/react';

import { accordionRecipe } from './accordion';
import { alertRecipe } from './alert';
import { buttonRecipe } from './button';
import { cardRecipe } from './card';
import { checkboxRecipe } from './checkbox';
import { containerRecipe } from './container';
import { dialogRecipe } from './dialog';
import { drawerRecipe } from './drawer';
import { fieldRecipe } from './field';
import { inputRecipe, textareaRecipe } from './form';
import { headingRecipe } from './heading';
import { linkRecipe } from './link';
import { numberInputRecipe } from './numberInput';
import { radioGroupRecipe } from './radio';
import { sliderRecipe } from './slider';
import { switchRecipe } from './switch';
import { tooltipRecipe } from './tooltip';

export const slotRecipes: ThemingConfig['slotRecipes'] = {
  tooltip: tooltipRecipe,
  card: cardRecipe,
  field: fieldRecipe,
  switch: switchRecipe,
  accordion: accordionRecipe,
  alert: alertRecipe,
  radioGroup: radioGroupRecipe,
  checkbox: checkboxRecipe,
  drawer: drawerRecipe,
  numberInput: numberInputRecipe,
  slider: sliderRecipe,
  dialog: dialogRecipe,
};

export const recipes: ThemingConfig['recipes'] = {
  link: linkRecipe,
  container: containerRecipe,
  input: inputRecipe,
  textarea: textareaRecipe,
  heading: headingRecipe,
  button: buttonRecipe,
};
