import { defineSlotRecipe } from '@chakra-ui/react';

import { pxToRem } from '../utils';

export const alertRecipe = defineSlotRecipe({
  slots: ['root', 'indicator', 'content'],
  base: {
    root: {
      borderRadius: 'lg',
      border: 'thin',
      color: 'textSecondary',
      p: 4,
      fontSize: 'sm',
    },
    indicator: {
      position: 'relative',
      top: '2px',
      w: pxToRem(18),
      h: 'auto',
    },
    content: {
      gap: 0,
    },
  },
  variants: {
    status: {
      info: {
        root: {
          borderColor: 'teal.700',
        },
      },
      success: {
        root: {
          borderColor: 'green.700',
        },
      },
      warning: {
        root: {
          borderColor: 'yellow.700',
        },
      },
      error: {
        root: {
          borderColor: 'red.700',
        },
      },
    },
  },
  defaultVariants: {
    status: 'info',
  },
});
