import {
  Flex,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
  SystemStyleObject,
} from '@chakra-ui/react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { PropsWithChildren, ReactNode } from 'react';

import { pxToRem } from '../theme';
import { LinkArrowIcon } from './icons';

export type AppLinkProps = NextLinkProps &
  Omit<ChakraLinkProps, 'href'> & {
    children: ReactNode;
    variant?: string;
    css?: SystemStyleObject;
  };

export function AppLink({ href, children, target, ...rest }: AppLinkProps) {
  const propsHref = typeof href === 'string' ? href : href?.pathname;
  const isInternalLink = propsHref ? propsHref.startsWith('/') || propsHref.startsWith('#') : false;

  return !target && isInternalLink ? (
    <ChakraLink asChild {...rest}>
      <NextLink href={href} target={target}>
        {children}
      </NextLink>
    </ChakraLink>
  ) : (
    <ChakraLink
      href={href as string}
      target={target || '_blank'}
      rel={'noopener noreferrer'}
      {...rest}
    >
      {children}
    </ChakraLink>
  );
}

type TextWithArrowProps = PropsWithChildren & {
  stylesContainer?: SystemStyleObject;
  sxIcon?: SystemStyleObject;
};

export function TextWithArrow({ stylesContainer, sxIcon, children }: TextWithArrowProps) {
  return (
    <Flex css={{ alignItems: 'center', gap: 2, ...stylesContainer }}>
      {children}
      <LinkArrowIcon width={pxToRem(21)} height="auto" css={sxIcon} />
    </Flex>
  );
}

export function AppLinkWithArrow({
  stylesContainer,
  sxIcon,
  children,
  ...rest
}: AppLinkProps & { stylesContainer?: SystemStyleObject; sxIcon?: SystemStyleObject }) {
  return (
    <AppLink {...rest}>
      <TextWithArrow {...{ stylesContainer, sxIcon }}>{children}</TextWithArrow>
    </AppLink>
  );
}
