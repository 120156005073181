const appColors = {
  backgroundSecondaryAlpha40: { value: 'rgba(22, 22, 22, 0.4)' },
  backgroundSecondaryAlpha60: { value: 'rgba(22, 22, 22, 0.6)' },
  backgroundSecondaryAlpha85: { value: 'rgba(22, 22, 22, 0.85)' },
};

const baseColors = {
  text: { value: '#FFF' },
  textSecondary: { value: '#C6C6C6' }, // neutral.200
  textTertiary: { value: '#8D8D8D' }, // neutral.400
  textDisabled: { value: '#525252' }, // neutral.600

  textAlt: { value: '#161616' },

  background: { value: '#000' },
  backgroundSecondary: { value: '#161616' }, // neutral.900
  backgroundTertiary: { value: '#262626' }, // neutral.800

  backgroundCards: { value: '#1C1C1C' },
  backgroundCardsTransparent: { value: 'rgba(111, 111, 111, 0.2)' },
  backgroundCardsTransparentSecondary: { value: 'rgba(255, 255, 255, 0.04)' },

  backgroundCardsTransparentDark: { value: 'rgba(22, 22, 22, 0.85)' },

  backgroundLandingCards: { value: '#101010' },
  backgroundLandingCardsTransparent: { value: 'rgba(95, 95, 95, 0.15)' },

  // brand colors
  primary: { value: '#45F08C' },
  secondary: { value: '#F21DDD' },
  tertiary: { value: '#23D5DB' },

  modalOverlay: { value: 'rgba(0, 0, 0, 0.6)' },

  // colors palette
  neutral: {
    25: { value: '#F4F4F4' },
    50: { value: '#E8E8E8' },
    100: { value: '#DBDBDB' },
    200: { value: '#C6C6C6' },
    300: { value: '#A8A8A8' },
    400: { value: '#8D8D8D' },
    500: { value: '#6F6F6F' },
    600: { value: '#525252' },
    700: { value: '#393939' },
    800: { value: '#262626' },
    850: { value: '#1C1C1C' },
    900: { value: '#161616' },
  },

  green: {
    25: { value: '#E4FFED' },
    50: { value: '#CEFFE4' },
    100: { value: '#AAFFD1' },
    200: { value: '#7BFFB4' },
    300: { value: '#45F08C' },
    400: { value: '#24C774' },
    500: { value: '#15985F' },
    600: { value: '#077149' },
    700: { value: '#005338' },
    800: { value: '#003D2C' },
    900: { value: '#002E23' },
  },

  pink: {
    25: { value: '#FFF3FE' },
    50: { value: '#FFDCFF' },
    100: { value: '#FFB5FF' },
    200: { value: '#FF80FF' },
    300: { value: '#FF42F6' },
    400: { value: '#F21DDD' },
    500: { value: '#C90CAF' },
    600: { value: '#97007E' },
    700: { value: '#6E0058' },
    800: { value: '#4D003C' },
    900: { value: '#330026' },
  },

  teal: {
    25: { value: '#DEFFFC' },
    50: { value: '#BCF7F6' },
    100: { value: '#99F0F0' },
    200: { value: '#71EBEB' },
    300: { value: '#49E5E5' },
    400: { value: '#23D5DB' },
    500: { value: '#00AEC2' },
    600: { value: '#017C8F' },
    700: { value: '#005E70' },
    800: { value: '#004452' },
    900: { value: '#032931' },
  },

  red: {
    50: { value: '#FFF1F1' },
    100: { value: '#FFD7D9' },
    200: { value: '#FFB3B8' },
    300: { value: '#FF8389' },
    400: { value: '#FA4D56' },
    500: { value: '#DA1E28' },
    600: { value: '#A2191F' },
    700: { value: '#750E13' },
    800: { value: '#520408' },
    900: { value: '#2D0709' },
  },

  yellow: {
    50: { value: '#FCF4D6' },
    100: { value: '#FDDC69' },
    200: { value: '#F1C21B' },
    300: { value: '#D2A106' },
    400: { value: '#B28600' },
    500: { value: '#8E6A00' },
    600: { value: '#684E00' },
    700: { value: '#483700' },
    800: { value: '#302400' },
    900: { value: '#241B00' },
  },
};

const genkaiColors = {
  background: { value: '#F2EEEC' },
  backgroundAlpha15: { value: 'rgba(242, 238, 236, 0.15)' },
  backgroundAlpha40: { value: 'rgba(242, 238, 236, 0.4)' },
  backgroundAlpha70: { value: 'rgba(242, 238, 236, 0.7)' },
  backgroundSecondary: { value: '#6C605E' },

  text: { value: '#4A3D3A' },
  textSecondary: { value: '#525252' },

  primary: { value: '#4A3D3A' },
  primaryAlpha10: { value: 'rgba(74, 61, 58, 0.1)' },
  primaryAlpha25: { value: 'rgba(74, 61, 58, 0.25)' },
  primaryAlpha85: { value: 'rgba(74, 61, 58, 0.85)' },
  primaryAlpha90: { value: 'rgba(74, 61, 58, 0.9)' },

  backgroundCardsTransparent: { value: 'rgba(74, 61, 58, 0.1)' },

  characterEvent: {
    stageCardBadgeBgActiveAlpha25: { value: 'rgba(0, 94, 112, 0.25)' },
    nudgeLightBgAlpha20: { value: 'rgba(255, 255, 255, 0.2)' },
  },
};

export const colors = {
  ...baseColors,
  app: appColors,
  genkai: genkaiColors,
};

export const semanticColors = {
  error: {
    DEFAULT: { value: 'colors.red.400' },
  },
  red: {
    DEFAULT: { value: 'colors.red.600' },
    solid: { value: 'colors.red.600' },
    subtle: { value: 'colors.red.900' },
  },
  orange: {
    DEFAULT: { value: 'colors.yellow.600' },
    solid: { value: 'colors.yellow.600' },
    subtle: { value: 'colors.yellow.900' },
  },
  green: {
    DEFAULT: { value: 'colors.green.600' },
    solid: { value: 'colors.green.600' },
    subtle: { value: 'colors.green.900' },
  },
  blue: {
    DEFAULT: { value: 'colors.teal.600' },
    solid: { value: 'colors.teal.600' },
    subtle: { value: 'colors.teal.900' },
  },
};
