import { SystemStyleObject } from '@chakra-ui/react';
import { AppLink, PlayCircleIcon, pxToRem } from '@cyberkongz/ui';

import { Image } from './AssetWrapper';

const youtubePlayerTriggerStyles: SystemStyleObject = {
  cursor: 'pointer',
  position: 'relative',
  '& svg': {
    position: 'absolute',
    width: [pxToRem(50), pxToRem(80)],
    height: [pxToRem(50), pxToRem(80)],
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Don't embed YT video in our site due to cookies policy
export function YoutubeVideoWithPreviewImage({
  src,
  thumbnail,
  sxImage,
}: {
  src: string;
  thumbnail?: string;
  sxImage?: SystemStyleObject;
}) {
  // parsed if eg. blog post contains link to YT with embed
  const srcParsed = src.replace('embed', 'watch');

  return (
    <>
      <AppLink href={srcParsed} css={youtubePlayerTriggerStyles}>
        <Image
          src={thumbnail || `https://i1.ytimg.com/vi/${srcParsed.split('/watch/')[1]}/hq720.jpg`}
          alt="YouTube video thumbnail"
          css={{ opacity: thumbnail ? 1 : '0.6', ...sxImage }}
        />
        <PlayCircleIcon />
      </AppLink>
    </>
  );
}
