import { Box, Grid, SystemStyleObject } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

import { pxToRem, TRANSITIONS } from '../theme';
import { AppLink } from './AppLink';

export function DropdownBase({
  dropdownOpened,
  children,
  stylesContainer,
  sxCard,
}: {
  dropdownOpened: boolean;
  sxCard?: SystemStyleObject;
  stylesContainer?: SystemStyleObject;
} & PropsWithChildren) {
  return (
    <Box
      css={{
        position: 'absolute',
        display: dropdownOpened ? 'block' : 'none',
        bottom: 0,
        pt: 1,
        transform: 'translate(0%, 100%)',
        right: '0',
        'a:hover': { textDecoration: 'none' },
        ...stylesContainer,
      }}
    >
      <Grid
        css={{
          bg: 'backgroundSecondary',
          width: pxToRem(152),
          py: 1,
          fontSize: 'md',
          color: 'textSecondary',
          borderRadius: 'lg',
          ...sxCard,
        }}
      >
        {children}
      </Grid>
    </Box>
  );
}

const dropdownItemStyles: SystemStyleObject = {
  cursor: 'pointer',
  px: 4,
  py: 3,
  '&:hover': { bg: 'neutral.850', transition: TRANSITIONS.standard },
};

export function DropdownItem({
  href,
  onClick,
  children,
}: PropsWithChildren & { href?: string; onClick?: () => void }) {
  return href ? (
    <AppLink css={dropdownItemStyles} href={href}>
      {children}
    </AppLink>
  ) : (
    <Box css={dropdownItemStyles} onClick={onClick}>
      {children}
    </Box>
  );
}
