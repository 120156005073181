import { Box, Container, Flex, Grid, SystemStyleObject, Text } from '@chakra-ui/react';
import { useMemo } from 'react';

import { LANDING_ROUTES, SHARED_ROUTES } from '../routes';
import { pxToRem } from '../theme';
import { AppLink } from './AppLink';
import { LogoFullIcon, SocialIcons } from './icons';

// TO DO, remove/unify those Decorations?????
const squareDotsStyles: SystemStyleObject = {
  display: 'block',
  position: 'absolute',
  zIndex: 1,
  content: '""',
  bg: 'neutral.700',
  w: 1,
  h: 1,
  top: '50%',
  transform: 'translate(-50%, -50%)',
};

export function BorderWithSquareDots() {
  return (
    <Box
      css={{
        position: 'relative',
        border: 'thin',
        '&:after': {
          ...squareDotsStyles,
          right: 0,
          transform: 'translate(50%, -50%)',
        },
        '&:before': {
          ...squareDotsStyles,
          left: 0,
        },
      }}
    />
  );
}

export function Footer({ landingRoutes }: { landingRoutes: typeof LANDING_ROUTES }) {
  const LINKS = useMemo(
    () => [
      { href: landingRoutes.ABOUT, label: 'About' },
      { href: landingRoutes.FAQ, label: 'FAQ' },
      { href: landingRoutes.KOLLECTIONS, label: 'Kollections' },
      { href: landingRoutes.BLOG, label: 'Blog' },
      { href: landingRoutes.BANANA, label: '$BANANA' },
      { href: SHARED_ROUTES.VOTE, label: 'Vote' },
      { href: landingRoutes.BLUEPRINT, label: 'Blueprint' },
      { href: landingRoutes.PLAY_AND_KOLLECT, label: 'Play & Kollect' },
      { href: landingRoutes.PR_KIT, label: 'Press Kit' },
    ],
    []
  );

  return (
    <Box
      as="footer"
      css={{
        borderTop: 'thin',
        pt: [8, 20],
        pb: [8, 16],
        color: 'textSecondary',
        bg: 'background',
        position: 'relative',
        zIndex: 4,
      }}
    >
      <Container>
        <Flex
          css={{
            flexDirection: ['column', 'row'],
            pb: [8, 20],
            justifyContent: 'space-between',
          }}
        >
          <Box css={{ pr: [0, 20], mb: [7, 0] }}>
            <Box css={{ '& svg': { maxWidth: [pxToRem(134), '100%'] } }}>
              <LogoFullIcon />
            </Box>
            <Box css={{ maxWidth: '31.125em', mt: [2, 5] }}>
              CyberKongz are unique and randomly generated 2D/3D NFT Social Avatars for your online
              experiences.
            </Box>
          </Box>
          <Grid
            templateColumns={'repeat(2, 1fr)'}
            gap={4}
            css={{ justifyContent: 'space-between', maxWidth: pxToRem(600), width: '100%' }}
          >
            {LINKS.map(({ href, label }) => (
              <Box key={href}>
                <AppLink variant="footer" href={href}>
                  {label}
                </AppLink>
              </Box>
            ))}
          </Grid>
        </Flex>
        <BorderWithSquareDots />
        <Flex
          css={{
            flexDirection: ['column', 'row'],
            mt: [8, 12],
            justifyContent: 'space-between',
            alignItems: ['flex-start', 'center'],
          }}
        >
          <Flex
            css={{
              flexDirection: ['column', 'row'],
              alignItems: ['flex-start', 'center'],
              fontSize: 'xs',
            }}
          >
            <Box css={{ pr: 4, borderRight: ['none', 'thin'], mr: 4 }}>
              All Rights Reserved © CyberKongz.{' '}
              <Text display={['block', 'inline-block']}>
                Design&nbsp;by&nbsp;
                <AppLink css={{ textDecoration: 'underline' }} href={SHARED_ROUTES.SIXTY_TWO}>
                  Sixty&nbsp;Two
                </AppLink>
              </Text>
            </Box>
            <AppLink
              variant="footer"
              href={landingRoutes.TERMS('terms-and-conditions')}
              css={{ my: [8, 0] }}
            >
              {' '}
              Terms and Conditions
            </AppLink>
          </Flex>
          <SocialIcons />
        </Flex>
      </Container>
    </Box>
  );
}
