import { Box, Flex, SystemStyleObject } from '@chakra-ui/react';
import { PARTNERS } from '@content/partners';
import { pxToRem } from '@cyberkongz/ui';
import { keyframes } from '@emotion/react';
import { Fragment, PropsWithChildren } from 'react';

import { Image } from './AssetWrapper';

const marqueeRightAnimation = keyframes`
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
`;

const marqueeLeftAnimation = keyframes`
  0% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(0%);
  }
`;

const marqueeUpAnimation = keyframes`
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-50%);
  }
`;

const marqueeDownAnimation = keyframes`
  0% {
    transform: translateY(-50%);
  }

  100% {
    transform: translateY(0%);
  }
`;

const animationDirectionMapping = {
  up: marqueeUpAnimation,
  down: marqueeDownAnimation,
  right: marqueeRightAnimation,
  left: marqueeLeftAnimation,
};

export function MarqueeItem({
  direction,
  children,
  sxContent,
}: {
  direction: keyof typeof animationDirectionMapping;
  sxContent?: SystemStyleObject;
} & PropsWithChildren) {
  const isColumn = direction === 'up' || direction === 'down';

  return (
    <Box css={{ position: 'relative', overflow: 'hidden' }}>
      <Flex
        css={{
          width: 'fit-content',
          position: 'relative',
          animation: `${animationDirectionMapping[direction]} ${
            isColumn ? 40 : 20
          }s linear infinite`,
          gap: 3,
          flexDirection: isColumn ? 'column' : 'row',
          ...sxContent,
        }}
      >
        {Array.from(Array(2).keys()).map((_, i) => (
          <Fragment key={i}>{children}</Fragment>
        ))}
      </Flex>
    </Box>
  );
}

export function PartnersMarquee() {
  return (
    <MarqueeItem
      direction="right"
      sxContent={{ animationDuration: `${PARTNERS.length * 2}s`, gap: 20 }}
    >
      <Flex css={{ alignItems: 'center', gap: 20 }}>
        {PARTNERS.map(({ image, label: alt }) => (
          <Image
            css={{
              maxWidth: [pxToRem(120), pxToRem(150)],
              maxHeight: [pxToRem(85)],
            }}
            key={image}
            src={image}
            alt={alt}
          />
        ))}
      </Flex>
    </MarqueeItem>
  );
}
