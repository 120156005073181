import { keyBy } from 'lodash';

import { CDN_ROUTE } from './consts';
import { ERC_1155_BY_KEY, ERC1155TokenConfig } from './tokens';
import { RarityType } from './types';

type CharmTokenBase = ERC1155TokenConfig & {
  description: string;
  rarity: RarityType;
  kongiumBonusDescription: string;
  expBonusDescription: string;
};

export type CharmTokenType = CharmTokenBase & {
  imageSmall: string;
  imageMedium: string;
  imageBig: string;
  imageTransparent: string;
};

function getCharmzImages(itemName: string) {
  const parsedItemName = itemName.replace(`'`, '').split(' ').join('_').toLowerCase();

  const baseUrl = `${CDN_ROUTE}/static/icons/charmz`;

  return {
    imageSmall: `${baseUrl}/40x40/${parsedItemName}.png`,
    imageMedium: `${baseUrl}/175x175/${parsedItemName}.png`,
    imageBig: `${baseUrl}/400x400/${parsedItemName}.png`,
    imageTransparent: `${baseUrl}/transparent/${parsedItemName}.png`,
  };
}

const CHARMZ_TOKENS_BASE: CharmTokenBase[] = [
  {
    ...ERC_1155_BY_KEY.cyber_fragment,
    description: `A common memory device, typically used in Cyber Wearables and often found in scrap yards. They might contain memory fragments of it's previous owner which may help to locate forgotten Kongium veins.`,
    rarity: 'Common',
    kongiumBonusDescription: '20% double Kongium',
    expBonusDescription: '10% double EXP',
    ...getCharmzImages(ERC_1155_BY_KEY.cyber_fragment.name),
  },
  {
    ...ERC_1155_BY_KEY.rainbow_crystal,
    description: `A rare, naturally occurring glass that can be used to manufacture wearables and lenses. Looking through it can unveil objects that are not visible to the naked eye.`,
    rarity: 'Rare',
    kongiumBonusDescription: '40% double Kongium',
    expBonusDescription: '20% double EXP',
    ...getCharmzImages(ERC_1155_BY_KEY.rainbow_crystal.name),
  },
  {
    ...ERC_1155_BY_KEY.promethean_relic,
    description: `A high-tech device with unknown origin. No one knows how it operates but some do know how to forge it into advantageous tech. The energy it radiates excites naturally occurring Kongium.`,
    rarity: 'Legendary',
    kongiumBonusDescription: '100% double Kongium',
    expBonusDescription: '50% double EXP',
    ...getCharmzImages(ERC_1155_BY_KEY.promethean_relic.name),
  },
];

export const CHARMZ_TOKENS: CharmTokenType[] = CHARMZ_TOKENS_BASE.map((charm) => ({
  ...charm,
  ...getCharmzImages(charm.name),
}));

export const CHARM_BY_ID = keyBy(CHARMZ_TOKENS, 'tokenId');

export const MAX_CHARM_HP = 10000;

export const CHARMZ_BY_KEY = keyBy(CHARMZ_TOKENS, 'key');
