import { createSystem, defaultConfig, defineConfig } from '@chakra-ui/react';

import { colors, semanticColors } from './colors';
import { recipes, slotRecipes } from './recipes';
import { textStyles } from './textStyles';
import { typography } from './typography';

const baseTheme = defineConfig({
  theme: {
    breakpoints: {
      sm: '48em',
      md: '64em',
      lg: '80em',
    },
    tokens: {
      borders: {
        thin: { value: '1px solid var(--chakra-colors-neutral-700)' },
        thinWhite: { value: '1px solid var(--chakra-colors-white)' },
        mediumWhite: { value: '2px solid var(--chakra-colors-white)' },
        genkai: {
          thin: {
            value: '1px solid {colors.genkai.primary}',
          },
        },
      },
      zIndex: {
        modal: { value: 1400 },
        menu: { value: 6 },
        footer: { value: 5 },
      },
      shadows: {
        dropdownCard: {
          value:
            '0px 4px 6px -2px rgba(16, 24, 40, 0.05), 0px 12px 16px -4px rgba(16, 24, 40, 0.10)',
        },
      },
      colors,
      ...typography,
    },
    semanticTokens: {
      colors: semanticColors,
    },
    textStyles,
    recipes,
    slotRecipes,
  },
  globalCss: {
    html: {
      scrollPaddingTop: 'var(--scroll-padding-top)',
      '--w3m-z-index': 'var(--chakra-zIndices-toast)!important',
    },
    body: {
      background: 'background',
      color: 'text',
      fontSize: { base: 'sm', sm: 'md' },
      fontWeight: 'normal',
      fontFamily: 'body',
    },
    strong: {
      fontWeight: 'medium',
    },
    '& svg': {
      fontSize: '0px',
    },
    '*::selection': {
      bg: 'blue.700',
    },
    '*::placeholder': {
      color: 'textTertiary',
    },
  },
  cssVarsRoot: 'body',
});

const theme = createSystem(defaultConfig, baseTheme);

export default theme;
