import { Box, Container, Grid, Heading } from '@chakra-ui/react';
import {
  AppLinkWithArrow,
  AppSpinner,
  GradientShadowOverlay,
  pxToRem,
  useIsClient,
} from '@cyberkongz/ui';

import { RectangleDecorationWithRotatedBorder } from './Decorations';

type NumberStat = { number?: string; label: string; href?: string };

function NumberStat({ number, label, href }: NumberStat) {
  const isClient = useIsClient();

  return (
    <Box
      css={{
        width: ['auto', '100%'],
        textAlign: 'center',
        px: 3,
        py: [10, 0],
        borderRight: ['none', 'thin'],
        borderBottom: ['thin', 'none'],
        borderColor: ['white', 'white'],
        '&:last-of-type': { border: 'none' },
      }}
    >
      <Heading fontSize={['4xl', '3xl']}>{isClient && number ? number : <AppSpinner />}</Heading>
      <Box
        css={{
          mt: [3, 4],
          color: 'textSecondary',
          textTransform: 'uppercase',
          fontSize: 'md',
        }}
      >
        {href ? (
          <AppLinkWithArrow stylesContainer={{ justifyContent: 'center' }} href={href}>
            {label}
          </AppLinkWithArrow>
        ) : (
          label
        )}
      </Box>
    </Box>
  );
}

export function NumberStatsSection({ numberStats }: { numberStats: NumberStat[] }) {
  return (
    <Box my={[16, pxToRem(120)]} position="relative">
      <GradientShadowOverlay top />
      <Container py={pxToRem(10)} position="relative" zIndex="2">
        <RectangleDecorationWithRotatedBorder
          stylesWrapper={{
            mr: pxToRem(36),
            ml: 'auto',
          }}
          sxRectangle={{
            ml: 'auto',
            mr: '0',
            w: [pxToRem(104), pxToRem(301)],
            h: [pxToRem(4), pxToRem(6)],
          }}
          sxRotatedBorder={{
            right: pxToRem(1),
            transformOrigin: 'top right',
          }}
        />
        <Grid
          css={{
            my: [2, pxToRem(100)],
            gridTemplateColumns: ['repeat(1, 1fr)', 'repeat(3, 1fr)'],
            justifyItems: ['center', 'initial'],
          }}
        >
          {numberStats.map((numberStat) => (
            <NumberStat key={numberStat.label} {...numberStat} />
          ))}
        </Grid>
        <RectangleDecorationWithRotatedBorder
          stylesWrapper={{
            ml: pxToRem(36),
          }}
          sxRectangle={{
            w: [pxToRem(104), pxToRem(301)],
            h: [pxToRem(4), pxToRem(6)],
            transform: 'translateY(100%)',
          }}
        />
      </Container>
    </Box>
  );
}
