const fontSizes = {
  '2xs': { value: '0.625rem' }, //   10
  xs: { value: '0.75rem' }, //       12
  sm: { value: '0.875rem' }, //      14
  md: { value: '1rem' }, //          16
  lg: { value: '1.125rem' }, //      18
  xl: { value: '1.25rem' }, //       20
  '2xl': { value: '1.5rem' }, //     24
  '2.5xl': { value: '1.75rem' }, //  28
  '3xl': { value: '2rem' }, //       32
  '4xl': { value: '2.5rem' }, //     40
  '5xl': { value: '3rem' }, //       48
  '6xl': { value: '3.5rem' }, //     56
  '7xl': { value: '4rem' }, //       64
  '8xl': { value: '4.5rem' }, //     72
  '9xl': { value: '5rem' }, //       80
  '10xl': { value: '6rem' }, //      96
};

export const typography = {
  fontSizes,
  fonts: {
    body: { value: '"IBM Plex Sans", sans-serif' },
    heading: { value: '"Bios", sans-serif' },
    hyperwaveOne: { value: '"Hyperwave One", sans-serif' },
  },
};
