import { Box, Button, Flex, Grid, Heading, SystemStyleObject, Text } from '@chakra-ui/react';
import Script from 'next/script';
import { useEffect, useState } from 'react';

import {
  AccordionItem,
  AccordionItemContent,
  AccordionItemTrigger,
  AccordionRoot,
  AppLink,
  BaseModalProps,
  Card,
  Modal,
  Switch,
} from '../../components';
import { useIsClient, useLocalStorage } from '../../hooks';
import { LANDING_ROUTES_ABSOLUTE } from '../../routes';
import { customScrollbarStyles, getClipPathBackground, pxToRem, TRANSITIONS } from '../../theme';

export const LS_COOKIES_SETTINGS = 'cookiesSettings';

const COOKIES_CATEGORIES = ['analytics_storage', 'ad_storage'] as const;
type CookiesCategory = typeof COOKIES_CATEGORIES[number];
type EnabledCookies = {
  [key in CookiesCategory]: boolean;
};

// TO DO ADD Cookie Policy version to change if policy changed and retrigger popup
type CookiesSettings = {
  status: 'interacted' | 'notInteracted';
  accepted: boolean;
  enabledCookies: EnabledCookies;
};

export const ALL_COOKIES_ENABLED = COOKIES_CATEGORIES.reduce((cookies, cookieCategory) => {
  cookies[cookieCategory] = true;

  return cookies;
}, {} as EnabledCookies);

export const ALL_COOKIES_DISABLED = COOKIES_CATEGORIES.reduce((cookies, cookieCategory) => {
  cookies[cookieCategory] = false;

  return cookies;
}, {} as EnabledCookies);

// TO DO COPY AFTER LEGAL
const COOKIES_CATEGORIES_ITEMS: {
  title: string;
  description: string;
  category?: CookiesCategory;
}[] = [
  {
    title: 'Necessary',
    description:
      'Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. These cookies do not store any personally identifiable data.',
    category: undefined,
  },
  {
    title: 'Analytics',
    description: `We use analytics cookies to collect statistical information about how you use our website, to improve the website's functionality and performance, and to understand your preferences. The information collected through analytics cookies may include user IP addresses, device information, browser type, and other relevant information. Rest assured, however, that CyberKongz never stores your personal information or information that could be used to identify you. We use third-party analytics providers, such as Google Analytics to help us analyze this information. By using our website, you consent to the use of analytics cookies for the purposes described above. If you wish to opt-out of the use of analytics cookies, you can disable cookies in your browser settings or use a cookie opt-out tool`,
    category: 'analytics_storage',
  },
  {
    title: 'Advertisement',
    description: `We use cookies and similar technologies to provide you with tailored advertising on our website and third-party websites. This may include targeted ads based on your browsing history, preferences, and other information collected through cookies and similar technologies. We may work with advertising partners who use these technologies to provide personalized ads on our behalf. Please note, however, that CyberKongz never stores users' personal information. By using our website, you agree to the use of cookies and similar technologies for advertising purposes.`,
    category: 'ad_storage',
  },
];

const cookieBannerButtonStyles: SystemStyleObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
  color: 'text',
  h: 10,
  border: '1px solid white',
  transition: TRANSITIONS.standard,
  '&:hover': {
    bg: 'text',
    color: 'background',
  },
};

const cookieBannerOutlineButtonStyles: SystemStyleObject = {
  ...cookieBannerButtonStyles,
  color: 'background',
  bg: 'white',
  '&:hover': {
    color: 'text',
    bg: 'transparent',
  },
};

function CookieModal({
  acceptAll,
  rejectAll,
  customizeCookies,
  toggleCookiesCategory,
  enabledCookies,
  ...rest
}: BaseModalProps & {
  acceptAll: () => void;
  rejectAll: () => void;
  customizeCookies: () => void;
  toggleCookiesCategory: (cookieCategory: CookiesCategory) => void;
  enabledCookies: CookiesSettings['enabledCookies'];
}) {
  return (
    <Modal
      {...rest}
      styles={{
        content: {
          minWidth: ['100%', pxToRem(600)],
          clipPath: getClipPathBackground(pxToRem(20)),
        },
        body: {
          px: 0,
          py: 8,
        },
      }}
    >
      <Heading size="2xs" css={{ borderBottom: 'thin', pb: 6, px: 8 }}>
        Customize Consent Preferences
      </Heading>
      <Box css={{ px: 3, mt: 4 }}>
        <Box css={{ maxHeight: pxToRem(368), overflow: 'auto', px: 5, ...customScrollbarStyles }}>
          <Text css={{ pb: 4, borderBottom: 'thin', mb: 5 }}>
            We use cookies to help you navigate efficiently and perform certain functions. You will
            find detailed information about the types of cookies we generally use under each consent
            category below. The cookies that are categorized as &quot;Necessary&quot; are stored on
            your browser as they are essential for enabling the basic functionality of the site. We
            also use third-party cookies that help us analyze how you use this website, store your
            preferences, and provide the content and advertisements that may be relevant to you.
            These cookies will only be stored in your browser with your consent. You can choose to
            enable or disable some or all of these cookies but disabling some of them may affect
            your browsing experience.
          </Text>
          <AccordionRoot size="sm" multiple collapsible>
            <Grid gap={3} pr={4}>
              {COOKIES_CATEGORIES_ITEMS.map(({ title, description, category }) => (
                <AccordionItem key={title} value={title}>
                  <AccordionItemTrigger indicatorPlacement="start">
                    <Flex gap={2} justifyContent="space-between" alignItems="center" width="100%">
                      <Heading size="2xs">{title}</Heading>
                      {category ? (
                        <Switch
                          data-testid={`${category}-checkbox`}
                          checked={enabledCookies[category]}
                          onChange={() => toggleCookiesCategory(category)}
                        />
                      ) : (
                        <Text textStyle="p-md" color="primary" fontWeight="medium">
                          Always active
                        </Text>
                      )}
                    </Flex>
                  </AccordionItemTrigger>
                  <AccordionItemContent>{description}</AccordionItemContent>
                </AccordionItem>
              ))}
            </Grid>
          </AccordionRoot>
        </Box>
        <Grid css={{ gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'], gap: [2, 4], mt: 4, px: 5 }}>
          <Button variant="none" onClick={rejectAll} css={cookieBannerButtonStyles}>
            Reject all
          </Button>
          <Button variant="none" onClick={customizeCookies} css={cookieBannerButtonStyles}>
            Save My Preferences
          </Button>
          <Button variant="none" onClick={acceptAll} css={cookieBannerOutlineButtonStyles}>
            Accept all
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
}

export function CookieBanner({ privacyLink }: { privacyLink?: string }) {
  const [cookiesSettings, setCookiesSettings] = useLocalStorage<CookiesSettings>(
    LS_COOKIES_SETTINGS,
    {
      status: 'notInteracted',
      accepted: false,
      enabledCookies: ALL_COOKIES_DISABLED,
    }
  );
  const [enabledCookies, setEnabledCookies] =
    useState<CookiesSettings['enabledCookies']>(ALL_COOKIES_ENABLED);
  const [isOpened, setIsOpened] = useState(false);
  const isClient = useIsClient();

  function rejectAll() {
    setCookiesSettings({
      status: 'interacted',
      accepted: false,
      enabledCookies: ALL_COOKIES_DISABLED,
    });
  }

  function acceptAll() {
    setCookiesSettings({
      status: 'interacted',
      accepted: true,
      enabledCookies: ALL_COOKIES_ENABLED,
    });
  }

  function customizeCookies() {
    setCookiesSettings({
      status: 'interacted',
      accepted: true,
      enabledCookies: COOKIES_CATEGORIES.reduce((cookies, cookieCategory) => {
        cookies[cookieCategory] = enabledCookies[cookieCategory];

        return cookies;
      }, {} as EnabledCookies),
    });
  }

  function toggleCookiesCategory(cookieCategory: CookiesCategory) {
    setEnabledCookies((prevValue) => ({
      ...prevValue,
      [cookieCategory]: !prevValue[cookieCategory],
    }));
  }

  useEffect(() => {
    if (cookiesSettings.accepted) {
      const enabledCookies = COOKIES_CATEGORIES.reduce((cookies, cookieCategory) => {
        cookies[cookieCategory] = cookiesSettings.enabledCookies[cookieCategory]
          ? 'granted'
          : 'denied';

        return cookies;
      }, {} as { [key in CookiesCategory]: 'granted' | 'denied' });

      window.gtag('consent', 'update', enabledCookies);
    }
  }, [cookiesSettings]);

  const showCookies = isClient ? cookiesSettings.status === 'notInteracted' : false;

  return (
    <>
      <Script
        id="gtm"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}

          gtag('consent', 'default', {
            'ad_storage': 'denied',
            'analytics_storage': 'denied'
          });

          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTM_ID}')
          `,
        }}
      />
      {showCookies ? (
        isOpened ? (
          <CookieModal
            isOpen={isOpened}
            onClose={() => setIsOpened(false)}
            acceptAll={acceptAll}
            rejectAll={rejectAll}
            customizeCookies={customizeCookies}
            toggleCookiesCategory={toggleCookiesCategory}
            enabledCookies={enabledCookies}
          />
        ) : (
          <Box
            css={{
              position: 'fixed',
              bottom: [2, 10],
              left: [2, 8],
              maxWidth: [`calc(100% - ${pxToRem(16)})`, '30em'],
              zIndex: 'toast',
            }}
          >
            <Card variant="landing" size="lg">
              <Heading size="2xs">We value your privacy</Heading>
              <Text textStyle="p-sm" css={{ mt: 2, mb: [4, 7], lineHeight: '1.75' }}>
                We primarily use cookies to enhance your browsing experience, serve personalized ads
                and/or content, and to analyze our traffic. By clicking &quot;Accept All&quot;, you
                acknowledge that you understand and consent to our use of cookies.&nbsp;
                <AppLink
                  href={privacyLink || LANDING_ROUTES_ABSOLUTE.TERMS('privacy-policy')}
                  css={{ textDecoration: 'underline', fontWeight: 'medium' }}
                >
                  Read More
                </AppLink>
              </Text>
              <Flex gap={[2, 4]}>
                <Button
                  variant="none"
                  css={cookieBannerButtonStyles}
                  onClick={() => setIsOpened(true)}
                >
                  Customize
                </Button>
                <Button variant="none" css={cookieBannerButtonStyles} onClick={rejectAll}>
                  Reject all
                </Button>
                <Button variant="none" css={cookieBannerOutlineButtonStyles} onClick={acceptAll}>
                  Accept all
                </Button>
              </Flex>
            </Card>
          </Box>
        )
      ) : null}
    </>
  );
}
