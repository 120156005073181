export const VRFCoordinatorABI = [
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "requestId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "outputSeed",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint96",
        "name": "payment",
        "type": "uint96"
      },
      {
        "indexed": false,
        "internalType": "bool",
        "name": "success",
        "type": "bool"
      }
    ],
    "name": "RandomWordsFulfilled",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "bytes32",
        "name": "keyHash",
        "type": "bytes32"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "requestId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "preSeed",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "uint64",
        "name": "subId",
        "type": "uint64"
      },
      {
        "indexed": false,
        "internalType": "uint16",
        "name": "minimumRequestConfirmations",
        "type": "uint16"
      },
      {
        "indexed": false,
        "internalType": "uint32",
        "name": "callbackGasLimit",
        "type": "uint32"
      },
      {
        "indexed": false,
        "internalType": "uint32",
        "name": "numWords",
        "type": "uint32"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "sender",
        "type": "address"
      }
    ],
    "name": "RandomWordsRequested",
    "type": "event"
  }
] as const;