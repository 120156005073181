import { defineSlotRecipe } from '@chakra-ui/react';

export const accordionRecipe = defineSlotRecipe({
  slots: ['root', 'itemTrigger', 'itemBody', 'itemIndicator'],
  base: {
    itemTrigger: {
      cursor: 'pointer',
    },
  },
  variants: {
    size: {
      md: {
        itemIndicator: {
          '& svg': {
            width: '12px !important',
          },
        },
        itemTrigger: {
          py: 0,
        },
        itemBody: {
          pt: { base: 4, sm: 5 },
          pb: 0,
        },
      },
    },
  },
});
