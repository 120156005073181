import { CDN_ROUTE } from '../consts';
import { ARMORY_ITEMS } from './armoryItems';
import MODELS_MAPPING_PARSED from './modelsMappingParsed.json';
import MODELS_MAPPING_RAW from './modelsMappingRaw.json';

const MODELS_MAPPING: { [key: string]: string } = MODELS_MAPPING_RAW;

const TORSO_NODES = ['Chest_Global', 'Chest_Global_Back'] as const;
const ARMS_NODES = [
  'Left_Arm',
  'Right_Arm',
  'Left_Forearm',
  'Right_Forearm',
  // Node name on VX model for Left_Glove and Right_Glove
  'Left_Hand_Skin',
  'Right_Hand_Skin',
] as const;

const LEGS_NODES = [
  'Left_Leg',
  'Right_Leg',
  'Left_Thigh',
  'Right_Thigh',
  'Left_Foot',
  'Right_Foot',
] as const;

type NodeTorsoType = typeof TORSO_NODES[number];
type NodeArmsType = typeof ARMS_NODES[number];
type NodeLegsType = typeof LEGS_NODES[number];

type NodeType = 'Left_Hand' | 'Right_Hand' | NodeTorsoType | NodeLegsType | NodeArmsType;

export type NodeModels = { node: NodeType; file: string };

function parseItemsMapping() {
  const newMapping: { [key: string]: NodeModels[] } = {};

  ARMORY_ITEMS.forEach((item) => {
    const { slot, name, series } = item;
    const parsedName = name.replaceAll(' ', '_');

    const models: NodeModels[] = [];

    if (slot === 'Main-Hand') {
      const file =
        series === 'Genesis'
          ? `${CDN_ROUTE}/static/models/armory/genesis/weapons/Equip_Right_Hand_Global_${parsedName.toLowerCase()}.glb`
          : MODELS_MAPPING[parsedName];

      models.push({ node: 'Right_Hand', file });
    } else if (slot === 'Off-Hand') {
      const file =
        series === 'Genesis'
          ? `${CDN_ROUTE}/static/models/armory/genesis/shields/Equip_Left_Hand_Global_${parsedName.toLowerCase()}.glb`
          : MODELS_MAPPING[parsedName];

      models.push({ node: 'Left_Hand', file });
    } else if (slot === 'Torso') {
      TORSO_NODES.forEach((node) => {
        models.push({
          node,
          file: MODELS_MAPPING[`${parsedName}${node.replace('Chest_Global', '')}`],
        });
      });
    } else if (slot === 'Arms') {
      ARMS_NODES.forEach((node) => {
        models.push({ node, file: MODELS_MAPPING[`${parsedName}_${node}`] });
      });
    } else if (slot === 'Legs') {
      LEGS_NODES.forEach((node) => {
        models.push({ node, file: MODELS_MAPPING[`${parsedName}_${node}`] });
      });
    }

    newMapping[name] = models.map(({ node, file }) => ({
      node,
      file: file !== undefined ? file : `remove:${node}`,
    }));
  });

  console.log(JSON.stringify(newMapping, null, 2));

  return newMapping;
}

// UNCOMMENT for debugging
// parseItemsMapping();

export const ARMORY_ITEMS_MODELS_MAPPING = MODELS_MAPPING_PARSED as ReturnType<
  typeof parseItemsMapping
>;
