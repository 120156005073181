{
  "Pitchfork": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/weapons/Equip_Right_Hand_Global_pitchfork.glb"
    }
  ],
  "Old Sword": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/weapons/Equip_Right_Hand_Global_old_sword.glb"
    }
  ],
  "Wooden Mallet": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/weapons/Equip_Right_Hand_Global_wooden_mallet.glb"
    }
  ],
  "Daisy": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/weapons/Equip_Right_Hand_Global_daisy.glb"
    }
  ],
  "Cactus On A Stick": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/weapons/Equip_Right_Hand_Global_cactus_on_a_stick.glb"
    }
  ],
  "Kris": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/weapons/Equip_Right_Hand_Global_kris.glb"
    }
  ],
  "Katana": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/weapons/Equip_Right_Hand_Global_katana.glb"
    }
  ],
  "Battle Axe": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/weapons/Equip_Right_Hand_Global_battle_axe.glb"
    }
  ],
  "Kongbone": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/weapons/Equip_Right_Hand_Global_kongbone.glb"
    }
  ],
  "Royal Guard's Hammer": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/weapons/Equip_Right_Hand_Global_royal_guard's_hammer.glb"
    }
  ],
  "Gut Ripper": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/weapons/Equip_Right_Hand_Global_gut_ripper.glb"
    }
  ],
  "Horus Wand": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/weapons/Equip_Right_Hand_Global_horus_wand.glb"
    }
  ],
  "Noctua": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/weapons/Equip_Right_Hand_Global_noctua.glb"
    }
  ],
  "Hellfire": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/weapons/Equip_Right_Hand_Global_hellfire.glb"
    }
  ],
  "Barn Door": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/shields/Equip_Left_Hand_Global_barn_door.glb"
    }
  ],
  "Banana Leaf": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/shields/Equip_Left_Hand_Global_banana_leaf.glb"
    }
  ],
  "Wooden Shield": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/shields/Equip_Left_Hand_Global_wooden_shield.glb"
    }
  ],
  "Oakenshield": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/shields/Equip_Left_Hand_Global_oakenshield.glb"
    }
  ],
  "Bib": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/shields/Equip_Left_Hand_Global_bib.glb"
    }
  ],
  "Knight's Shield": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/shields/Equip_Left_Hand_Global_knight's_shield.glb"
    }
  ],
  "Gladiator's Shield": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/shields/Equip_Left_Hand_Global_gladiator's_shield.glb"
    }
  ],
  "Steelforged Shield": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/shields/Equip_Left_Hand_Global_steelforged_shield.glb"
    }
  ],
  "Spiked Steelguard": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/shields/Equip_Left_Hand_Global_spiked_steelguard.glb"
    }
  ],
  "Royal Guard's Shield": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/shields/Equip_Left_Hand_Global_royal_guard's_shield.glb"
    }
  ],
  "Plutonium Scientist's Protector": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/shields/Equip_Left_Hand_Global_plutonium_scientist's_protector.glb"
    }
  ],
  "Ancient Promethean Aegis": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/shields/Equip_Left_Hand_Global_ancient_promethean_aegis.glb"
    }
  ],
  "Noctua's Shield": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/shields/Equip_Left_Hand_Global_noctua's_shield.glb"
    }
  ],
  "Hellbreaker": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/genesis/shields/Equip_Left_Hand_Global_hellbreaker.glb"
    }
  ],
  "Street Sign Chest Guard": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common1/Equip_Chest_Global.glb"
    },
    {
      "node": "Chest_Global_Back",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common1/Equip_Chest_Global_Back.glb"
    }
  ],
  "Street Sign Arm Guards": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common1/Equip_Left_Arm_Global.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common1/Equip_Right_Arm_Global.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "remove:Left_Forearm"
    },
    {
      "node": "Right_Forearm",
      "file": "remove:Right_Forearm"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "Street Sign Leg Guards": [
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common1/Equip_Left_Leg_Global.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common1/Equip_Right_Leg_Global.glb"
    },
    {
      "node": "Left_Thigh",
      "file": "remove:Left_Thigh"
    },
    {
      "node": "Right_Thigh",
      "file": "remove:Right_Thigh"
    },
    {
      "node": "Left_Foot",
      "file": "remove:Left_Foot"
    },
    {
      "node": "Right_Foot",
      "file": "remove:Right_Foot"
    }
  ],
  "Street Sign": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common1/Equip_Right_Hand_Global.glb"
    }
  ],
  "Stop Sign": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common1/Equip_Left_Hand_Global.glb"
    }
  ],
  "Scrap Metal Chest Protector": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common2/Equip_Chest_Global.glb"
    },
    {
      "node": "Chest_Global_Back",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common2/Equip_Chest_Global_Back.glb"
    }
  ],
  "Scrap Metal Arm Protectors": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common2/Equip_Left_Arm_Global.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common2/Equip_Right_Arm_Global.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common2/Equip_Left_Forearm_Global.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common2/Equip_Right_Forearm_Global.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "Scrap Metal Leg Protectors": [
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common2/Equip_Left_Leg_Global.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common2/Equip_Right_Leg_Global.glb"
    },
    {
      "node": "Left_Thigh",
      "file": "remove:Left_Thigh"
    },
    {
      "node": "Right_Thigh",
      "file": "remove:Right_Thigh"
    },
    {
      "node": "Left_Foot",
      "file": "remove:Left_Foot"
    },
    {
      "node": "Right_Foot",
      "file": "remove:Right_Foot"
    }
  ],
  "Crowbar": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common2/Equip_Right_Hand_Global.glb"
    }
  ],
  "Trash Can Lid": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common2/Equip_Left_Hand_Global.glb"
    }
  ],
  "Rusted Steel Plated Chest": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common3/Equip_Chest_Global.glb"
    },
    {
      "node": "Chest_Global_Back",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common3/Equip_Chest_Global_Back.glb"
    }
  ],
  "Rusted Steel Plated Arms": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common3/Equip_Left_Arm_Global.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common3/Equip_Right_Arm_Global.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "remove:Left_Forearm"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common3/Equip_Right_Forearm_Global.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "Rusted Steel Plated Legs": [
    {
      "node": "Left_Leg",
      "file": "remove:Left_Leg"
    },
    {
      "node": "Right_Leg",
      "file": "remove:Right_Leg"
    },
    {
      "node": "Left_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common3/Equip_Left_Thigh_Global.glb"
    },
    {
      "node": "Right_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common3/Equip_Right_Thigh_Global.glb"
    },
    {
      "node": "Left_Foot",
      "file": "remove:Left_Foot"
    },
    {
      "node": "Right_Foot",
      "file": "remove:Right_Foot"
    }
  ],
  "Pipe": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common3/Equip_Right_Hand_Global.glb"
    }
  ],
  "Barrier Plate": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common3/Equip_Left_Hand_Global.glb"
    }
  ],
  "Rebel Chest Protector": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common4/Equip_Chest_Global.glb"
    },
    {
      "node": "Chest_Global_Back",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common4/Equip_Chest_Global_Back.glb"
    }
  ],
  "Rebel Arm Protectors": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common4/Equip_Left_Arm_Global.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common4/Equip_Right_Arm_Global.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common4/Equip_Right_Forearm_Global.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "remove:Right_Forearm"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "Rebel Leg Protectors": [
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common4/Equip_Left_Leg_Global.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common4/Equip_Right_Leg_Global.glb"
    },
    {
      "node": "Left_Thigh",
      "file": "remove:Left_Thigh"
    },
    {
      "node": "Right_Thigh",
      "file": "remove:Right_Thigh"
    },
    {
      "node": "Left_Foot",
      "file": "remove:Left_Foot"
    },
    {
      "node": "Right_Foot",
      "file": "remove:Right_Foot"
    }
  ],
  "Nail Bat": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common4/Equip_Right_Hand_Global.glb"
    }
  ],
  "Rebel Shield": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common4/Equip_Left_Hand_Global.glb"
    }
  ],
  "Banana Plated Chest": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common5/Equip_Chest_Global.glb"
    },
    {
      "node": "Chest_Global_Back",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common5/Equip_Chest_Global_Back.glb"
    }
  ],
  "Banana Plated Arms": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common5/Equip_Left_Arm_Global.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common5/Equip_Right_Arm_Global.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common5/Equip_Left_Forearm_Global.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common5/Equip_Right_Forearm_Global.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "Banana Plated Legs": [
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common5/Equip_Left_Leg_Global.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common5/Equip_Right_Leg_Global.glb"
    },
    {
      "node": "Left_Thigh",
      "file": "remove:Left_Thigh"
    },
    {
      "node": "Right_Thigh",
      "file": "remove:Right_Thigh"
    },
    {
      "node": "Left_Foot",
      "file": "remove:Left_Foot"
    },
    {
      "node": "Right_Foot",
      "file": "remove:Right_Foot"
    }
  ],
  "Small Axe": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common5/Equip_Right_Hand_Global.glb"
    }
  ],
  "Banana Shield": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/common5/Equip_Left_Hand_Global.glb"
    }
  ],
  "Adventurer's Splint Chest Guard": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare1/Equip_Chest_Global.glb"
    },
    {
      "node": "Chest_Global_Back",
      "file": "remove:Chest_Back"
    }
  ],
  "Adventurer's Splint Arms": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare1/Equip_Left_Arm_Global.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare1/Equip_Right_Arm_Global.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare1/Equip_Left_Forearm_Global.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare1/Equip_Right_Forearm_Global.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "Adventurer's Splint Legs": [
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare1/Equip_Left_Leg_Global.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare1/Equip_Right_Leg_Global.glb"
    },
    {
      "node": "Left_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare1/Equip_Right_Thigh_Global.glb"
    },
    {
      "node": "Right_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare1/Equip_Left_Thigh_Global.glb"
    },
    {
      "node": "Left_Foot",
      "file": "remove:Left_Foot"
    },
    {
      "node": "Right_Foot",
      "file": "remove:Right_Foot"
    }
  ],
  "Saber": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare1/Equip_Right_Hand_Global.glb"
    }
  ],
  "Wooden Adventurer's Shield": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare1/Equip_Left_Hand_Global.glb"
    }
  ],
  "KIPD Riot Chest Gear": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare2/Equip_Chest_Global.glb"
    },
    {
      "node": "Chest_Global_Back",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare2/Equip_Chest_Global_Back.glb"
    }
  ],
  "KIPD Riot Arm Gear": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare2/Equip_Left_Arm_Global.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare2/Equip_Right_Arm_Global.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare2/Equip_Left_Forearm_Global.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare2/Equip_Right_Forearm_Global.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "KIPD Riot Leg Gear": [
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare2/Equip_Left_Leg_Global.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare2/Equip_Right_Leg_Global.glb"
    },
    {
      "node": "Left_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare2/Equip_Left_Thigh_Global.glb"
    },
    {
      "node": "Right_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare2/Equip_Right_Thigh_Global.glb"
    },
    {
      "node": "Left_Foot",
      "file": "remove:Left_Foot"
    },
    {
      "node": "Right_Foot",
      "file": "remove:Right_Foot"
    }
  ],
  "Tactical Axe": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare2/Equip_Right_Hand_Global.glb"
    }
  ],
  "Tactical Shield": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare2/Equip_Left_Hand_Global.glb"
    }
  ],
  "Steel Forged Chestplate": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare3/Equip_Chest_Global.glb"
    },
    {
      "node": "Chest_Global_Back",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare3/Equip_Chest_Global_Back.glb"
    }
  ],
  "Steel Forged Arms": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare3/Equip_Left_Arm_Global.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare3/Equip_Right_Arm_Global.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare3/Equip_Left_Forearm_Global.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare3/Equip_Right_Forearm_Global.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "Steel Forged Legs": [
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare3/Equip_Left_Leg_Global.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare3/Equip_Right_Leg_Global.glb"
    },
    {
      "node": "Left_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare3/Equip_Left_Thigh_Global.glb"
    },
    {
      "node": "Right_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare3/Equip_Right_Thigh_Global.glb"
    },
    {
      "node": "Left_Foot",
      "file": "remove:Left_Foot"
    },
    {
      "node": "Right_Foot",
      "file": "remove:Right_Foot"
    }
  ],
  "Steel Mace": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare3/Equip_Right_Hand_Global.glb"
    }
  ],
  "Steel Shield": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare3/Equip_Left_Hand_Global.glb"
    }
  ],
  "Legion's Battlegear Chest": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare4/Equip_Chest_Global.glb"
    },
    {
      "node": "Chest_Global_Back",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare4/Equip_Chest_Global_Back.glb"
    }
  ],
  "Legion's Battlegear Arms": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare4/Equip_Left_Arm_Global.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare4/Equip_Right_Arm_Global.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare4/Equip_Left_Forearm_Global.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare4/Equip_Right_Forearm_Global.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "Legion's Battlegear Legs": [
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare4/Equip_Left_Leg_Global.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare4/Equip_Right_Leg_Global.glb"
    },
    {
      "node": "Left_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare4/Equip_Left_Thigh_Global.glb"
    },
    {
      "node": "Right_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare4/Equip_Right_Thigh_Global.glb"
    },
    {
      "node": "Left_Foot",
      "file": "remove:Left_Foot"
    },
    {
      "node": "Right_Foot",
      "file": "remove:Right_Foot"
    }
  ],
  "Scimitar": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare4/Equip_Right_Hand_Global.glb"
    }
  ],
  "Legion's Crest": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/rare4/Equip_Left_Hand_Global.glb"
    }
  ],
  "Enriched Darksteel Breastplate": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic1/Equip_Chest_Global.glb"
    },
    {
      "node": "Chest_Global_Back",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic1/Equip_Chest_Global_Back.glb"
    }
  ],
  "Enriched Darksteel Arms": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic1/Equip_Left_Arm_Global.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic1/Equip_Right_Arm_Global.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic1/Equip_Left_Forearm_Global.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic1/Equip_Right_Forearm_Global.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "Enriched Darksteel Legs": [
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic1/Equip_Left_Leg_Global.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic1/Equip_Right_Leg_Global.glb"
    },
    {
      "node": "Left_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic1/Equip_Left_Thigh_Global.glb"
    },
    {
      "node": "Right_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic1/Equip_Right_Thigh_Global.glb"
    },
    {
      "node": "Left_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic1/Equip_Left_Foot_Global.glb"
    },
    {
      "node": "Right_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic1/Equip_Right_Foot_Global.glb"
    }
  ],
  "Darksteel Saber": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic1/Equip_Right_Hand_Global.glb"
    }
  ],
  "Darksteel Guardian": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic1/Equip_Left_Hand_Global.glb"
    }
  ],
  "Reactor Chest Battleplate": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic2/Equip_Chest_Global.glb"
    },
    {
      "node": "Chest_Global_Back",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic2/Equip_Chest_Global_Back.glb"
    }
  ],
  "Reactor Arm Battleplate": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic2/Equip_Left_Arm_Global.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic2/Equip_Right_Arm_Global.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "remove:Left_Forearm"
    },
    {
      "node": "Right_Forearm",
      "file": "remove:Right_Forearm"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "Reactor Leg Battleplate": [
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic2/Equip_Left_Leg_Global.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic2/Equip_Right_Leg_Global.glb"
    },
    {
      "node": "Left_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic2/Equip_Left_Thigh_Global.glb"
    },
    {
      "node": "Right_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic2/Equip_Right_Thigh_Global.glb"
    },
    {
      "node": "Left_Foot",
      "file": "remove:Left_Foot"
    },
    {
      "node": "Right_Foot",
      "file": "remove:Right_Foot"
    }
  ],
  "Reactor Powered Battlehammer": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic2/Equip_Right_Hand_Global.glb"
    }
  ],
  "Reactor Powered Bulwark": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic2/Equip_Left_Hand_Global.glb"
    }
  ],
  "Azure Elemental Chest Guard": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic3/Equip_Chest_Global.glb"
    },
    {
      "node": "Chest_Global_Back",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic3/Equip_Chest_Global_Back.glb"
    }
  ],
  "Azure Elemental Arm Guards": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic3/Equip_Left_Arm_Global.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic3/Equip_Right_Arm_Global.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic3/Equip_Left_Forearm_Global.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic3/Equip_Right_Forearm_Global.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "Azure Elemental Leg Guards": [
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic3/Equip_Left_Leg_Global.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic3/Equip_Right_Leg_Global.glb"
    },
    {
      "node": "Left_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic3/Equip_Left_Thigh_Global.glb"
    },
    {
      "node": "Right_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic3/Equip_Right_Thigh_Global.glb"
    },
    {
      "node": "Left_Foot",
      "file": "remove:Left_Foot"
    },
    {
      "node": "Right_Foot",
      "file": "remove:Right_Foot"
    }
  ],
  "Azure Broadsword": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic3/Equip_Right_Hand_Global.glb"
    }
  ],
  "Azure Aegis": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/epic3/Equip_Left_Hand_Global.glb"
    }
  ],
  "Kongium Heritage Breastplate": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/legendary2/Equip_Chest_Global.glb"
    },
    {
      "node": "Chest_Global_Back",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/legendary2/Equip_Chest_Global_Back.glb"
    }
  ],
  "Kongium Heritage Arms": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/legendary2/Equip_Left_Arm_Global.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/legendary2/Equip_Right_Arm_Global.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "remove:Left_Forearm"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/legendary2/Equip_Right_Forearm_Global.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "Kongium Heritage Legs": [
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/legendary2/Equip_Left_Leg_Global.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/legendary2/Equip_Right_Leg_Global.glb"
    },
    {
      "node": "Left_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/legendary2/Equip_Left_Thigh_Global.glb"
    },
    {
      "node": "Right_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/legendary2/Equip_Right_Thigh_Global.glb"
    },
    {
      "node": "Left_Foot",
      "file": "remove:Left_Foot"
    },
    {
      "node": "Right_Foot",
      "file": "remove:Right_Foot"
    }
  ],
  "Kongium Decapitator": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/legendary2/Equip_Right_Hand_Global.glb"
    }
  ],
  "Immovable One": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/legendary2/Equip_Left_Hand_Global.glb"
    }
  ],
  "Promethean Buster Chestplate": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/legendary1/Equip_Chest_Global.glb"
    },
    {
      "node": "Chest_Global_Back",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/legendary1/Equip_Chest_Global_Back.glb"
    }
  ],
  "Promethean Buster Arms": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/legendary1/Equip_Left_Arm_Global.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/legendary1/Equip_Right_Arm_Global.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/legendary1/Equip_Left_Forearm_Global.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/legendary1/Equip_Right_Forearm_Global.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "Promethean Buster Legs": [
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/legendary1/Equip_Left_Leg_Global.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/legendary1/Equip_Right_Leg_Global.glb"
    },
    {
      "node": "Left_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/legendary1/Equip_Left_Thigh_Global.glb"
    },
    {
      "node": "Right_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/legendary1/Equip_Right_Thigh_Global.glb"
    },
    {
      "node": "Left_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/legendary1/Equip_Left_Foot_Global.glb"
    },
    {
      "node": "Right_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/legendary1/Equip_Right_Foot_Global.glb"
    }
  ],
  "Illuminator": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/legendary1/Equip_Right_Hand_Global.glb"
    }
  ],
  "Promethean Deflector": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/embattle/legendary1/Equip_Left_Hand_Global.glb"
    }
  ],
  "Kaboom Blastplate": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common1/Kaboom_Chest.glb"
    }
  ],
  "Kaboom Bracers": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common1/Kaboom_Left_Arm.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common1/Kaboom_Right_Arm.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common1/Kaboom_Left_Forearm.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common1/Kaboom_Right_Forearm.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "Kaboom Techboots": [
    {
      "node": "Left_Thigh",
      "file": "remove:Left_Thigh"
    },
    {
      "node": "Right_Thigh",
      "file": "remove:Right_Thigh"
    },
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common1/Kaboom_Left_Leg.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common1/Kaboom_Right_Leg.glb"
    },
    {
      "node": "Left_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common1/Kaboom_Left_Foot.glb"
    },
    {
      "node": "Right_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common1/Kaboom_Right_Foot.glb"
    }
  ],
  "Kaboom Disruptor": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common1/Kaboom_Mainhand.glb"
    }
  ],
  "Kaboom Liberty Shield": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common1/Kaboom_Offhand.glb"
    }
  ],
  "KEC Recon Coreplate": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common2/KEC_Recon_Chest.glb"
    }
  ],
  "KEC Recon Articulators": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common2/KEC_Recon_Left_Arm.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common2/KEC_Recon_Right_Arm.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common2/KEC_Recon_Left_Forearm.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common2/KEC_Recon_Right_Forearm.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "KEC Recon Terrain Treads": [
    {
      "node": "Left_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common2/KEC_Recon_Left_Thigh.glb"
    },
    {
      "node": "Right_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common2/KEC_Recon_Right_Thigh.glb"
    },
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common2/KEC_Recon_Left_Leg.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common2/KEC_Recon_Right_Leg.glb"
    },
    {
      "node": "Left_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common2/KEC_Recon_Left_Foot.glb"
    },
    {
      "node": "Right_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common2/KEC_Recon_Right_Foot.glb"
    }
  ],
  "KEC Recon Pulse Pistol": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common2/KEC_Recon_Mainhand.glb"
    }
  ],
  "KEC Recon GeoGuard": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common2/KEC_Recon_Offhand.glb"
    }
  ],
  "Arcane Operator Chestpiece": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common3/Arcane_Operator_Chest.glb"
    }
  ],
  "Arcane Operator Bracers": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common3/Arcane_Operator_Left_Arm.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common3/Arcane_Operator_Right_Arm.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common3/Arcane_Operator_Left_Forearm.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common3/Arcane_Operator_Right_Forearm.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "Arcane Operator Cuisses": [
    {
      "node": "Left_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common3/Arcane_Operator_Left_Thigh.glb"
    },
    {
      "node": "Right_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common3/Arcane_Operator_Right_Thigh.glb"
    },
    {
      "node": "Left_Leg",
      "file": "remove:Left_Leg"
    },
    {
      "node": "Right_Leg",
      "file": "remove:Right_Leg"
    },
    {
      "node": "Left_Foot",
      "file": "remove:Left_Foot"
    },
    {
      "node": "Right_Foot",
      "file": "remove:Right_Foot"
    }
  ],
  "Arcane Operator Blade": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common3/Arcane_Operator_Mainhand.glb"
    }
  ],
  "Arcane Operator Holo Shield": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common3/Arcane_Operator_Offhand.glb"
    }
  ],
  "Cryo Commander Chest Shell": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common4/Cryo_Commander_Chest.glb"
    }
  ],
  "Cryo Commander Arm Guards": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common4/Cryo_Commander_Left_Arm.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common4/Cryo_Commander_Right_Arm.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common4/Cryo_Commander_Left_Forearm.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common4/Cryo_Commander_Right_Forearm.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "Cryo Commander Leg Guards": [
    {
      "node": "Left_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common4/Cryo_Commander_Left_Thigh.glb"
    },
    {
      "node": "Right_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common4/Cryo_Commander_Right_Thigh.glb"
    },
    {
      "node": "Left_Leg",
      "file": "remove:Left_Leg"
    },
    {
      "node": "Right_Leg",
      "file": "remove:Right_Leg"
    },
    {
      "node": "Left_Foot",
      "file": "remove:Left_Foot"
    },
    {
      "node": "Right_Foot",
      "file": "remove:Right_Foot"
    }
  ],
  "Cryo Commander Blastech Pistol": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common4/Cryo_Commander_Mainhand.glb"
    }
  ],
  "Cryo Commander Core Shield": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common4/Cryo_Commander_Offhand.glb"
    }
  ],
  "Blasted Furnance Barricade Harness": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common5/Blasted_Furnance_Chest.glb"
    }
  ],
  "Blasted Furnance Arm Guards": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common5/Blasted_Furnance_Left_Arm.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common5/Blasted_Furnance_Right_Arm.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common5/Blasted_Furnance_Left_Forearm.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common5/Blasted_Furnance_Right_Forearm.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "Blasted Furnance Leg Guards": [
    {
      "node": "Left_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common5/Blasted_Furnance_Left_Thigh.glb"
    },
    {
      "node": "Right_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common5/Blasted_Furnance_Right_Thigh.glb"
    },
    {
      "node": "Left_Leg",
      "file": "remove:Left_Leg"
    },
    {
      "node": "Right_Leg",
      "file": "remove:Right_Leg"
    },
    {
      "node": "Left_Foot",
      "file": "remove:Left_Foot"
    },
    {
      "node": "Right_Foot",
      "file": "remove:Right_Foot"
    }
  ],
  "Blasted Furnance Blaster": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common5/Blasted_Furnance_Mainhand.glb"
    }
  ],
  "Blasted Furnance Rampart": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common5/Blasted_Furnance_Offhand.glb"
    }
  ],
  "Midnight Trooper Coreplate": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common6/Midnight_Trooper_Chest.glb"
    }
  ],
  "Midnight Trooper Stealth Bracers": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common6/Midnight_Trooper_Left_Arm.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common6/Midnight_Trooper_Right_Arm.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common6/Midnight_Trooper_Left_Forearm.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common6/Midnight_Trooper_Right_Forearm.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "Midnight Trooper Stealth Shoes": [
    {
      "node": "Left_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common6/Midnight_Trooper_Left_Thigh.glb"
    },
    {
      "node": "Right_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common6/Midnight_Trooper_Right_Thigh.glb"
    },
    {
      "node": "Left_Leg",
      "file": "remove:Left_Leg"
    },
    {
      "node": "Right_Leg",
      "file": "remove:Right_Leg"
    },
    {
      "node": "Left_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common6/Midnight_Trooper_Left_Foot.glb"
    },
    {
      "node": "Right_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common6/Midnight_Trooper_Right_Foot.glb"
    }
  ],
  "Midnight Trooper Suppressed Blaster": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common6/Midnight_Trooper_Mainhand.glb"
    }
  ],
  "Midnight Trooper Deflective Plate": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common6/Midnight_Trooper_Offhand.glb"
    }
  ],
  "Vortex Pulse Chestplate": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common7/Vortex_Pulse_Chest.glb"
    }
  ],
  "Vortex Pulse Arm Sleeves": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common7/Vortex_Pulse_Left_Arm.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common7/Vortex_Pulse_Right_Arm.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common7/Vortex_Pulse_Left_Forearm.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common7/Vortex_Pulse_Right_Forearm.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "Vortex Pulse Techboots": [
    {
      "node": "Left_Thigh",
      "file": "remove:Left_Thigh"
    },
    {
      "node": "Right_Thigh",
      "file": "remove:Right_Thigh"
    },
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common7/Vortex_Pulse_Left_Leg.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common7/Vortex_Pulse_Right_Leg.glb"
    },
    {
      "node": "Left_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common7/Vortex_Pulse_Left_Foot.glb"
    },
    {
      "node": "Right_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common7/Vortex_Pulse_Right_Foot.glb"
    }
  ],
  "Vortex Pulse Laser Blaster": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common7/Vortex_Pulse_Mainhand.glb"
    }
  ],
  "Vortex Pulse Flux Shield": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common7/Vortex_Pulse_Offhand.glb"
    }
  ],
  "Runic Raider Chestplate": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common8/Runic_Raider_Chest.glb"
    }
  ],
  "Runic Raider Arm Guard": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common8/Runic_Raider_Left_Arm.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common8/Runic_Raider_Right_Arm.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common8/Runic_Raider_Left_Forearm.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common8/Runic_Raider_Right_Forearm.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "Runic Raider Steel Treads": [
    {
      "node": "Left_Thigh",
      "file": "remove:Left_Thigh"
    },
    {
      "node": "Right_Thigh",
      "file": "remove:Right_Thigh"
    },
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common8/Runic_Raider_Left_Leg.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common8/Runic_Raider_Right_Leg.glb"
    },
    {
      "node": "Left_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common8/Runic_Raider_Left_Foot.glb"
    },
    {
      "node": "Right_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common8/Runic_Raider_Right_Foot.glb"
    }
  ],
  "Runic Raider Staxe": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common8/Runic_Raider_Mainhand.glb"
    }
  ],
  "Runic Raider Light Shield": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/common8/Runic_Raider_Offhand.glb"
    }
  ],
  "Torus Chestplate": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare1/Torus_Chest.glb"
    }
  ],
  "Torus Armored Sleeves": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare1/Torus_Left_Arm.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare1/Torus_Right_Arm.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare1/Torus_Left_Forearm.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare1/Torus_Right_Forearm.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare1/Torus_Left_Glove.glb"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare1/Torus_Right_Glove.glb"
    }
  ],
  "Torus Leg Guards": [
    {
      "node": "Left_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare1/Torus_Left_Thigh.glb"
    },
    {
      "node": "Right_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare1/Torus_Right_Thigh.glb"
    },
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare1/Torus_Left_Leg.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare1/Torus_Right_Leg.glb"
    },
    {
      "node": "Left_Foot",
      "file": "remove:Left_Foot"
    },
    {
      "node": "Right_Foot",
      "file": "remove:Right_Foot"
    }
  ],
  "Torus Devastator": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare1/Torus_Mainhand.glb"
    }
  ],
  "Torus Wand": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare1/Torus_Offhand.glb"
    }
  ],
  "Rugendo Elite Frost Cuirass": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare2/Rugendo_Elite_Chest.glb"
    }
  ],
  "Rugendo Elite Crystal Arm Guards": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare2/Rugendo_Elite_Left_Arm.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare2/Rugendo_Elite_Right_Arm.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare2/Rugendo_Elite_Left_Forearm.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare2/Rugendo_Elite_Right_Forearm.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare2/Rugendo_Elite_Left_Glove.glb"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare2/Rugendo_Elite_Right_Glove.glb"
    }
  ],
  "Rugendo Elite Shinplates": [
    {
      "node": "Left_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare2/Rugendo_Elite_Left_Thigh.glb"
    },
    {
      "node": "Right_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare2/Rugendo_Elite_Right_Thigh.glb"
    },
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare2/Rugendo_Elite_Left_Leg.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare2/Rugendo_Elite_Right_Leg.glb"
    },
    {
      "node": "Left_Foot",
      "file": "remove:Left_Foot"
    },
    {
      "node": "Right_Foot",
      "file": "remove:Right_Foot"
    }
  ],
  "Rugendo Elite Scout Cross-Blaster": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare2/Rugendo_Elite_Mainhand.glb"
    }
  ],
  "Rugendo Elite Scout Aegis": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare2/Rugendo_Elite_Offhand.glb"
    }
  ],
  "Nightforce Phantom Ghost Vest": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare3/Nightforce_Phantom_Chest.glb"
    }
  ],
  "Nightforce Phantom Stealth Bracers": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare3/Nightforce_Phantom_Left_Arm.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare3/Nightforce_Phantom_Right_Arm.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare3/Nightforce_Phantom_Left_Forearm.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare3/Nightforce_Phantom_Right_Forearm.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare3/Nightforce_Phantom_Left_Glove.glb"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare3/Nightforce_Phantom_Right_Glove.glb"
    }
  ],
  "Nightforce Phantom Ghostboots": [
    {
      "node": "Left_Thigh",
      "file": "remove:Left_Thigh"
    },
    {
      "node": "Right_Thigh",
      "file": "remove:Right_Thigh"
    },
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare3/Nightforce_Phantom_Left_Leg.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare3/Nightforce_Phantom_Right_Leg.glb"
    },
    {
      "node": "Left_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare3/Nightforce_Phantom_Left_Foot.glb"
    },
    {
      "node": "Right_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare3/Nightforce_Phantom_Right_Foot.glb"
    }
  ],
  "Nightforce Phantom Piercer": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare3/Nightforce_Phantom_Mainhand.glb"
    }
  ],
  "Nightforce Phantom Nova Shield": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare3/Nightforce_Phantom_Offhand.glb"
    }
  ],
  "Ajisai Enforcer Carbon Vest": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare4/Ajisai_Enforcer_Chest.glb"
    }
  ],
  "Ajisai Enforcer Carbon Sleeves": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare4/Ajisai_Enforcer_Left_Arm.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare4/Ajisai_Enforcer_Right_Arm.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare4/Ajisai_Enforcer_Left_Forearm.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare4/Ajisai_Enforcer_Right_Forearm.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "Ajisai Enforcer Z-Boots": [
    {
      "node": "Left_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare4/Ajisai_Enforcer_Left_Thigh.glb"
    },
    {
      "node": "Right_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare4/Ajisai_Enforcer_Right_Thigh.glb"
    },
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare4/Ajisai_Enforcer_Left_Leg.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare4/Ajisai_Enforcer_Right_Leg.glb"
    },
    {
      "node": "Left_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare4/Ajisai_Enforcer_Left_Foot.glb"
    },
    {
      "node": "Right_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare4/Ajisai_Enforcer_Right_Foot.glb"
    }
  ],
  "Ajisai Enforcer Neutron Rifle": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare4/Ajisai_Enforcer_Mainhand.glb"
    }
  ],
  "Ajisai Enforcer Particle Scrambler": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare4/Ajisai_Enforcer_Offhand.glb"
    }
  ],
  "Titanite Centaurian Chest Guard": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare5/Titanite_Centaurian_Chest.glb"
    }
  ],
  "Titanite Centaurian Exo-Arms": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare5/Titanite_Centaurian_Left_Arm.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare5/Titanite_Centaurian_Right_Arm.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare5/Titanite_Centaurian_Left_Forearm.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare5/Titanite_Centaurian_Right_Forearm.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "Titanite Centaurian Leg Guard": [
    {
      "node": "Left_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare5/Titanite_Centaurian_Left_Thigh.glb"
    },
    {
      "node": "Right_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare5/Titanite_Centaurian_Right_Thigh.glb"
    },
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare5/Titanite_Centaurian_Left_Leg.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare5/Titanite_Centaurian_Right_Leg.glb"
    },
    {
      "node": "Left_Foot",
      "file": "remove:Left_Foot"
    },
    {
      "node": "Right_Foot",
      "file": "remove:Right_Foot"
    }
  ],
  "Titanite Centaurian Railgun": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare5/Titanite_Centaurian_Mainhand.glb"
    }
  ],
  "Titanite Centaurian Ice Shield": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare5/Titanite_Centaurian_Offhand.glb"
    }
  ],
  "Warrior of Iyakoy Ancient Yoroi": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare6/Warrior_of_Iyakoy_Chest.glb"
    }
  ],
  "Warrior of Iyakoy Aituth Sleeves": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare6/Warrior_of_Iyakoy_Left_Arm.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare6/Warrior_of_Iyakoy_Right_Arm.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare6/Warrior_of_Iyakoy_Left_Forearm.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare6/Warrior_of_Iyakoy_Right_Forearm.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "Warrior of Iyakoy Leg Guard": [
    {
      "node": "Left_Thigh",
      "file": "remove:Left_Thigh"
    },
    {
      "node": "Right_Thigh",
      "file": "remove:Right_Thigh"
    },
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare6/Warrior_of_Iyakoy_Left_Leg.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare6/Warrior_of_Iyakoy_Right_Leg.glb"
    },
    {
      "node": "Left_Foot",
      "file": "remove:Left_Foot"
    },
    {
      "node": "Right_Foot",
      "file": "remove:Right_Foot"
    }
  ],
  "Warrior of Iyakoy Aituth Fang": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare6/Warrior_of_Iyakoy_Mainhand.glb"
    }
  ],
  "Warrior of Iyakoy Wand": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/rare6/Warrior_of_Iyakoy_Offhand.glb"
    }
  ],
  "Ryu Cyclone Chest Rig": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic1/Ryu_Cyclone_Chest.glb"
    }
  ],
  "Ryu Cyclone Arm Rig": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic1/Ryu_Cyclone_Left_Arm.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic1/Ryu_Cyclone_Right_Arm.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic1/Ryu_Cyclone_Left_Forearm.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic1/Ryu_Cyclone_Right_Forearm.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "Ryu Cyclone Combat Boots": [
    {
      "node": "Left_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic1/Ryu_Cyclone_Left_Thigh.glb"
    },
    {
      "node": "Right_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic1/Ryu_Cyclone_Right_Thigh.glb"
    },
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic1/Ryu_Cyclone_Left_Leg.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic1/Ryu_Cyclone_Right_Leg.glb"
    },
    {
      "node": "Left_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic1/Ryu_Cyclone_Left_Foot.glb"
    },
    {
      "node": "Right_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic1/Ryu_Cyclone_Right_Foot.glb"
    }
  ],
  "Ryu Cyclone Annihalator": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic1/Ryu_Cyclone_Mainhand.glb"
    }
  ],
  "Ryu Cyclone Blast Shield": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic1/Ryu_Cyclone_Offhand.glb"
    }
  ],
  "KIPD Trauma Unit Protector": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic2/KIPD_Trauma_Unit_Chest.glb"
    }
  ],
  "KIPD Trauma Unit Arm Guards": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic2/KIPD_Trauma_Unit_Left_Arm.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic2/KIPD_Trauma_Unit_Right_Arm.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic2/KIPD_Trauma_Unit_Left_Forearm.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic2/KIPD_Trauma_Unit_Right_Forearm.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "KIPD Trauma Unit Jumpers": [
    {
      "node": "Left_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic2/KIPD_Trauma_Unit_Left_Thigh.glb"
    },
    {
      "node": "Right_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic2/KIPD_Trauma_Unit_Right_Thigh.glb"
    },
    {
      "node": "Left_Leg",
      "file": "remove:Left_Leg"
    },
    {
      "node": "Right_Leg",
      "file": "remove:Right_Leg"
    },
    {
      "node": "Left_Foot",
      "file": "remove:Left_Foot"
    },
    {
      "node": "Right_Foot",
      "file": "remove:Right_Foot"
    }
  ],
  "KIPD Trauma Tempest Blade": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic2/KIPD_Trauma_Unit_Mainhand.glb"
    }
  ],
  "KIPD Trauma Protectorate": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic2/KIPD_Trauma_Unit_Offhand.glb"
    }
  ],
  "Crimson Inferno ExoWings": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic3/Crimson_Inferno_Chest.glb"
    }
  ],
  "Crimson Inferno ExoGauntlets": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic3/Crimson_Inferno_Left_Arm.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic3/Crimson_Inferno_Right_Arm.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic3/Crimson_Inferno_Left_Forearm.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic3/Crimson_Inferno_Right_Forearm.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "Crimson Inferno ExoBoots": [
    {
      "node": "Left_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic3/Crimson_Inferno_Left_Thigh.glb"
    },
    {
      "node": "Right_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic3/Crimson_Inferno_Right_Thigh.glb"
    },
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic3/Crimson_Inferno_Left_Leg.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic3/Crimson_Inferno_Right_Leg.glb"
    },
    {
      "node": "Left_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic3/Crimson_Inferno_Left_Foot.glb"
    },
    {
      "node": "Right_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic3/Crimson_Inferno_Right_Foot.glb"
    }
  ],
  "Crimson Inferno Prometheans Wrath": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic3/Crimson_Inferno_Mainhand.glb"
    }
  ],
  "Crimson Inferno Guardian Plate": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic3/Crimson_Inferno_Offhand.glb"
    }
  ],
  "Fallen Plutonium Chest Reactor": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic4/Fallen_Plutonium_Chest.glb"
    }
  ],
  "Fallen Plutonium Arm Guards": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic4/Fallen_Plutonium_Left_Arm.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic4/Fallen_Plutonium_Right_Arm.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic4/Fallen_Plutonium_Left_Forearm.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic4/Fallen_Plutonium_Right_Forearm.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "remove:Left_Hand_Skin"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "remove:Right_Hand_Skin"
    }
  ],
  "Fallen Plutonium ExoLegs": [
    {
      "node": "Left_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic4/Fallen_Plutonium_Left_Thigh.glb"
    },
    {
      "node": "Right_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic4/Fallen_Plutonium_Right_Thigh.glb"
    },
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic4/Fallen_Plutonium_Left_Leg.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic4/Fallen_Plutonium_Right_Leg.glb"
    },
    {
      "node": "Left_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic4/Fallen_Plutonium_Left_Foot.glb"
    },
    {
      "node": "Right_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic4/Fallen_Plutonium_Right_Foot.glb"
    }
  ],
  "Fallen Plutonium Nuclear Obliterator": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic4/Fallen_Plutonium_Mainhand.glb"
    }
  ],
  "Fallen Plutonium Fusion Shield": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/epic4/Fallen_Plutonium_Offhand.glb"
    }
  ],
  "Jolgue Guardian Armor": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary1/Jolgue_Guardian_Chest.glb"
    }
  ],
  "Jolgue Guardian Thruster Sleeves": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary1/Jolgue_Guardian_Left_Arm.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary1/Jolgue_Guardian_Right_Arm.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary1/Jolgue_Guardian_Left_Forearm.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary1/Jolgue_Guardian_Right_Forearm.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary1/Jolgue_Guardian_Left_Glove.glb"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary1/Jolgue_Guardian_Right_Glove.glb"
    }
  ],
  "Jolgue Guardian Thruster Boots": [
    {
      "node": "Left_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary1/Jolgue_Guardian_Left_Thigh.glb"
    },
    {
      "node": "Right_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary1/Jolgue_Guardian_Right_Thigh.glb"
    },
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary1/Jolgue_Guardian_Left_Leg.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary1/Jolgue_Guardian_Right_Leg.glb"
    },
    {
      "node": "Left_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary1/Jolgue_Guardian_Left_Foot.glb"
    },
    {
      "node": "Right_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary1/Jolgue_Guardian_Right_Foot.glb"
    }
  ],
  "Jolgue Guardian Weeping Killer": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary1/Jolgue_Guardian_Mainhand.glb"
    }
  ],
  "Jolgue Guardian Sacred Shield": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary1/Jolgue_Guardian_Offhand.glb"
    }
  ],
  "Sealkeeper of Ixon Armor": [
    {
      "node": "Chest_Global",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary2/Sealkeeper_of_Ixon_Chest.glb"
    }
  ],
  "Sealkeeper of Ixon Gauntlets": [
    {
      "node": "Left_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary2/Sealkeeper_of_Ixon_Left_Arm.glb"
    },
    {
      "node": "Right_Arm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary2/Sealkeeper_of_Ixon_Right_Arm.glb"
    },
    {
      "node": "Left_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary2/Sealkeeper_of_Ixon_Left_Forearm.glb"
    },
    {
      "node": "Right_Forearm",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary2/Sealkeeper_of_Ixon_Right_Forearm.glb"
    },
    {
      "node": "Left_Hand_Skin",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary2/Sealkeeper_of_Ixon_Left_Glove.glb"
    },
    {
      "node": "Right_Hand_Skin",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary2/Sealkeeper_of_Ixon_Right_Glove.glb"
    }
  ],
  "Sealkeeper of Ixon Surge Boots": [
    {
      "node": "Left_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary2/Sealkeeper_of_Ixon_Left_Thigh.glb"
    },
    {
      "node": "Right_Thigh",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary2/Sealkeeper_of_Ixon_Right_Thigh.glb"
    },
    {
      "node": "Left_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary2/Sealkeeper_of_Ixon_Left_Leg.glb"
    },
    {
      "node": "Right_Leg",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary2/Sealkeeper_of_Ixon_Right_Leg.glb"
    },
    {
      "node": "Left_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary2/Sealkeeper_of_Ixon_Left_Foot.glb"
    },
    {
      "node": "Right_Foot",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary2/Sealkeeper_of_Ixon_Right_Foot.glb"
    }
  ],
  "Sealkeeper of Ixon Harbinger": [
    {
      "node": "Right_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary2/Sealkeeper_of_Ixon_Mainhand.glb"
    }
  ],
  "Sealkeeper of Ixon Aether": [
    {
      "node": "Left_Hand",
      "file": "https://assets.cyberkongz.com/static/models/armory/ascension/legendary2/Sealkeeper_of_Ixon_Offhand.glb"
    }
  ]
}
