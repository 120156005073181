export const allowedPrivateAssetsExtensionTypes = [
  '.vox',
  '.gltf',
  '_preview.png',
  '_preview_back.png',
  '_preview_head.png',
  '_preview_transparent.png',
  '_preview_back_transparent.png',
  '_preview_head_transparent.png',
  '_for_3d_print.zip',
  '.fbx',
] as const;
export type PrivateAssetsExtensionType = typeof allowedPrivateAssetsExtensionTypes[number];

export const SIGNATURE_MESSAGE_PRIVATE_ASSETS = 'Verify ownership of Kong to download asset';

export const SIGNATURE_MESSAGE = 'Verify your address with signature to perform this operation';

export const MAX_GENKAI_REVEAL_AMOUNT = 10;

export const MAX_GENKAI_SUBMIT_AMOUNT = 5;
