import { RarityType } from '../types';

export type ArmorySetType = {
  id: number;
  name: string;
  bonusGearScore: number;
  rarity: RarityType;
};

const ARMORY_SETS: ArmorySetType[] = [
  {
    id: 1,
    name: 'Street Sign',
    bonusGearScore: 26,
    rarity: 'Common',
  },
  {
    id: 2,
    name: 'Scrap Metal',
    bonusGearScore: 28,
    rarity: 'Common',
  },
  {
    id: 3,
    name: 'Rusted Steel',
    bonusGearScore: 30,
    rarity: 'Common',
  },
  {
    id: 4,
    name: 'Rebel',
    bonusGearScore: 32,
    rarity: 'Common',
  },
  {
    id: 5,
    name: 'Banana',
    bonusGearScore: 34,
    rarity: 'Common',
  },
  {
    id: 6,
    name: 'Adventurer',
    bonusGearScore: 63,
    rarity: 'Rare',
  },
  {
    id: 7,
    name: 'KIPD',
    bonusGearScore: 68,
    rarity: 'Rare',
  },
  {
    id: 8,
    name: 'Steel Forged',
    bonusGearScore: 73,
    rarity: 'Rare',
  },
  {
    id: 9,
    name: 'Legion',
    bonusGearScore: 78,
    rarity: 'Rare',
  },
  {
    id: 10,
    name: 'Enriched Darksteel',
    bonusGearScore: 115,
    rarity: 'Epic',
  },
  {
    id: 11,
    name: 'Reactor',
    bonusGearScore: 120,
    rarity: 'Epic',
  },
  {
    id: 12,
    name: 'Azure Elemental',
    bonusGearScore: 125,
    rarity: 'Epic',
  },
  {
    id: 13,
    name: 'Kongium Heritage',
    bonusGearScore: 200,
    rarity: 'Legendary',
  },
  {
    id: 14,
    name: 'Promethean',
    bonusGearScore: 200,
    rarity: 'Legendary',
  },
  {
    id: 15,
    name: 'Blasted Furnance',
    bonusGearScore: 22,
    rarity: 'Common',
  },
  {
    id: 16,
    name: 'Cryo Commander',
    bonusGearScore: 24,
    rarity: 'Common',
  },
  {
    id: 17,
    name: 'Midnight Trooper',
    bonusGearScore: 26,
    rarity: 'Common',
  },
  {
    id: 18,
    name: 'Runic Raider',
    bonusGearScore: 28,
    rarity: 'Common',
  },
  {
    id: 19,
    name: 'Vortex Pulse',
    bonusGearScore: 30,
    rarity: 'Common',
  },
  {
    id: 20,
    name: 'Kaboom',
    bonusGearScore: 32,
    rarity: 'Common',
  },
  {
    id: 21,
    name: 'Arcane Operator',
    bonusGearScore: 34,
    rarity: 'Common',
  },
  {
    id: 22,
    name: 'KEC Recon',
    bonusGearScore: 36,
    rarity: 'Common',
  },
  {
    id: 23,
    name: 'Titanite Centaurian',
    bonusGearScore: 58,
    rarity: 'Rare',
  },
  {
    id: 24,
    name: 'Torus',
    bonusGearScore: 63,
    rarity: 'Rare',
  },
  {
    id: 25,
    name: 'Warrior of Iyakoy',
    bonusGearScore: 68,
    rarity: 'Rare',
  },
  {
    id: 26,
    name: 'Ajisai Enforcer',
    bonusGearScore: 73,
    rarity: 'Rare',
  },
  {
    id: 27,
    name: 'Rugendo Elite',
    bonusGearScore: 78,
    rarity: 'Rare',
  },
  {
    id: 28,
    name: 'Nightforce Phantom',
    bonusGearScore: 83,
    rarity: 'Rare',
  },
  {
    id: 29,
    name: 'KIPD Trauma Unit',
    bonusGearScore: 115,
    rarity: 'Epic',
  },
  {
    id: 30,
    name: 'Fallen Plutonium',
    bonusGearScore: 120,
    rarity: 'Epic',
  },
  {
    id: 31,
    name: 'Ryu Cyclone',
    bonusGearScore: 125,
    rarity: 'Epic',
  },
  {
    id: 32,
    name: 'Crimson Inferno',
    bonusGearScore: 130,
    rarity: 'Epic',
  },
  {
    id: 33,
    name: 'Jolgue Guardian',
    bonusGearScore: 200,
    rarity: 'Legendary',
  },
  {
    id: 34,
    name: 'Sealkeeper of Ixon',
    bonusGearScore: 200,
    rarity: 'Legendary',
  },
];

export const ARMORY_SETS_BY_ID = ARMORY_SETS.reduce((mapping, item) => {
  mapping[item.id] = item;

  return mapping;
}, {} as { [key: number]: ArmorySetType });
