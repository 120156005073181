import { defineTextStyles, SystemStyleObject } from '@chakra-ui/react';

import { pxToRem } from './utils';

export const paragraphTextSizes = ['p-xs', 'p-sm', 'p-md', 'p-lg', 'p-xl'] as const;

type PrimaryTextSize = typeof paragraphTextSizes[number];

export const paragraphSizeTextStyles: { [key in PrimaryTextSize]: { value: SystemStyleObject } } = {
  'p-xs': {
    value: {
      fontSize: 'xs',
      lineHeight: '1rem',
      letterSpacing: pxToRem(0.32),
    },
  },
  'p-sm': {
    value: {
      fontSize: { base: 'xs', sm: 'sm' },
      lineHeight: '1.25rem',
      letterSpacing: pxToRem(0.16),
    },
  },
  'p-md': {
    value: {
      fontSize: { base: 'sm', sm: 'md' },
      lineHeight: '1.5rem',
    },
  },
  'p-lg': {
    value: {
      fontSize: { base: 'md', sm: 'lg' },
      lineHeight: { base: '1.5rem', sm: '1.75rem' },
    },
  },
  'p-xl': {
    value: {
      fontSize: { base: 'md', sm: 'xl' },
      lineHeight: { base: '1.5rem', sm: '1.875rem' },
    },
  },
};

export const textStyles = defineTextStyles({
  ...paragraphSizeTextStyles,
});
