import { ChakraProvider } from '@chakra-ui/react';
import { BasicLayout } from '@components/BasicLayout';
import {
  ALL_SUPPORTED_CHAIN_TYPES,
  CookieBanner,
  DefaultSEOTags,
  globalAppStyles,
  LANDING_ROUTES,
  theme,
  Web3Provider,
} from '@cyberkongz/ui';
import { wagmiConfig } from '@cyberkongz/ui-blockchain';
import { Global } from '@emotion/react';
import { SITE_URL } from '@features/seo/SEO';
import { SharedUIProvider } from '@features/sharedUI';
import { UserSettingsProvider } from '@features/userSettings/UserSettingsProvider';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import React, { ReactElement, ReactNode } from 'react';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout || ((page) => <BasicLayout>{page}</BasicLayout>);

  return (
    <>
      <DefaultSEOTags siteUrl={SITE_URL} />
      <SessionProvider session={pageProps.session}>
        <ChakraProvider value={theme}>
          <Web3Provider
            config={wagmiConfig}
            supportedChainTypes={ALL_SUPPORTED_CHAIN_TYPES}
            tosLink={LANDING_ROUTES.TERMS('terms-and-conditions')}
          >
            <Global styles={globalAppStyles} />
            <SharedUIProvider>
              <UserSettingsProvider>
                {getLayout(<Component {...pageProps} />)}
                <CookieBanner privacyLink={LANDING_ROUTES.TERMS('privacy-policy')} />
              </UserSettingsProvider>
            </SharedUIProvider>
          </Web3Provider>
        </ChakraProvider>
      </SessionProvider>
    </>
  );
}

export default App;
