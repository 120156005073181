import { Box, Grid } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';

import { pxToRem, TRANSITIONS } from '../../theme';

const startingHeight = 2;
const endHeight = 24;
const scaleEnd = endHeight / startingHeight;
const VOLUME_BAR_COUNT = 4;
const translateYTo = ((1 - scaleEnd) * 100) / 2;

const animateVolumeBar = keyframes`
  0% {
    transform: translateY(0%) scaleY(1);
  }
  100% {
    transform: translateY(${translateYTo}%) scaleY(${scaleEnd});
  }
`;

const ANIMATION_DELAYS = Array.from(Array(VOLUME_BAR_COUNT).keys())
  .map((_, i) => `${i * 0.5}s`)
  .reverse();

function VolumeBar({ animation, color }: { animation?: string; color?: string }) {
  return (
    <Box w={pxToRem(2)} h={pxToRem(startingHeight)} bg={color || 'white'} animation={animation} />
  );
}

export function VolumeIcon({
  audioEnabled,
  toggleAudio,
  color,
}: {
  audioEnabled: boolean;
  toggleAudio: () => void;
  color?: string;
}) {
  return (
    <Grid
      position="relative"
      gridTemplateColumns={`repeat(${VOLUME_BAR_COUNT}, 1fr)`}
      gap={pxToRem(5)}
      h={pxToRem(endHeight)}
      alignItems="center"
      opacity={audioEnabled ? 1 : 0.4}
      transition={`opacity ${TRANSITIONS.standard}`}
      transform={`translateY(${audioEnabled ? '50' : '0'}%)`}
      _hover={{
        opacity: 1,
      }}
    >
      <Box
        onClick={toggleAudio}
        boxSize={10}
        cursor="pointer"
        position="absolute"
        zIndex={2}
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      />
      {ANIMATION_DELAYS.map((animationDelay) => (
        <VolumeBar
          key={animationDelay}
          animation={
            audioEnabled ? `${animateVolumeBar} 1s ${animationDelay} alternate infinite` : 'none'
          }
          color={color}
        />
      ))}
    </Grid>
  );
}
