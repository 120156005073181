import { Kong, KongGenkai, KongVX } from '@cyberkongz/database';

export const kollectionSortOptions = ['id_asc', 'id_desc'] as const;
export type KollectionSortType = typeof kollectionSortOptions[number];

export const allowedKollections = ['genesis', 'vx', 'baby', 'genkai'] as const;
export type KollectionType = typeof allowedKollections[number];

export const allowedGuardianKollections = [
  'vx_polygon',
  'vx_ronin',
  'genkai_eth',
  'genkai_ronin',
] as const;
export type GuardianKollectionType = typeof allowedGuardianKollections[number];

export const allowedBooleanTraits = ['legendary'] as const;
export type BooleanTraitType = typeof allowedBooleanTraits[number];

export type StringTraitsType = { traitType: string; values: string[] };

export type StringTraitType = {
  traitType: string;
  value: string;
};

export type APIGetKongResponse = {
  kong: Kong;
};

export type SingleLockRegistryType = { lock_registry: { locking_contract: string | null }[] };
export type SingleLockRegistryRoninType = {
  lock_registry_ronin: { locking_contract: string | null }[];
};

export type APIKongVX = KongVX & SingleLockRegistryType & SingleLockRegistryRoninType;

export type APIGetKongVXResponse = {
  kong: APIKongVX;
};

export type APIKongGenkai = KongGenkai & SingleLockRegistryType & SingleLockRegistryRoninType;

type KongGenkaiLinkableTraitType = { trait_id: string | null };

export type APIKongGenkaiEmaki = APIKongGenkai & {
  linked_trait_genkai: KongGenkaiLinkableTraitType[];
  equipped_trait_genkai: KongGenkaiLinkableTraitType[];
};

export type APIGetKongGenkaiResponse = {
  kong: APIKongGenkaiEmaki;
};

export type APIGetKongzResponse = { count: number; endCursor?: number } & (
  | {
      kollection: Extract<KollectionType, 'genesis' | 'baby'>;
      kongz: Kong[];
    }
  | {
      kollection: Extract<KollectionType, 'vx'>;
      kongz: KongVX[];
    }
  | {
      kollection: Extract<KollectionType, 'genkai'>;
      kongz: APIKongGenkai[];
    }
);

export type APIGetGetKongzProfileDumpResponse = {
  kongz: Kong[];
  kongzVX: APIKongVX[];
  kongzGenkai: (APIKongGenkai & { linked_trait_genkai?: KongGenkaiLinkableTraitType[] })[];
};

export type APIGuardianGetKongzResponse =
  | {
      kollection: Extract<GuardianKollectionType, 'vx_ronin' | 'vx_polygon'>;
      kongz: APIKongVX[];
    }
  | {
      kollection: Extract<GuardianKollectionType, 'genkai_eth' | 'genkai_ronin'>;
      kongz: (KongGenkai & SingleLockRegistryType)[];
    };

export type JungleAdventureAPIGetJackpotEligibleVX = {
  count: number;
};
