import { defineRecipe, SystemStyleObject } from '@chakra-ui/react';

import { pxToRem } from '../utils';

export const primaryHeadingSizes = ['3xs', '2xs', 'xs', 'sm', 'md', 'lg', 'xl'] as const;
type PrimaryHeadingSize = typeof primaryHeadingSizes[number];

const primaryHeadingSizeStyles: { [key in PrimaryHeadingSize]: SystemStyleObject } = {
  '3xs': {
    fontSize: { base: 'sm', sm: 'md' },
    lineHeight: { base: '1.5rem', sm: '1.75rem' },
    letterSpacing: pxToRem(0.48),
  },
  '2xs': {
    fontSize: { base: 'md', sm: 'lg' },
    lineHeight: { base: '1.5rem', sm: '1.75rem' },
    letterSpacing: pxToRem(0.48),
  },
  xs: {
    fontSize: { base: 'lg', sm: 'xl' },
    lineHeight: { base: '1.75rem', sm: '1.875rem' },
    letterSpacing: { base: pxToRem(0.48), sm: pxToRem(0.4) },
  },
  sm: {
    fontSize: { base: 'xl', sm: '2xl' },
    lineHeight: { base: '1.875rem', sm: '2.25rem' },
    letterSpacing: { base: pxToRem(0.4), sm: pxToRem(0.32) },
  },
  md: {
    fontSize: { base: 'xl', sm: '2.5xl' },
    lineHeight: { base: '1.875rem', sm: '2.625rem' },
    letterSpacing: { base: pxToRem(0.4), sm: 0 },
  },
  lg: {
    fontSize: { base: '2xl', sm: '3xl' },
    lineHeight: { base: '2.25rem', sm: '3rem' },
    letterSpacing: { base: pxToRem(0.32), sm: 0 },
  },
  xl: {
    fontSize: { base: '2xl', sm: '4xl' },
    lineHeight: { base: '2.25rem', sm: '3.75rem' },
    letterSpacing: { base: pxToRem(0.32), sm: 0 },
  },
};

export const displayHeadingSizes = ['md', 'lg'] as const;
const displayHeadingColors = ['text', 'primary', 'secondary', 'tertiary'] as const;
type DisplayHeadingSize = typeof displayHeadingSizes[number];
type DisplayHeadingColor = typeof displayHeadingColors[number];

const displayHeadingSizeStyles: { [key in DisplayHeadingSize]: SystemStyleObject } = {
  md: {
    fontSize: { base: '2xl', sm: '4xl' },
  },
  lg: {
    fontSize: { base: '5xl', sm: '8xl' },
  },
};

function getDisplayHeadingStyles({ color }: { color: DisplayHeadingColor }): SystemStyleObject {
  const textShadow = `0px 0px 25px var(--chakra-colors-${color})`;

  return {
    fontWeight: 'normal',
    fontFamily: 'hyperwaveOne',
    lineHeight: { base: '0.9em', sm: '0.9em' },
    color,
    textShadow,
    ...displayHeadingSizeStyles['lg'],
  };
}

export const headingRecipe = defineRecipe({
  base: {
    fontFamily: 'heading',
    lineHeight: 'moderate',
    color: 'text',
  },
  variants: {
    variant: {
      display: getDisplayHeadingStyles({ color: 'text' }),
      displayPrimary: getDisplayHeadingStyles({ color: 'primary' }),
      displaySecondary: getDisplayHeadingStyles({ color: 'secondary' }),
      displayTertiary: getDisplayHeadingStyles({ color: 'tertiary' }),
    },
    size: {
      ...primaryHeadingSizeStyles,
    },
  },
  compoundVariants: displayHeadingSizes.flatMap((size) =>
    (['display', 'displayPrimary', 'displaySecondary', 'displayTertiary'] as const).map(
      (variant) => ({
        size,
        variant,
        css: displayHeadingSizeStyles[size],
      })
    )
  ),
  defaultVariants: {
    size: 'xl',
  },
});
