import { defineSlotRecipe, defineStyle } from '@chakra-ui/react';

const triggerStyle = defineStyle({
  color: 'textSecondary',
  '& svg': {
    fontSize: '1.25em',
  },
});

export const numberInputRecipe = defineSlotRecipe({
  slots: ['incrementTrigger', 'decrementTrigger'],
  base: {
    incrementTrigger: triggerStyle,
    decrementTrigger: triggerStyle,
  },
});
