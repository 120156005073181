import { defineRecipe } from '@chakra-ui/react';

import { pxToRem, TRANSITIONS } from '../utils';

export const linkRecipe = defineRecipe({
  base: {
    outline: 'none !important',
    textDecorationColor: 'currentColor !important',
    textUnderlineOffset: '3px',
  },
  variants: {
    variant: {
      footer: {
        fontWeight: 'medium',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      menu: {
        fontFamily: 'heading',
        fontWeight: 'bold',
        fontSize: '2xl',
        textTransform: 'uppercase',
        color: 'neutral.100',
        transition: TRANSITIONS.standard,
        _hover: {
          textDecoration: 'none',
          color: 'primary',
          textShadow: '0px 0px 25px var(--chakra-colors-primary)',
        },
      },
      displayHover: {
        _hover: {
          textDecoration: 'underline',
          textDecorationThickness: pxToRem(2),
        },
      },
      noHover: {
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
  },
});
