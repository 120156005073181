import { defineSlotRecipe } from '@chakra-ui/react';

import { pxToRem } from '../utils';

export const dialogRecipe = defineSlotRecipe({
  slots: ['positioner', 'backdrop', 'content', 'header', 'body', 'closeTrigger'],
  base: {
    backdrop: {
      bg: 'modalOverlay',
    },
    content: {
      bg: 'backgroundSecondary',
      borderRadius: 'base',
      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
      m: 'auto',
      fontSize: 'inherit',
      lineHeight: 'inherit',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 2,
    },
    body: {
      color: 'textSecondary',
    },
    closeTrigger: {
      boxSize: 6,
      top: { base: 2, sm: 6 },
      right: { base: 2, sm: 6 },
      zIndex: 5,
      position: 'absolute',
      cursor: 'pointer',
    },
  },
  variants: {
    variant: {
      form: {
        header: {
          py: 6,
          px: { base: 4, sm: 6 },
        },
        content: {
          minW: { base: 'auto', sm: pxToRem(600) },
          borderRadius: 'lg',
        },
        body: {
          px: { base: 4, sm: 6 },
          pb: { base: 5, sm: 6 },
          pt: 0,
        },
      },
    },
  },
});
