import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogCloseTriggerProps,
  DialogContent,
  DialogHeader,
  DialogPositioner,
  DialogRoot,
  DialogRootProps,
  Portal,
  SystemStyleObject,
} from '@chakra-ui/react';
import { PropsWithChildren, ReactNode } from 'react';

import { CloseIcon } from './icons';

type ModalStyles = {
  styles?: {
    content?: SystemStyleObject;
    closeIcon?: SystemStyleObject;
    body?: SystemStyleObject;
    header?: SystemStyleObject;
  };
};

export type BaseModalProps = // onOpenChange from DialogRootProps has some details
  ModalStyles & { isOpen: boolean; onClose: () => void };

export type AppModalProps = DialogRootProps &
  BaseModalProps &
  PropsWithChildren &
  ModalStyles & {
    headerComponent?: ReactNode;
  };

export function ModalClose(props: DialogCloseTriggerProps) {
  return (
    <DialogCloseTrigger {...props}>
      <CloseIcon boxSize="inherit" />
    </DialogCloseTrigger>
  );
}

export function Modal({ children, headerComponent, styles, ...rest }: AppModalProps) {
  return (
    <Portal>
      <DialogRoot placement="center" open={rest.isOpen} onOpenChange={rest.onClose}>
        <DialogPositioner>
          <DialogBackdrop />
          <DialogContent {...styles?.content}>
            {headerComponent ? (
              <DialogHeader {...styles?.header}>
                {headerComponent}
                <ModalClose {...styles?.closeIcon} />
              </DialogHeader>
            ) : (
              <ModalClose {...styles?.closeIcon} />
            )}
            <DialogBody {...styles?.body}>{children}</DialogBody>
          </DialogContent>
        </DialogPositioner>
      </DialogRoot>
    </Portal>
  );
}
