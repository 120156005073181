import { Container, Heading } from '@chakra-ui/react';
import { Image } from '@components/AssetWrapper';
import { fullSizeParentStyles, LANDING_ROUTES, pxToRem } from '@cyberkongz/ui';
import { AnimatePresence, motion, MotionStyle, Variants } from 'framer-motion';
import { useEffect, useState } from 'react';

import { UnderArmourCursor } from './Icons';
import {
  FocusRectangles,
  ShowcasePartnerLogoStart,
  ShowcasePartnersWaiting4Interaction,
  ShowcaseThumbnail,
  START_TRANISTION_PARTNER,
  useBackgroundScale,
} from './Shared';

const BASE_ASSETS_URL = '/static/home/showcases/under_armour';

type ShowcaseStage = 'start' | 'waiting4Click' | 'videoPlaying';

const IMG_WIDTH = 2880;
const IMG_HEIGHT = 1580;

const VIDEO_ID = 'under_armour_video';

const videoVariants: Variants = {
  start: {
    filter: ['blur(0px)', 'blur(10px)', 'blur(10px)', 'blur(0px)'].map(
      (el) => `${el} grayscale(1)`
    ),
    scale: [1, 1, 1, 1.1],
    transition: START_TRANISTION_PARTNER,
  },
  videoPlaying: {
    filter: 'grayscale(0)',
    scale: 1.1,
    transition: { ease: [0, 0.42, 0.58, 1] },
  },
};

export function ShowcaseUnderArmour() {
  const [stage, setStage] = useState<ShowcaseStage>('start');
  const { scale } = useBackgroundScale({ imageWidth: IMG_WIDTH, imageHeight: IMG_HEIGHT });
  const isVideoPlaying = stage === 'videoPlaying';

  useEffect(() => {
    if (isVideoPlaying) {
      (document.getElementById(VIDEO_ID) as HTMLVideoElement).play();
    }
  }, [stage]);

  return (
    <>
      <motion.video
        id={VIDEO_ID}
        autoPlay={false}
        loop
        muted
        variants={videoVariants}
        animate={isVideoPlaying ? 'videoPlaying' : 'start'}
        style={{
          ...(fullSizeParentStyles as MotionStyle),
          objectFit: 'cover',
          zIndex: -1,
        }}
      >
        <source src={`${BASE_ASSETS_URL}/video.webm`} type="video/webm" />
        <source src={`${BASE_ASSETS_URL}/video.mp4`} type="video/mp4" />
      </motion.video>
      <Container>
        <AnimatePresence mode="wait">
          {stage === 'start' ? (
            <ShowcasePartnerLogoStart
              onAnimationComplete={() => setStage('waiting4Click')}
              partnerImage={
                <Image src={`${BASE_ASSETS_URL}/under_armour_logo.png`} alt="Under Armour logo" />
              }
            />
          ) : null}
          {stage === 'waiting4Click' ? (
            <ShowcasePartnersWaiting4Interaction
              description={
                <>
                  <Heading size="xs" textShadow="0px 0px 50px #FFFFFF" mb={4}>
                    spin the cap to showcase more merch
                  </Heading>
                  <UnderArmourCursor />
                </>
              }
              rectangles={
                <FocusRectangles
                  onClick={() => setStage('videoPlaying')}
                  width={pxToRem(scale.width * 600)}
                  height={pxToRem(scale.height * 800)}
                  stylesContainer={{
                    transform: 'translate(-50%, -50%)',
                    top: '50%',
                  }}
                />
              }
            />
          ) : null}
          {stage === 'videoPlaying' ? (
            <ShowcaseThumbnail
              src={`${BASE_ASSETS_URL}/thumbnail.png`}
              alt="Cap"
              label="Up Front collection x Under Armour"
              href={LANDING_ROUTES.MERCH_UNDER_ARMOUR}
            />
          ) : null}
        </AnimatePresence>
      </Container>
    </>
  );
}
