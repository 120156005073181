import { SystemStyleObject } from '@chakra-ui/react';

import { SHARED_ROUTES } from '../routes';
import { pxToRem } from './utils';

export const fullSizeParentStyles: SystemStyleObject = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
};

export const imageBackgroundCoverStyles: SystemStyleObject = {
  ...fullSizeParentStyles,
  objectFit: 'cover',
  zIndex: -1,
};

export const customScrollbarStyles: SystemStyleObject = {
  '&::-webkit-scrollbar': {
    w: pxToRem(6),
  },
  '&::-webkit-scrollbar-track': {
    bg: 'transparent',
    borderRadius: 'lg',
  },
  '&::-webkit-scrollbar-thumb': {
    bg: 'neutral.700',
    borderRadius: 'lg',
  },
};

export const fontStyles = `
  @font-face {
    font-family: "IBM Plex Sans";
    font-weight: 400;
    src: url("${SHARED_ROUTES.CDN}/static/fonts/IBM_Plex_Sans/IBM_Plex_Sans_Regular.ttf") format("truetype");
    font-display: swap;
  }

  @font-face {
    font-family: "IBM Plex Sans";
    font-weight: 500;
    src: url("${SHARED_ROUTES.CDN}/static/fonts/IBM_Plex_Sans/IBM_Plex_Sans_Medium.ttf") format("truetype");
    font-display: swap;
  }

  @font-face {
    font-family: "Hyperwave One";
    font-weight: 400;
    src: url("${SHARED_ROUTES.CDN}/static/fonts/Hyperwave_One/Hyperwave_One.ttf") format("truetype");
    font-display: swap;
  }

  @font-face {
    font-family: "Bios";
    font-weight: 400;
    src: url("${SHARED_ROUTES.CDN}/static/fonts/Bios/Bios_Regular.otf") format("opentype");
    font-display: swap;
  }

  @font-face {
    font-family: "Bios";
    font-weight: 700;
    src: url("${SHARED_ROUTES.CDN}/static/fonts/Bios/Bios_Bold.otf") format("opentype");
    font-display: swap;
  }
`;

export const globalAppStyles = `
  :root {
    --scroll-padding-top: ${pxToRem(90)}
  }

  html,
  body,
  body > div:first-of-type,
  div#__next {
    height: 100%;
    width: 100%;
    overflow-x: clip;
  }

  div#__next {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }

  ${fontStyles}
`;
