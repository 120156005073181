const getString256LongBinary = (string: string, str = '0') => {
  const missingChars = 256 - string.length;

  let s = '';
  for (let i = 0; i < missingChars; i += 1) {
    s += str;
  }

  return s + string;
};

export const stringToBinary = (string: string) => {
  const stringBin = BigInt(string).toString(2);

  const string256LongBin = getString256LongBinary(stringBin);

  return string256LongBin;
};

export function parseRoninAddress(address: string) {
  return address.replace('ronin:', '0x') as `0x${string}`;
}
