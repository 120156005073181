import { Box, Flex, Grid, Heading, SystemStyleObject } from '@chakra-ui/react';
import { ChainType } from '@cyberkongz/config-blockchain';
import {
  appChains,
  ConnectorName,
  CONNECTORS_BY_NETWORK,
  useChainEnhanced,
} from '@cyberkongz/ui-blockchain';
import { PropsWithChildren, ReactNode, useEffect, useMemo, useState } from 'react';
import { Connector, useConnect, useSwitchChain } from 'wagmi';

import { useWeb3Context } from '../../hooks';
import { pxToRem, TRANSITIONS } from '../../theme';
import { AppLink } from '../AppLink';
import { Card } from '../Card';
import { EthereumIconColor, PolygonIconColor, RoninIconColor } from '../icons';
import { BaseModalProps, Modal } from '../Modal';
import { TabsContainer, TabsItem } from '../Tabs';
import { CoinbaseWalletIcon, MetamaskIcon, WalletConnectIcon } from './Icons';

const ICONS_BY_NAME: { [key in ConnectorName]: ReactNode } = {
  MetaMask: <MetamaskIcon />,
  Injected: <EthereumIconColor />,
  WalletConnect: <WalletConnectIcon />,
  'Coinbase Wallet': <CoinbaseWalletIcon />,
  'Ronin Wallet': <RoninIconColor />,
  'Mock Connector': <EthereumIconColor />,
};

export const connectWalletModalStyles: BaseModalProps['styles'] = {
  content: {
    width: '100%',
    maxWidth: [pxToRem(360), pxToRem(780)],
    pt: [6, 8],
    pb: [4, 10],
    bg: 'rgba(22, 22, 22, 0.85)',
    border: 'thin',
    backdropFilter: 'blur(16px)',
  },
  body: {
    px: [4, 10],
  },
};

export function ConnectorCard({
  children,
  onClick,
  css,
}: PropsWithChildren & { onClick: () => void; css?: SystemStyleObject }) {
  return (
    <Card
      variant="transparentSecondary"
      onClick={onClick}
      css={{
        width: ['100%', '25%'],
        display: 'flex',
        gap: 4,
        flexDirection: ['row', 'column'],
        justifyContent: 'space-between',
        py: [2, 8],
        px: [3, 4],
        cursor: 'pointer',
        color: 'text',
        textAlign: 'center',
        border: 'thin',
        borderColor: 'transparent',
        transition: TRANSITIONS.standard,
        alignItems: 'center',
        '&:hover': {
          borderColor: 'text',
        },
        ...css,
      }}
    >
      {children}
    </Card>
  );
}

function EthereumNetworkHeader() {
  return (
    <Flex justifyContent="center" align="center" gap={4}>
      <EthereumIconColor />
      Ethereum
    </Flex>
  );
}

function PolygonNetworkHeader() {
  return (
    <Flex justifyContent="center" align="center" gap={4}>
      <PolygonIconColor />
      Polygon
    </Flex>
  );
}

function RoninNetworkHeader() {
  return (
    <Flex justifyContent="center" align="center" gap={4}>
      <RoninIconColor />
      Ronin
    </Flex>
  );
}

const TABS: { label: ReactNode; id: ChainType }[] = [
  {
    label: <EthereumNetworkHeader />,
    id: 'mainnet',
  },
  {
    label: <PolygonNetworkHeader />,
    id: 'polygon',
  },
  {
    label: <RoninNetworkHeader />,
    id: 'ronin',
  },
];

export function WalletList({
  onClose,
  mode = 'connect',
  expectedChain,
}: {
  onClose: () => void;
  mode?: 'switch' | 'connect';
  expectedChain?: ChainType;
}) {
  const [activeTab, setActiveTab] = useState<ChainType | undefined>(expectedChain);
  const { connectors, connectAsync } = useConnect();
  const { supportedChainTypes } = useWeb3Context();
  const { switchChainAsync } = useSwitchChain();
  const { chain } = useChainEnhanced();

  const availableConnectors = useMemo(() => {
    if (!activeTab) {
      return [];
    }

    // sometimes connectors array has different orderd depending on browser so this reduce overcomes that to guarantee order of displaying connectors in UI
    return CONNECTORS_BY_NETWORK[activeTab].reduce((_availableConnectors, connectorName) => {
      const connector = connectors.find(({ name }) => name === connectorName);

      if (connector) {
        _availableConnectors.push(connector);
      }

      return _availableConnectors;
    }, [] as Connector[]);
  }, [activeTab]);

  const availableTabs = useMemo(() => {
    const supportedTabs = TABS.filter(({ id }) => supportedChainTypes.includes(id));

    if (mode === 'connect') {
      return supportedTabs;
    }

    return supportedTabs.filter(({ id }) => id !== chain?.type);
  }, [chain, mode]);

  useEffect(() => {
    if (mode === 'switch') {
      setActiveTab(availableTabs[0].id);
    }
  }, [availableTabs]);

  return (
    <>
      <TabsContainer>
        {availableTabs.map(({ id, label }) => (
          <TabsItem
            key={id}
            activeTab={activeTab}
            setActiveTab={() => setActiveTab(id)}
            tab={id}
            noShadow
            css={{
              textAlign: 'center',
              width: `${100 / availableTabs.length}%`,
            }}
          >
            {label}
          </TabsItem>
        ))}
      </TabsContainer>
      <Flex gap={[3, 5]} justifyContent="center" flexWrap={['wrap', 'nowrap']}>
        {availableConnectors.map((connector) => (
          <ConnectorCard
            key={connector.id}
            onClick={async () => {
              const testingEnv = process.env.NEXT_PUBLIC_DEFAULT_CHAIN_ID === '11155111';
              const chainId = appChains.filter((chain) =>
                testingEnv
                  ? chain.type === activeTab && chain.testnet === true
                  : chain.type === activeTab
              )[0].id;

              if (mode === 'switch' && activeTab !== 'ronin' && chain?.type !== 'ronin') {
                await switchChainAsync?.({ chainId });
              } else {
                // TO DO BETTER OPTION
                // dont use default chainID on connecting
                await connectAsync({
                  connector,
                  chainId:
                    mode === 'connect' ? (connector.id === 'mock' ? chainId : undefined) : chainId,
                });
              }
              onClose();
            }}
          >
            <Flex
              h={[8, 16]}
              alignItems="center"
              justifyContent="center"
              css={{ '& svg': { height: 'inherit', width: 'auto' } }}
            >
              {ICONS_BY_NAME[connector.name as any as ConnectorName] || ''}
            </Flex>
            <Box>{connector.name}</Box>
          </ConnectorCard>
        ))}
      </Flex>
    </>
  );
}

export function ConnectWalletModal({
  isOpen,
  onClose,
  tosLink,
  expectedChain,
}: BaseModalProps & { tosLink: string; expectedChain: ChainType }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} styles={connectWalletModalStyles}>
      <Grid gap={[6, 10]}>
        <Heading textAlign="center" size="sm">
          connect YOUR WALLET
        </Heading>
        <WalletList onClose={onClose} expectedChain={expectedChain} />
        <Box color="textTertiary" textAlign="center">
          By connecting your wallet you agree to our{' '}
          <AppLink
            href={tosLink}
            target="_blank"
            textDecoration="underline"
            color="text"
            whiteSpace="nowrap"
          >
            Terms of Service
          </AppLink>
        </Box>
      </Grid>
    </Modal>
  );
}
