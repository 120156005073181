import { ChainType } from '@cyberkongz/config-blockchain';
import { Chain, createClient, http } from 'viem';
import { createConfig, fallback, unstable_connector } from 'wagmi';
import { mainnet, polygon, polygonMumbai, ronin, saigon, sepolia } from 'wagmi/chains';
import { injected } from 'wagmi/connectors';

import { connectors } from './connectors';

type ConfigChainIds = { mainnetChainId: number; polygonChainId: number; roninChainId: number };

export type ChainEnhanced = Chain &
  ConfigChainIds & {
    type: ChainType;
    switchToChainId: number;
  };

const prodChainIds: ConfigChainIds = {
  mainnetChainId: mainnet.id,
  polygonChainId: polygon.id,
  roninChainId: ronin.id,
};

const testnetChainIds: ConfigChainIds = {
  mainnetChainId: sepolia.id,
  polygonChainId: polygonMumbai.id,
  roninChainId: saigon.id,
};

const mainnetChains: ChainEnhanced[] = [
  {
    ...mainnet,
    ...prodChainIds,
    type: 'mainnet',
    switchToChainId: polygon.id,
  },
  {
    ...sepolia,
    ...testnetChainIds,
    type: 'mainnet',
    switchToChainId: polygonMumbai.id,
  },
];

const polygonChains: ChainEnhanced[] = [
  {
    ...polygon,
    ...prodChainIds,
    type: 'polygon',
    switchToChainId: mainnet.id,
  },
  {
    ...polygonMumbai,
    ...testnetChainIds,
    type: 'polygon',
    switchToChainId: sepolia.id,
  },
];

const roninChains: ChainEnhanced[] = [
  {
    ...ronin,
    ...prodChainIds,
    type: 'ronin',
    switchToChainId: polygonMumbai.id,
  },
  {
    ...saigon,
    blockExplorers: {
      default: {
        ...saigon.blockExplorers.default,
        url: 'https://saigon-app.roninchain.com',
      },
    },
    ...testnetChainIds,
    type: 'ronin',
    switchToChainId: polygonMumbai.id,
  },
];

export const appChains = [...mainnetChains, ...polygonChains, ...roninChains];

type EthereumProvider = { request(...args: any): Promise<any> };
type WindowWithEthereum = { ethereum?: EthereumProvider; ronin?: { provider: EthereumProvider } };

export const wagmiConfig = createConfig({
  // TO DO REMOVE it, some readonly typing
  // @ts-ignore
  chains: appChains,
  connectors,
  ssr: true,
  client({ chain }) {
    // if (typeof window !== 'undefined') {
    //   const chainData = appChains.find((appChain) => {
    //     return appChain.id === chain.id;
    //   });

    //   if (chainData && chainData.rpcUrls.default) {
    //     const isRoninChain = chainData.type === 'ronin';
    //     const windowTyped = window as unknown as WindowWithEthereum;
    //     let customWindowProvider;

    //     if (isRoninChain && windowTyped.ronin?.provider) {
    //       customWindowProvider = windowTyped.ronin.provider;
    //     } else if (windowTyped.ethereum) {
    //       customWindowProvider = windowTyped.ethereum;
    //     }

    //     if (customWindowProvider) {
    //       return createWalletClient({
    //         chain: chainData,
    //         transport: custom(customWindowProvider),
    //         key: isRoninChain ? 'Ronin Window Provider' : 'Window Provider',
    //         name: isRoninChain ? 'Ronin Window Provider' : 'Window Provider',
    //       }).extend(publicActions);
    //     }
    //   }
    // }

    return createClient({
      chain,
      transport:
        chain.id === 1
          ? fallback([
              unstable_connector(injected),
              http('https://eth.llamarpc.com'),
              http('https://ethereum-rpc.publicnode.com'),
              http(),
            ])
          : http(),
    });
  },
});
