import { Image as ChakraImage, ImageProps, Skeleton } from '@chakra-ui/react';
import { forwardRef, useEffect, useRef, useState } from 'react';

// wrapper for Image, will use later on for optimization, like lazy loading .etc
export const Image = forwardRef<HTMLImageElement, ImageProps>(function Image(props, ref) {
  // Workaround because Image from Chakra puts src before loading attribute and it doesn't work in Firefox
  return <ChakraImage ref={ref} {...props} />;
});

export function ImageWithMobile(props: ImageProps & { alt: string; srcSet: string }) {
  const desktopSrc = props.srcSet.replace('_mobile', '');

  return (
    <picture>
      <source srcSet={desktopSrc} media="(min-width: 48em)" />
      {/* eslint-disable-next-line */}
      <Image {...props} />
    </picture>
  );
}

export function ImageWithSkeleton(props: ImageProps) {
  const imageRef = useRef<HTMLImageElement>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!loading && imageRef.current?.complete) {
      setLoading(false);
    }
  }, []);

  return (
    <Skeleton loading={loading} bg="gray.400" animationDuration="2.4s">
      {/* eslint-disable-next-line */}
      <Image
        ref={imageRef}
        onLoad={() => {
          setLoading(false);
        }}
        {...props}
      />
    </Skeleton>
  );
}
