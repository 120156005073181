/* eslint-disable simple-import-sort/imports */
import merge from 'lodash/merge';

// L1 (Mainnet / Sepolia)
import { KongzABI } from './abi-wagmi/Kongz';
import { KongzVXABI } from './abi-wagmi/KongzVX';
import { BananaABI } from './abi-wagmi/Banana';
import { YieldHubABI } from './abi-wagmi/YieldHub';
import { LuckyCharmABI } from './abi-wagmi/LuckyCharm';
import { GoldenTicketABI } from './abi-wagmi/GoldenTicket';
import { SubdomainFactoryABI } from './abi-wagmi/SubdomainFactory';
import { SubdomainHelperABI } from './abi-wagmi/SubdomainHelper';
import { EnsRegistrarABI } from './abi-wagmi/EnsRegistrar';
import { EnsReverseRegistrarABI } from './abi-wagmi/EnsReverseRegistrar';
import { PublicResolverABI } from './abi-wagmi/PublicResolver';
import { KongzGenkaiABI } from './abi-wagmi/KongzGenkai';

// L2 (Polygon / Mumbai)
import { KongzVXPolygonABI } from './abi-wagmi/KongzVXPolygon';
import { BananaPolygonABI } from './abi-wagmi/BananaPolygon';
import { LuckyCharmPolygonABI } from './abi-wagmi/LuckyCharmPolygon';
import { VRFCoordinatorABI } from './abi-wagmi/VRFCoordinator';
import { GoldenTicketPolygonABI } from './abi-wagmi/GoldenTicketPolygon';
import { WrappedEtherPolygonABI } from './abi-wagmi/WrappedEtherPolygon';

// L2 (Ronin/Saigon)
import { BananaRoninABI } from './abi-wagmi/BananaRonin';
import { BananaSliceRoninABI } from './abi-wagmi/BananaSliceRonin';
import { KongzVXRoninABI } from './abi-wagmi/KongzVXRonin';
import { ConsumablesRoninABI } from './abi-wagmi/ConsumablesRonin';
import { CharmzRoninABI } from './abi-wagmi/CharmzRonin';
import { GoldenTicketRoninABI } from './abi-wagmi/GoldenTicketRonin';
import { Armory1155RoninABI } from './abi-wagmi/Armory1155Ronin';
import { Armory721RoninABI } from './abi-wagmi/Armory721Ronin';
import { EquipmentRoninABI } from './abi-wagmi/EquipmentRonin';
import { JungleAdventureRoninABI } from './abi-wagmi/JungleAdventureRonin';
import { MysteryBoxReedemerRoninABI } from './abi-wagmi/MysteryBoxReedemerRonin';
import { RedeemerRoninABI } from './abi-wagmi/RedeemerRonin';
import { JungleAdventureLotteryRoninABI } from './abi-wagmi/JungleAdventureLotteryRonin';
import { JungleShopRoninABI } from './abi-wagmi/JungleShopRonin';
import { TraitRegistryRoninABI } from './abi-wagmi/TraitRegistryRonin';
import { VRFCoordinatorRoninABI } from './abi-wagmi/VRFCoordinatorRonin';

import { ZERO_ADDRESS } from './consts';
import { EmakiEquippableABI } from './abi-wagmi/EmakiEquippable';
import { EmakiConsumableABI } from './abi-wagmi/EmakiConsumable';

// TO DO way of casting than `0x${string}`
// TO DO INVESTIGATE IF MAYBE JUST USE TYPECHAIN???
export const CONTRACTS_L1_MAINNET = {
  Kongz: {
    address: '0x57a204AA1042f6E66DD7730813f4024114d74f37' as `0x${string}`,
    abi: KongzABI,
  },
  KongzVX: {
    address: '0x7EA3Cca10668B8346aeC0bf1844A49e995527c8B' as `0x${string}`,
    abi: KongzVXABI,
  },
  Banana: {
    address: '0x94e496474f1725f1c1824cb5bdb92d7691a4f03a' as `0x${string}`,
    abi: BananaABI,
  },
  YieldHub: {
    address: '0x86cc33dbe3d2fb95bc6734e1e5920d287695215f' as `0x${string}`,
    abi: YieldHubABI,
  },
  Charmz: {
    address: '0xDD6E7fF87CE5e19F7856280A24f1eEC612947248' as `0x${string}`,
    abi: LuckyCharmABI,
  },
  GoldenTicket: {
    address: '0x3848CA9f99068455c0A4CDA0cb71DCF94df56cC2' as `0x${string}`,
    abi: GoldenTicketABI,
  },
  SubdomainFactory: {
    address: '0x390b7A059000414CD5305527e4E14Dcde1e88b0B' as `0x${string}`,
    abi: SubdomainFactoryABI,
  },
  SubdomainHelper: {
    address: '0xc0f853A783c0aa1E47aA835a954580A13063B5B8' as `0x${string}`,
    abi: SubdomainHelperABI,
  },
  EnsRegistrar: {
    address: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e' as `0x${string}`,
    abi: EnsRegistrarABI,
  },
  EnsReverseRegistrar: {
    address: '0xa58e81fe9b61b5c3fe2afd33cf304c454abfc7cb' as `0x${string}`,
    abi: EnsReverseRegistrarABI,
  },
  PublicResolver: {
    address: '0x4976fb03C32e5B8cfe2b6cCB31c09Ba78EBaBa41' as `0x${string}`,
    abi: PublicResolverABI,
  },
  // PROXY address!
  KongzGenkai: {
    address: '0x1F7c16FCe4fC894143aFB5545Bf04f676bf7DCf3' as `0x${string}`,
    abi: KongzGenkaiABI,
  },
};

const CONTRACTS_L1_SEPOLIA = merge({}, CONTRACTS_L1_MAINNET, {
  Kongz: {
    address: '0xc3a63EfC109c649D2424717ED188c2a8ab768331',
  },
  KongzVX: {
    address: '0xF9e04202F70434d8b415a25BC3072E1B21D5d043',
  },
  Banana: {
    address: '0xA024B28a0a3338650f537e5182f825D7EB0a5eC8',
  },
  YieldHub: {
    address: '0x36607e415024866330917cca2fe7B7a124Fee697',
  },
  Charmz: {
    address: ZERO_ADDRESS,
  },
  GoldenTicket: {
    address: ZERO_ADDRESS,
  },
  SubdomainFactory: {
    address: ZERO_ADDRESS,
  },
  SubdomainHelper: {
    address: ZERO_ADDRESS,
  },
  EnsRegistrar: {
    address: ZERO_ADDRESS,
  },
  EnsReverseRegistrar: {
    address: ZERO_ADDRESS,
  },
  PublicResolver: {
    address: ZERO_ADDRESS,
  },
  KongzGenkai: {
    address: '0xF57A2A29Ce0f4B7Ab6cB82f6f1f372959b8f93ec',
  },
} as { [key in keyof typeof CONTRACTS_L1_MAINNET]: { address: `0x${string}` } });

export const CONTRACTS_L2_POLYGON = {
  KongzVXPolygon: {
    address: '0x05df72d911e52ab122f7d9955728bc96a718782c' as `0x${string}`,
    abi: KongzVXPolygonABI,
  },
  BananaPolygon: {
    address: '0xbc91347e80886453f3f8bbd6d7ac07c122d87735' as `0x${string}`,
    abi: BananaPolygonABI,
  },
  CharmzPolygon: {
    address: '0x7cBCCC4a1576d7A05eB6f6286206596BCBee14aC' as `0x${string}`,
    abi: LuckyCharmPolygonABI,
  },
  UniqlyCollectionPolygon: {
    address: '0xa7b974d3c6a9e46bae10c2dd599966be126cbdbe' as `0x${string}`,
  },
  KongiumShop: {
    address: '0xB87dE69e438fB77Bfe6b1329565cBa88f5466A8C' as `0x${string}`,
  },
  KongiumShopMerkle: {
    address: '0xa54B8bCa39961b4be28304ddD426c414f6E45A0C' as `0x${string}`,
  },
  VRFCoordinator: {
    address: '0xAE975071Be8F8eE67addBC1A82488F1C24858067' as `0x${string}`,
    abi: VRFCoordinatorABI,
  },
  GoldenTicketPolygon: {
    address: '0x268166C237F6d7A061157891A3E2183C943E5F87' as `0x${string}`,
    abi: GoldenTicketPolygonABI,
  },
  WrappedEtherPolygon: {
    address: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619' as `0x${string}`,
    abi: WrappedEtherPolygonABI,
  },
};

const CONTRACTS_L2_POLYGON_MUMBAI = merge({}, CONTRACTS_L2_POLYGON, {
  KongzVXPolygon: {
    address: '0xB93dfc950801221Eaea6056AffD56B80173A3ecf',
  },
  BananaPolygon: {
    address: '0x6f825FFF1B792b8c3fbd12E9D16c46eE8740ac3E',
  },
  CharmzPolygon: {
    address: '0x647Fc6E9268A505cF3824D3Eac344167Cb10e0e5',
  },
  UniqlyCollectionPolygon: {
    address: '0x9BAd3DE6b3B5fBB8bF9e5f742EE0fF3cA03289e7',
  },
  KongiumShop: {
    address: '0xF57b8886Dfb9D953ea5fD6ae5F730C58463DC561',
  },
  KongiumShopMerkle: {
    address: '0x7e21936f1f551e3023d35131B95d32E9161e87b1',
  },
  VRFCoordinator: {
    address: '0x7a1BaC17Ccc5b313516C5E16fb24f7659aA5ebed',
  },
  GoldenTicketPolygon: {
    address: '0x914908661CB28DB8e8B671aC78363dDD073a1C3c',
  },
  WrappedEtherPolygon: {
    address: '0xA6FA4fB5f76172d178d61B04b0ecd319C5d1C0aa',
  },
} as { [key in keyof typeof CONTRACTS_L2_POLYGON]: { address: `0x${string}` } });

export const CONTRACTS_L2_RONIN = {
  // proxy
  KongzGenkaiRonin: {
    address: '0x1F7c16FCe4fC894143aFB5545Bf04f676bf7DCf3' as `0x${string}`,
    abi: KongzGenkaiABI,
  },
  BananaRonin: {
    address: '0x1a89ecd466a23e98f07111b0510a2d6c1cd5e400' as `0x${string}`,
    abi: BananaRoninABI,
  },
  BananaSliceRonin: {
    address: '0x54eFB1CC4F0331405A39d42964c3C885396919E5' as `0x${string}`,
    abi: BananaSliceRoninABI,
  },
  KongzVXRonin: {
    address: '0x241a81fc0d6692707dad2b5025a3a7cf2cf25acf' as `0x${string}`,
    abi: KongzVXRoninABI,
  },
  ConsumablesRonin: {
    address: '0x9467ed740f52437ba7eaf65e29354ef0f7a8855d' as `0x${string}`,
    abi: ConsumablesRoninABI,
  },
  CharmzRonin: {
    address: ZERO_ADDRESS as `0x${string}`,
    abi: CharmzRoninABI,
  },
  GoldenTicketRonin: {
    address: ZERO_ADDRESS as `0x${string}`,
    abi: GoldenTicketRoninABI,
  },
  Armory1155Ronin: {
    address: '0x02286d77425ae3287335ff28b264109225ed6991' as `0x${string}`,
    abi: Armory1155RoninABI,
  },
  Armory721Ronin: {
    address: ZERO_ADDRESS as `0x${string}`,
    abi: Armory721RoninABI,
  },
  EquipmentRonin: {
    address: '0x7Bc7E63e55E5A174EFc70296a37629dDC72311Dd' as `0x${string}`,
    abi: EquipmentRoninABI,
  },
  JungleAdventureRonin: {
    address: '0x0a9E7795aB1D691986B8f944F99560A7eD9E0708' as `0x${string}`,
    abi: JungleAdventureRoninABI,
  },
  JungleShopRonin: {
    address: '0xE206b322FE38edA3Df95558e80d877a9e98bdc1a' as `0x${string}`,
    abi: JungleShopRoninABI,
  },
  MysteryBoxRedeemerRonin: {
    address: '0xd6f9734c45F0bdFfC6CCA98FcbfF13449F7b586A' as `0x${string}`,
    abi: MysteryBoxReedemerRoninABI,
  },
  RedeemerRonin: {
    address: '0x644A6d2aa3AbEEc944C874260d64805eD262eB4c' as `0x${string}`,
    abi: RedeemerRoninABI,
  },
  JungleAdventureJackpotRonin: {
    address: '0x083a79BEeAfc368a8c97Df63726eD54Cdb26556a' as `0x${string}`,
    abi: JungleAdventureLotteryRoninABI,
  },
  TraitRegistryRonin: {
    address: '0x66D017F2d6719B494ae6E86Dd46C1b300C233310' as `0x${string}`,
    abi: TraitRegistryRoninABI,
  },
  VRFCoordinatorRonin: {
    address: '0x16a62a921e7fec5bf867ff5c805b662db757b778' as `0x${string}`,
    abi: VRFCoordinatorRoninABI,
  },
  EmakiEquippable: {
    // proxy
    address: '0x193E2f020703d903Dd4CB8b54Fa89583BfBbe124' as `0x${string}`,
    abi: EmakiEquippableABI,
  },
  EmakiConsumable: {
    // proxy
    address: '0x27bc94fb5232819c83786412b90e9c22e5428fec' as `0x${string}`,
    abi: EmakiConsumableABI,
  },
};

const CONTRACTS_L2_RONIN_SAIGON = merge({}, CONTRACTS_L2_RONIN, {
  KongzGenkaiRonin: {
    address: '0x54A1065FeEF1960b7d4b86f9CbdCE7eBea478939',
  },
  BananaRonin: {
    address: '0xe61f92378450772495625D33F4a8dAed8E870D83',
  },
  BananaSliceRonin: {
    address: '0x0ba2DF5192e835DaFff6AD4a84aAbF99Ab87C5A3',
  },
  KongzVXRonin: {
    address: '0x48cE67584B69815181298Ee51Ccb5b9a8298014B',
  },
  ConsumablesRonin: {
    address: '0xC45542787CF19F1461C90f6990d02bC048337b10',
  },
  CharmzRonin: {
    address: '0x0085DC12a68a0BB2D996A70E2B72603098FFF9E7',
  },
  GoldenTicketRonin: {
    address: '0x518f05829BE6D6CA52FCAfA34E9cB44FbB48716D',
  },
  Armory1155Ronin: {
    address: '0x1eF8E338F414C31e7050b553cE334bA72bD429B6',
  },
  Armory721Ronin: {
    address: '0xA1C04387f884d5d332369d17D07C6575c8CcA89d',
  },
  EquipmentRonin: {
    address: '0x05e85415342bfF74B1919EA55d50DfE83c713388',
  },
  JungleAdventureRonin: {
    address: '0x9D6DE5C9d3B437475bD7e1e2F8b01492d498F8A6',
  },
  JungleShopRonin: {
    address: '0x92A9e04ACba85CEd1BDd5377BECFC792046b8313',
  },
  MysteryBoxRedeemerRonin: {
    address: '0x958cdbac1126B5Cda78cd9B0f25fc2b684ECF05A',
  },
  RedeemerRonin: {
    address: '0x9b703f4c3e181714ee4d12e347909319a11f3d44',
  },
  JungleAdventureJackpotRonin: {
    address: '0x76494A83EC232566e3cF74Ca7910758fd542740C',
  },
  TraitRegistryRonin: {
    address: '0x6035477E8bBA4eEba7Eae27448E76Bb55a2D9f48',
  },
  VRFCoordinatorRonin: {
    address: '0xa60c1e07fa030e4b49eb54950adb298ab94dd312',
  },
  EmakiEquippable: {
    address: '0x2fa2c97b284a59BF8fc5b22065A273Fe2Ecb54b0',
  },
  EmakiConsumable: {
    address: '0x656C0203A5862bAaDf6F1706D8DE11d8cF5867b3',
  },
} as { [key in keyof typeof CONTRACTS_L2_RONIN]: { address: `0x${string}` } });

const CONTRACTS_PROD = { ...CONTRACTS_L1_MAINNET, ...CONTRACTS_L2_POLYGON, ...CONTRACTS_L2_RONIN };
const CONTRACTS_TEST = {
  ...CONTRACTS_L1_SEPOLIA,
  ...CONTRACTS_L2_POLYGON_MUMBAI,
  ...CONTRACTS_L2_RONIN_SAIGON,
};

export function CONTRACTS_BY_NETWORK(networkName?: string) {
  switch (networkName?.toLowerCase()) {
    case 'ethereum':
    case 'polygon':
    case 'ronin':
      return CONTRACTS_PROD;
    case 'sepolia':
    case 'polygon mumbai':
    case 'saigon testnet':
      return CONTRACTS_TEST;
    case undefined:
      return process.env.NEXT_PUBLIC_DEFAULT_CHAIN_ID &&
        parseInt(process.env.NEXT_PUBLIC_DEFAULT_CHAIN_ID, 10) === 1
        ? CONTRACTS_PROD
        : CONTRACTS_TEST;
    default:
      throw new Error(`Network ${networkName} not supported`);
  }
}
