import { defineSlotRecipe } from '@chakra-ui/react';

export const tooltipRecipe = defineSlotRecipe({
  slots: ['content'],
  base: {
    content: {
      '--tooltip-bg': 'colors.backgroundTertiary',
      padding: 4,
      fontSize: 'sm',
      fontWeight: 'normal',
      borderRadius: 'md',
      lineHeight: 'moderate',
    },
  },
});
